import React, { FC } from "react";
import { Button, Col, Row } from "antd";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { tradingAccount } from "../../constants/actionTypes";
import styles from "./tradingAccount.module.css";

interface graphZoom {
  handleZoom: (value: boolean) => void;
}

export const GraphZoom: FC<graphZoom> = ({ handleZoom }) => {
  const dispatch = useAppDispatch();
  const dailyFsReturn = useAppSelector((state) => state.tradingAccount.dailyFsReturn);
  const date = useAppSelector((state) => state.tradingAccount.date);
  const filteredDate = useAppSelector((state) => state.tradingAccount.filteredDate);
  const performance = useAppSelector((state) => state.tradingAccount.performance);
  const returnValue = useAppSelector((state) => state.tradingAccount.returnValue);

  const zoomData = (zoomValue: number) => {
    if (!performance.length || !returnValue.length) {
      return;
    }
    handleZoom(true);
    const targetMonthLength = new Date(
      new Date(date[date?.length - 1]).getFullYear(),
      new Date(date[date?.length - 1]).getMonth() + 1 - zoomValue,
      0
    ).getDate();
    const currentDate = new Date(date[date?.length - 1]).getDate();
    const setTargetDay = currentDate > targetMonthLength ? targetMonthLength : currentDate;
    const startDate = new Date(
      new Date(
        new Date(new Date(date[date?.length - 1]).setDate(1)).setMonth(
          new Date(date[date?.length - 1]).getMonth() - zoomValue
        )
      ).setDate(setTargetDay)
    );
    const selectedData = date
      .map((day: string | undefined) => {
        if (day && new Date(day) >= new Date(startDate)) {
          return day;
        }
      })
      .filter((dataExist: string | undefined) => dataExist);

    const setFilteredFsValue = () => {
      const zoomedArray = dailyFsReturn.slice(dailyFsReturn?.length - selectedData?.length);
      const calculatePerformance = (index: number) => {
        let sum: number = zoomedArray[0] + 1;
        if (index === 0) {
          return zoomedArray[0] * 100;
        }

        for (let i = index; i > 0; i--) {
          sum *= zoomedArray[i] + 1;
        }

        return (sum - 1) * 100;
      };

      return zoomedArray.map((data: number, index: number) => {
        return calculatePerformance(index);
      });
    };

    dispatch({
      type: tradingAccount.SET_FILTERED_GRAPH_DATA,
      payload: {
        filteredReturnValue: returnValue.slice(returnValue?.length - zoomValue),
        filteredPerformance: performance.slice(performance?.length - zoomValue),
        filteredDate: selectedData,
        filteredFsReturn: setFilteredFsValue(),
      },
    });
  };

  const resetZoomData = () => {
    dispatch({
      type: tradingAccount.RESET_GRAPH_ZOOM,
    });
    handleZoom(false);
  };

  return (
    <Row>
      <Col sm={24} className={styles.returnCurve}>
        <div className={styles.graphTimeSelect}>
          <span className={styles.timeSelect}>Zoom:</span>
          <Button className={styles.timeSelectButton} onClick={() => zoomData(1)}>
            1M
          </Button>
          <Button className={styles.timeSelectButton} onClick={() => zoomData(3)}>
            3M
          </Button>
          <Button className={styles.timeSelectButton} onClick={() => zoomData(6)}>
            6M
          </Button>
          <Button className={styles.timeSelectButton} onClick={() => zoomData(12)}>
            1Y
          </Button>
          <Button className={styles.timeSelectButton} onClick={resetZoomData}>
            All
          </Button>
          <span>
            {filteredDate.length
              ? `${format(new Date(filteredDate[0]), "MMM dd, yyyy")} / ${format(
                  new Date(filteredDate[filteredDate?.length - 1]),
                  "MMM dd, yyyy"
                )}`
              : ""}
          </span>
        </div>
        {/* TODO: Temporary hidden
          <Button className={styles.selectBenchmarkButton}>Select Benchmark</Button>
        */}
      </Col>
    </Row>
  );
};
