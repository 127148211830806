import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Row } from "antd";
import { SearchResult } from "./searchResults";
import { SearchMain } from "./searchMain";

export const Search: FC = () => {
  const [searchParams] = useSearchParams();
  // Result
  const [showResult, setShowResult] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Search";
  }, []);

  useEffect(() => {
    if (searchParams.toString()) {
      setShowResult(true);
    }
  }, []);

  const handleShowResult = (value: boolean) => {
    setShowResult(value);
  };

  return (
    <>
      <SearchMain handleShowResult={handleShowResult} />
      <Row>
        <Col xs={24}>{showResult && <SearchResult />}</Col>
      </Row>
    </>
  );
};
