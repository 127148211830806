import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, TooltipItem, ChartData, ChartOptions, ChartType } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Empty } from "antd";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import styles from "./dashboard.module.css";

declare module "chart.js" {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

Tooltip.positioners.myCustomPositioner = (elements) => {
  return {
    x: elements[0]?.element.x,
    y: elements[0]?.element.y,
  };
};

interface currentChartType {
  date: string;
  fs_index_id: number;
  performance: number;
}

interface chart {
  currentChart: currentChartType[];
  isZoomed: boolean;
}

export const DashboardChart: FC<chart> = ({ currentChart, isZoomed }) => {
  const { t } = useTranslation("dashboard");
  const filteredDate = currentChart.map((dayData) => dayData?.date);
  const filteredPerformance = currentChart.map((oneDayPerformance) => oneDayPerformance?.performance);
  const performanceLabels = currentChart.map((oneDayPerformance) => String(oneDayPerformance?.performance));
  const labels = filteredDate;
  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: TooltipItem<"line">[]) => {
            if (context.length) {
              return context[0].dataset.type === "line"
                ? format(context[0].parsed.x, "MMM dd yyyy")
                : format(new Date(context[0].label), "MMM yyyy");
            } else return "";
          },
          label: (context: TooltipItem<"line">) => {
            if (context.dataset.label === "Performance_All") {
              return `Performance_All: ${context.formattedValue}%`;
            }
            return `Performance: ${context.formattedValue}%`;
          },
        },
        position: "myCustomPositioner" as "average",
      },
    },
    scales: {
      y: {
        labels: performanceLabels,
        title: {
          display: true,
          text: t("Performance") + " (%)",
          padding: 2,
          color: "#72c6ed",
        },
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: true,
        },
        offset: true,
        max: Math.max(...filteredPerformance),
        ticks: {
          color: (context) => {
            if (context.tick?.value < 0) {
              return "rgb(255, 18, 0)";
            }
            return "#72c6ed";
          },
        },
      },
      x2: {
        type: "timeseries",
        min: labels[0],
        max: labels[labels.length - 1],
        ticks: {
          callback: (value: number | string) => {
            if (typeof value === "string" && labels.length && Date.parse(value) && value.includes("Jan") && !isZoomed) {
              return format(new Date(value), "MMM");
            }
          },
          autoSkip: true,
          maxRotation: 0,
          align: "center",
        },
        offset: false,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        type: "timeseries",
        min: labels[0],
        max: labels[labels.length - 1],
        ticks: {
          callback: (value: number | string) => {
            if (typeof value === "string" && labels.length && Date.parse(value) && isZoomed) {
              return format(new Date(value), "MMM yy");
            }
            if (typeof value === "string" && labels.length && Date.parse(value) && !isZoomed) {
              return format(new Date(value), "yyyy");
            }
          },
          autoSkip: false,
          maxRotation: 0,
          align: "center",
        },
        offset: false,
        title: {
          display: false,
        },
        time: {
          unit: isZoomed ? "month" : "year",
        },
      },
    },
  };

  const data: ChartData = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: t("Performance"),
        borderColor: "#72c6ed",
        borderWidth: 1.5,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgb(114, 198, 237)",
        data: filteredPerformance,
        fill: {
          target: "start",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      {!filteredPerformance?.length ? <Empty /> : <Chart options={options} type="line" data={data} />}
    </div>
  );
};
