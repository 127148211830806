import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "antd";
import { Link } from "react-router-dom";
import { FormItem } from "./formItem";
import { useAppSelector } from "../../hooks";
import styles from "./addTradingAccount.module.css";

interface brokerAccountResult {
  connection: string;
  selectBrokerName: string;
  accNumber: string;
}

export const BrokerAccountResult: FC<brokerAccountResult> = ({ connection, selectBrokerName, accNumber }) => {
  const tradingAccountId = useAppSelector((state) => state.main.tradingAccountId);

  const { t } = useTranslation("addTradingAccount");
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      className={styles.resultForm}
    >
      <div className={styles.resultFormWrapper}>
        <FormItem
          formLabel={t("chooseConnectionMethod")}
          name="ChooseConnectionMethod"
          connection={connection}
          needToRenderTooltip={false}
          changed={true}
        />
        <FormItem
          formLabel={t("selectBrokerageFirm")}
          name="SelectBrokerageFirm"
          connection={selectBrokerName}
          needToRenderTooltip={false}
          changed={true}
        />
        <FormItem
          formLabel={t("accountNumber")}
          name="AccountNumber"
          connection={accNumber}
          needToRenderTooltip={false}
          changed={true}
        />
      </div>
      <div className={styles.resultTextWrapper}>
        <h1 className={styles.resultTitle}>{t("completedTheRegistrationProcess")}</h1>
        <p className={styles.resultText}>{t("lastStep")}:</p>
        <ol className={styles.resultList}>
          <li>{t("loginToYourAccount")}</li>
          <li>
            {t("clickInvestorsMarketplace")} {">"} {t("searchServices")} {">"} {t("administration")} {">"}{" "}
            {t("manageAdministrators")}
          </li>
          <li>{t("clickAddAdministrator")}.</li>
          <li>{t("enterAccountNumber", { accNumber })}.</li>
        </ol>
        <p className={styles.resultSubText}>
          {t("moreDetails")} <br />
          <strong>{t("administrationRights")}.</strong>
        </p>
      </div>
      <Form.Item className={styles.formItem} wrapperCol={{ offset: 8, span: 16 }}>
        <Link to={`/trading-account/${tradingAccountId}`}>
          <Button className={styles.confirmButton} style={{ width: "360px" }}>
            {t("myAccount")}
          </Button>
        </Link>
      </Form.Item>
    </Form>
  );
};
