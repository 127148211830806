import React, { FC, useState } from "react";
import axios from "axios";
import { Button, message, Modal, Select, Table, Tabs, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useAppSelector } from "../hooks";
import styles from "../pages/myAccount/myAccount.module.css";

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;

interface uploadModal {
  title: string;
  visible: boolean;
  onCancel: () => void;
  cancelText: string;
  nlvHeader: string;
  performanceHeader: string;
  downloadTemplate: string;
  uploadingText: string;
  startUpload: string;
  selectedAccountId: number;
  uploadSuccessfully: string;
  uploadFailed: string;
}

export const UploadModal: FC<uploadModal> = ({
  title,
  visible,
  onCancel,
  cancelText,
  nlvHeader,
  performanceHeader,
  downloadTemplate,
  uploadingText,
  startUpload,
  selectedAccountId,
  uploadSuccessfully,
  uploadFailed,
}) => {
  const currencies = useAppSelector((state) => state.main.currencies);

  const [selectedTab, setSelectedTab] = useState<string>("NLV Daily");
  const [frequency, setFrequency] = useState<string>("daily");
  const [dataType, setDataType] = useState<string>("NLVs");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [blobFile, setBlobFile] = useState<any>("");
  const [uploading, setUploading] = useState<boolean>(false);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setBlobFile(file);
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
    maxCount: 1,
  };

  const handleTabChange = (key: string) => {
    if (!uploading) {
      setSelectedTab(key);
      if (key.includes("Daily")) {
        setFrequency("daily");
      }
      if (key.includes("Monthly")) {
        setFrequency("monthly");
      }
      if (key.includes("NLV")) {
        setDataType("NLVs");
      }
      if (key.includes("Performance")) {
        setDataType("returns");
      }
    }
  };

  const handleCurrencyChange = (value: string) => {
    setSelectedCurrency(value);
  };

  const sendFormData = (file: any) => {
    if (!uploading) {
      setUploading(true);
      const currencyId = currencies[selectedCurrency];
      const formData = new FormData();
      formData.append("file", file as RcFile);
      formData.append("account_id", String(selectedAccountId));
      formData.append("currency_id", String(currencyId));
      formData.append("frequency", frequency);
      formData.append("data_type", dataType);
      formData.append("file_path", fileList[fileList.length - 1]?.name);
      axios
        .post(`${process.env.REACT_APP_API_URL}/unverified/`, formData)
        .then(() => {
          setFileList([]);
          message.success(uploadSuccessfully);
        })
        .catch(() => {
          message.error(uploadFailed);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const columns = [
    {
      title: "File name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Upload date",
      dataIndex: "uploadDate",
      key: "uploadDate",
    },
    {
      title: "Parsed date",
      dataIndex: "parsedDate",
      key: "parsedDate",
    },
  ];

  const dataSource = [
    {
      key: "1",
      fileName: "Unverified_Daily_Returns_Template__6.xls",
      status: "error",
      uploadDate: "September 9, 2022",
      parsedDate: "September 9, 2022",
    },
    {
      key: "2",
      fileName: "Unverified_Daily_Returns_Template__1.xls",
      status: "error",
      uploadDate: "September 9, 2022",
      parsedDate: "September 9, 2022",
    },
  ];

  const tabContent = (name: string, url: string, download: string, selectedTab: string) => {
    return (
      <TabPane tab={name} key={name}>
        <Select
          defaultValue="USD"
          style={{ width: 70 }}
          onChange={handleCurrencyChange}
          className={styles.currencySelect}
          disabled={uploading}
        >
          {Object.keys(currencies)?.map((currency) => {
            return (
              <Option value={currency} key={currency}>
                {currency}
              </Option>
            );
          })}
        </Select>
        <a href={url} download={download}>
          <Button type="primary" className={styles.downloadTemplateButton} disabled={uploading}>
            {downloadTemplate} {selectedTab}
          </Button>
        </a>
        <div className={styles.fileControl}>
          <Dragger {...props}>
            <Button icon={<UploadOutlined />} disabled={uploading}>
              Select File
            </Button>
          </Dragger>
          <Button
            type="primary"
            onClick={() => sendFormData(blobFile)}
            disabled={fileList.length === 0 || uploading}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? uploadingText : startUpload}
          </Button>
        </div>
        <Table dataSource={dataSource} columns={columns} className={styles.filesTable} />
      </TabPane>
    );
  };
  return (
    <Modal
      title={`${title}  ${selectedAccountId}`}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={
        <Button onClick={onCancel} data-testid="closeModal">
          {cancelText}
        </Button>
      }
      bodyStyle={{ paddingTop: 0 }}
      width={700}
      data-testid="uploadModal"
    >
      <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
        {tabContent(
          `${nlvHeader}`,
          "https://api.fundseeder.com/accounts/unverified_template/22217.xls?sid=d6297c36c5eceaf40a3b18e1ae56887d&user_token=G5odF-_sk_b4CYMxT1HU",
          "Unverified Data Template.xls",
          selectedTab
        )}
        {tabContent(
          `${performanceHeader}`,
          "https://api.fundseeder.com/accounts/unverified_template/22161.xls?sid=d6297c36c5eceaf40a3b18e1ae56887d&user_token=G5odF-_sk_b4CYMxT1HU",
          "Unverified Daily Returns Template.xls",
          selectedTab
        )}
      </Tabs>
    </Modal>
  );
};
