import React, { FC } from "react";
import {Row, Col} from "antd";
import {FooterContent} from "./footerContent";
import {FooterLinks} from "./footerLinks";
import ellipse1 from "./assets/ellipse1.svg";
import ellipse2 from "./assets/ellipse2.svg";
import styles from "./footer.module.css";

export const Footer: FC = () => {
  return (
    <footer className={styles.footer} id="footer">
      <Row align="middle" justify="center" className={styles.footerRow}>
        <Col xs={24}>
          <FooterContent/>
          <FooterLinks/>
        </Col>
      </Row>
      <div className={styles.ellipse1}><img src={ellipse1} alt=""/></div>
      <div className={styles.ellipse2}><img src={ellipse2} alt=""/></div>
      <div className={styles.divider}></div>
    </footer>
  )
};
