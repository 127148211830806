import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Col, Input, Row, Form, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { LoadingOutlined } from "@ant-design/icons";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import classNames from "classnames/bind";
import { Header } from "../../components/header";
import styles from "./search.module.css";

const cx = classNames.bind(styles);

interface checkboxItem {
  label: string;
  value: string;
}

export interface searchOptionsTypes {
  AssetClass: checkboxItem[];
  Strategies: checkboxItem[];
  Platforms: checkboxItem[];
}

export interface tradePerformanceTypes {
  label: string;
  value: string;
}

export interface lengthOfRecordTypes {
  label: string;
  value: string;
}

interface search {
  handleShowResult: (value: boolean) => void;
}

export const SearchMain: FC<search> = ({ handleShowResult }) => {
  const { t } = useTranslation("search");
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  // Trader name state
  const [traderName, setTraderName] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [exactTraderName, setExactTraderName] = useState<boolean>(false);
  const [exactAccountName, setExactAccountName] = useState<boolean>(false);
  // Main checkboxes state
  const [selectedAssetClass, setSelectedAssetClass] = useState<any[]>([]);
  const [selectedStrategies, setSelectedStrategies] = useState<any[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>([]);
  // Select all buttons state
  const [selectAllAssetClass, setSelectAllAssetClass] = useState<boolean>(false);
  const [selectAllStrategies, setSelectAllStrategies] = useState<boolean>(false);
  const [selectAllPlatforms, setSelectAllPlatforms] = useState<boolean>(false);
  // Restrict buttons state
  const [restrictAssets, setRestrictAssets] = useState<boolean>(false);
  const [restrictStrategies, setRestrictStrategies] = useState<boolean>(false);
  // Target NAS state
  const [targetNASFrom, setTargetNASFrom] = useState<string>("");
  const [targetNASTo, setTargetNASTo] = useState<string>("");
  // Length of return state
  const [lengthOfRecordValue, setLengthOfRecordValue] = useState<string>("");
  const [selectedTimeValue, setSelectedTimeValue] = useState<string>("");
  // Target performance state
  const [targetPerformanceData, setTargetPerformanceData] = useState<any>({});
  // Strategy description state
  const [strategyDescription, setStrategyDescription] = useState<string>("");
  const [strategyDescriptionCheckboxes, setStrategyDescriptionCheckboxes] = useState<any[]>([]);

  const searchOptions: searchOptionsTypes = {
    AssetClass: [
      { label: t("forex"), value: "Forex" },
      { label: t("futures"), value: "Futures" },
      { label: t("equities"), value: "Equities" },
      { label: t("options"), value: "Options" },
    ],
    Strategies: [
      { label: t("systematic"), value: "Systematic" },
      { label: t("discretionary"), value: "Discretionary" },
      { label: t("fundamental"), value: "Fundamental" },
      { label: t("technical"), value: "Technical" },
    ],
    Platforms: [
      { label: t("interactiveBrokers"), value: "InteractiveBrokers" },
      { label: t("aDM"), value: "ADM" },
      { label: t("tradestation"), value: "Tradestation" },
    ],
  };

  const tradePerformance: tradePerformanceTypes[] = [
    { label: t("fSScore"), value: "" },
    { label: t("annualizedReturn"), value: "" },
    { label: t("sharpeRatio"), value: "" },
    { label: t("sortino"), value: "" },
    { label: t("gPRDaily"), value: "" },
    { label: t("minMaxDD"), value: "" },
    { label: t("maxMaxDD"), value: "" },
  ];

  const targetNAS = [
    { label: t("from"), value: "" },
    { label: t("to"), value: "" },
  ];

  const lengthOfRecord: lengthOfRecordTypes[] = [
    { label: t("years"), value: "Years" },
    { label: t("months"), value: "Months" },
    { label: t("days"), value: "Days" },
  ];

  const strategyDescriptionOptions = [
    { label: t("showUnregulatedTrades"), value: "ShowUnregulatedTrades" },
    {
      label: t("showTradersWithIncompleteProfileInformation"),
      value: "ShowTradersWithIncompleteProfileInformation",
    },
    { label: t("includeUnverifiedAccounts"), value: "IncludeUnverifiedAccounts" },
    { label: t("onlyLeaderboard"), value: "OnlyLeaderboard" },
  ];

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key === "selectedAssetClass") {
        setSelectedAssetClass(value.split(" "));
      } else if (key === "selectedStrategies") {
        setSelectedStrategies(value.split(" "));
      } else if (key === "selectedPlatforms") {
        setSelectedPlatforms(value.split(" "));
      } else if (key === "strategyDescriptionCheckboxes") {
        setStrategyDescriptionCheckboxes(value.split(" "));
      } else if (key === "strategyDescription") {
        setStrategyDescription(value);
      } else if (key === "targetNASFrom") {
        setTargetNASFrom(value);
      } else if (key === "targetNASTo") {
        setTargetNASTo(value);
      } else if (key === "lengthOfRecordValue") {
        setLengthOfRecordValue(value);
      } else if (key === "selectedTimeValue") {
        setSelectedTimeValue(value);
      } else if (key === "traderName") {
        setTraderName(value);
      } else if (key === "accountName") {
        setAccountName(value);
      } else if (key === "exactTraderName") {
        setExactTraderName(true);
      } else if (key === "exactAccountName") {
        setExactAccountName(true);
      } else {
        setTargetPerformanceData((prevValue: any) => {
          return { ...prevValue, [key]: value };
        });
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue(targetPerformanceData);
  }, [targetPerformanceData]);

  const handleTraderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTraderName(event.target.value);
  };

  const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(event.target.value);
  };

  const handleExactTraderName = () => {
    setExactTraderName((prevValue) => !prevValue);
  };

  const handleExactAccountName = () => {
    setExactAccountName((prevValue) => !prevValue);
  };

  const handleCheckboxChange = (checkedValues: CheckboxValueType[], name: string) => {
    if (name === "AssetClass") {
      if (checkedValues.length === 0) {
        setRestrictAssets(false);
      }
      setSelectAllAssetClass(false);
      setSelectedAssetClass(checkedValues);
    }
    if (name === "Strategies") {
      if (checkedValues.length === 0) {
        setRestrictStrategies(false);
      }
      setSelectAllStrategies(false);
      setSelectedStrategies(checkedValues);
    }
    if (name === "Platforms") {
      setSelectAllPlatforms(false);
      setSelectedPlatforms(checkedValues);
    }
  };

  const handleTradePerformanceChange = (value: { [key: string]: string }) => {
    setTargetPerformanceData((prevValue: { [key: string]: string }) => {
      return { ...prevValue, ...value };
    });
  };

  const handleSelectAll = (event: CheckboxChangeEvent, name: keyof searchOptionsTypes) => {
    const selectedOptionsList = searchOptions[name].map((selectedOptions) => {
      if (selectedOptions.value !== "Restrict multi-assets" && selectedOptions.value !== "Restrict multi-strategies") {
        return selectedOptions.value;
      }
    });
    if (name === "AssetClass") {
      if (event.target.checked) {
        setSelectAllAssetClass(true);
        setSelectedAssetClass(selectedOptionsList);
        setRestrictAssets(false);
      } else {
        setSelectAllAssetClass(false);
        setSelectedAssetClass([]);
      }
    }
    if (name === "Strategies") {
      if (event.target.checked) {
        setSelectAllStrategies(true);
        setSelectedStrategies(selectedOptionsList);
        setRestrictStrategies(false);
      } else {
        setSelectAllStrategies(false);
        setSelectedStrategies([]);
      }
    }
    if (name === "Platforms") {
      if (event.target.checked) {
        setSelectAllPlatforms(true);
        setSelectedPlatforms(selectedOptionsList);
      } else {
        setSelectAllPlatforms(false);
        setSelectedPlatforms([]);
      }
    }
  };

  const handleRestrictChange = (event: CheckboxChangeEvent, name: string) => {
    if (name === "AssetClass") {
      setRestrictAssets(event.target.checked);
    }
    if (name === "Strategies") {
      setRestrictStrategies(event.target.checked);
    }
  };

  const handleTargetNASChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const removeLettersFromValue = event.target.value.replace(/[A-Za-z]/g, "");
    if (name === "From") {
      setTargetNASFrom(removeLettersFromValue);
    }
    if (name === "To") {
      setTargetNASTo(removeLettersFromValue);
    }
  };

  const handleTimeSelect = (value: string) => {
    setSelectedTimeValue(value);
  };

  const handleLengthOfRecord = (event: React.ChangeEvent<HTMLInputElement>) => {
    const removeLettersFromValue = event.target.value.replace(/[A-Za-z]/g, "");
    setLengthOfRecordValue(removeLettersFromValue);
  };

  const handleStrategyDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyDescription(event.target.value);
  };

  const handleStrategyDescriptionCheckboxes = (checkedValues: CheckboxValueType[]) => {
    setStrategyDescriptionCheckboxes(checkedValues);
  };

  const submitData = () => {
    if (!dataLoading) {
      setDataLoading(true);
      const queryParams: { [key: string]: any } = { ...targetPerformanceData };
      if (selectedAssetClass.length) {
        queryParams["selectedAssetClass"] = selectedAssetClass.join(" ");
      }
      if (selectedStrategies.length) {
        queryParams["selectedStrategies"] = selectedStrategies.join(" ");
      }
      if (selectedPlatforms.length) {
        queryParams["selectedPlatforms"] = selectedPlatforms.join(" ");
      }
      if (targetNASFrom) {
        queryParams["targetNASFrom"] = targetNASFrom;
      }
      if (targetNASTo) {
        queryParams["targetNASTo"] = targetNASTo;
      }
      if (lengthOfRecordValue) {
        queryParams["lengthOfRecordValue"] = lengthOfRecordValue;
      }
      if (selectedTimeValue) {
        queryParams["selectedTimeValue"] = selectedTimeValue;
      }
      if (strategyDescription) {
        queryParams["strategyDescription"] = strategyDescription;
      }
      if (strategyDescriptionCheckboxes.length) {
        queryParams["strategyDescriptionCheckboxes"] = strategyDescriptionCheckboxes.join(" ");
      }
      if (traderName) {
        queryParams["traderName"] = traderName;
      }
      if (accountName) {
        queryParams["accountName"] = accountName;
      }
      if (exactTraderName) {
        queryParams["exactTraderName"] = exactTraderName;
      }
      if (exactAccountName) {
        queryParams["exactAccountName"] = exactAccountName;
      }
      console.log("Selected to search", queryParams);
      setSearchParams(queryParams, { replace: true });
      setDataLoading(false);
      if (Object.keys(queryParams).length) {
        handleShowResult(true);
      } else {
        handleShowResult(false);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>{t("fundSeederSearch")}</div>
      </div>
      <Row className={styles.leaderboardCardsRow}>
        <Col xs={24} className={styles.leaderboardWrapper}>
          <div className={styles.wrapper}>
            <h3 className={styles.searchHeader}>Target Trader</h3>
            <p>{t("indicateTraderName")}.</p>
            <Row gutter={16} className={styles.traderNameRow}>
              <Col xs={12}>
                <div className={styles.flexLine}>
                  <label htmlFor="trader" className="mr16">
                    {t("trader")}
                  </label>
                  <Input
                    id="trader"
                    placeholder={t("trader")}
                    className={styles.nameInput}
                    onChange={handleTraderChange}
                    value={traderName}
                  />
                  <Checkbox
                    className={styles.noWrap}
                    onChange={handleExactTraderName}
                    disabled={traderName === ""}
                    checked={exactTraderName}
                  >
                    {t("exactTraderName")}
                  </Checkbox>
                </div>
              </Col>
              <Col xs={12}>
                <div className={styles.flexLine}>
                  <label htmlFor="account" className="mr16">
                    {t("account")}
                  </label>
                  <Input
                    id="account"
                    placeholder={t("account")}
                    className={styles.nameInput}
                    onChange={handleAccountChange}
                    value={accountName}
                  />
                  <Checkbox
                    className={styles.noWrap}
                    onChange={handleExactAccountName}
                    disabled={accountName === ""}
                    checked={exactAccountName}
                  >
                    {t("exactAccountName")}
                  </Checkbox>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className={styles.checkboxesRow}>
              {(Object.keys(searchOptions) as (keyof typeof searchOptions)[]).map((searchOption) => {
                const checkboxesValue = (value: string) => {
                  if (value === "AssetClass") {
                    return selectedAssetClass;
                  }
                  if (value === "Strategies") {
                    return selectedStrategies;
                  }
                  if (value === "Platforms") {
                    return selectedPlatforms;
                  }
                };
                const selectAllValue = (value: string) => {
                  if (value === "AssetClass") {
                    return selectAllAssetClass;
                  }
                  if (value === "Strategies") {
                    return selectAllStrategies;
                  }
                  if (value === "Platforms") {
                    return selectAllPlatforms;
                  }
                };
                const selectAllName = (value: string) => {
                  if (value === "AssetClass" && selectAllAssetClass) {
                    return t("deselectAll");
                  }
                  if (value === "Strategies" && selectAllStrategies) {
                    return t("deselectAll");
                  }
                  if (value === "Platforms" && selectAllPlatforms) {
                    return t("deselectAll");
                  }
                  return t("selectAll");
                };
                return (
                  <Col xs={5} key={searchOption}>
                    <h3 className={styles.searchHeader}>{searchOption}</h3>
                    <p className={styles.searchOptionsDescription}>
                      {t("checkAll")} {t(`${searchOption}`)} {t("toIncludeInSearch")}.
                    </p>
                    <div className={cx("checkboxesWrapper")}>
                      <Checkbox
                        onChange={(event) => handleSelectAll(event, searchOption)}
                        className={styles.selectAllCheckbox}
                        checked={selectAllValue(searchOption)}
                      >
                        {selectAllName(searchOption)}
                      </Checkbox>
                      <Checkbox.Group
                        options={searchOptions[searchOption]}
                        className={styles.checkbox}
                        value={checkboxesValue(searchOption)}
                        onChange={(values) => handleCheckboxChange(values, searchOption)}
                      />
                      {(searchOption === "AssetClass" || searchOption === "Strategies") && (
                        <Checkbox
                          className={styles.checkbox}
                          checked={searchOption === "AssetClass" ? restrictAssets : restrictStrategies}
                          onChange={(event) => handleRestrictChange(event, searchOption)}
                          disabled={
                            searchOption === "AssetClass"
                              ? selectAllAssetClass || selectedAssetClass.length === 0
                              : selectAllStrategies || selectedStrategies.length === 0
                          }
                        >
                          {searchOption === "AssetClass" ? t("restrictMultiAssets") : t("restrictMultiStrategies")}
                        </Checkbox>
                      )}
                    </div>
                  </Col>
                );
              })}
              <Col xs={5}>
                <h3 className={styles.searchHeader}>Target Performance</h3>
                <p className={styles.searchOptionsDescription}>{t("minimumPerformance")}.</p>
                <Form
                  form={form}
                  labelCol={{ span: 16 }}
                  onValuesChange={handleTradePerformanceChange}
                  initialValues={targetPerformanceData}
                >
                  {tradePerformance.map((tradePerformanceValues) => (
                    <Form.Item
                      label={tradePerformanceValues.label}
                      name={tradePerformanceValues.label}
                      className={styles.formItem}
                      key={tradePerformanceValues.label}
                    >
                      <Input className={styles.formInput} />
                    </Form.Item>
                  ))}
                </Form>
              </Col>
              <Col xs={4}>
                <h3 className={styles.searchHeader}>Target NAS</h3>
                <p className={styles.searchOptionsDescription}>Indicate minimum account size to include in search.</p>
                <Form labelCol={{ span: 8 }} labelAlign="left">
                  {targetNAS.map((targetNASValues) => (
                    <label htmlFor={targetNASValues.label} className={styles.currencyLabel} key={targetNASValues.label}>
                      {targetNASValues.label}:
                      <Input
                        className={cx("formInput", "currencyInput")}
                        type="text"
                        name={targetNASValues.label}
                        addonBefore="$"
                        value={targetNASValues.label === "From" ? targetNASFrom : targetNASTo}
                        onChange={(event) => handleTargetNASChange(event, targetNASValues.label)}
                      />
                    </label>
                  ))}
                </Form>
                <h3 className={styles.searchHeader}>Length of record</h3>
                <p className={styles.searchOptionsDescription}>{t("minimumTrackRecord")}?</p>
                <Input
                  className={cx("formInput", "timeInput")}
                  onChange={handleLengthOfRecord}
                  value={lengthOfRecordValue}
                ></Input>
                <Select
                  options={lengthOfRecord}
                  className={styles.timeSelect}
                  onChange={handleTimeSelect}
                  value={selectedTimeValue}
                ></Select>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <div className={styles.strategyDescriptionWrapper}>
                  <h3 className={styles.strategyHeader}>{t("strategyDescription")}</h3>
                  <Input value={strategyDescription} onChange={handleStrategyDescription} />
                </div>
                <div className={styles.strategyCheckboxesWrapper}>
                  <Checkbox.Group
                    options={strategyDescriptionOptions}
                    value={strategyDescriptionCheckboxes}
                    onChange={handleStrategyDescriptionCheckboxes}
                  />
                  <Button className={styles.submitButton} onClick={submitData}>
                    {dataLoading ? <LoadingOutlined /> : t("apply")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
