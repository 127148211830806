import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FacebookFilled, TwitterCircleFilled, InstagramFilled, LinkedinFilled } from "@ant-design/icons";
import footerLogo from "./assets/footerLogo.svg";
import styles from "./footer.module.css";

export const FooterLinks = () => {
  const { t } = useTranslation("home");

  return (
    <div className={styles.footerLinks}>
      <Row align="middle" justify="center" className={styles.footerLinksRow}>
        <Col xl={5} md={8} xs={0} className={styles.footerLogo}>
          <Link to="/">
            <img src={footerLogo} alt="logo" />
          </Link>
        </Col>
        <Col md={8} xs={0} className={styles.footerInfoLinks}>
          <nav className={styles.infoLinksList}>
            <Link className={styles.infoLinksItem} to="/privacy-policy">
              {t("privacyPolicy")}
            </Link>
            <Link className={styles.infoLinksItem} to="/security">
              {t("security")}
            </Link>
            <Link className={styles.infoLinksItem} to="/user-agreement">
              {t("userAgreement")}
            </Link>
          </nav>
        </Col>
        <Col xl={5} md={8} xs={20} className={styles.socialLinks}>
          <nav className={styles.iconsList}>
            <a
              href="https://www.facebook.com/FundSeeder"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
            >
              <FacebookFilled className={styles.socialIcon} />
            </a>
            <a href="https://twitter.com/fundseeder" target="_blank" className={styles.socialIconLink} rel="noreferrer">
              <TwitterCircleFilled className={styles.socialIcon} />
            </a>
            <a
              href="https://www.instagram.com/fundseeder/"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
            >
              <InstagramFilled className={styles.socialIcon} />
            </a>
            <a
              href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F9199205%2F"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
            >
              <LinkedinFilled className={styles.socialIcon} />
            </a>
          </nav>
        </Col>
      </Row>
    </div>
  );
};
