import React, { Fragment, useEffect } from "react";
import { Header } from "../../components/header";
import { UserInformation } from "./userInformation";

export const MyAccount = () => {
  useEffect(() => {
    document.title = "My account";
  }, []);

  return (
    <Fragment>
      <Header />
      <UserInformation />
    </Fragment>
  );
};
