import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Title } from "../../components/title";
import step1Img from "./assets/step1.svg";
import step2Img from "./assets/step2.svg";
import step3Img from "./assets/step3.svg";
import step4Img from "./assets/step4.svg";
import styles from "./home.module.css";

export const StartUsing = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.startUsing}>
      <div className={styles.startUsingInner}>
        <div className={styles.startUsingInner2}>
          <div className={styles.startUsingWrp}>
            <Title subTitle="start using">{t("howTo")}</Title>
            <div className={styles.steps}>
              <div className={styles.step}>
                <div className={styles.stepNumber}>1</div>
                <div className={styles.stepContent}>
                  <div className={styles.stepIcon}>
                    <img src={step1Img} alt="" />
                  </div>
                  <div className={styles.stepText}>
                    {t("fillInTheRegistrationForm")}
                  </div>
                </div>
              </div>
              <div className={styles.step}>
                <div className={styles.stepNumber}>2</div>
                <div className={cn(styles.stepContent, styles.stepContentOr)}>
                  <div className={styles.stepIcon}>
                    <img src={step2Img} alt="" />
                  </div>
                  <div className={styles.stepText}>
                    {t("linkYourTradingAccount")}
                  </div>
                </div>
              </div>
              <div className={styles.step}>
                <div className={cn(styles.stepNumber, styles.stepNumberHide)}>
                  &nbsp;
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.stepIcon}>
                    <img src={step3Img} alt="" />
                  </div>
                  <div className={styles.stepText}>
                    {t("uploadDataManuallyWithXLS")}
                  </div>
                </div>
              </div>
              <div className={styles.step}>
                <div className={styles.stepNumber}>3</div>
                <div className={styles.stepContent}>
                  <div className={styles.stepIcon}>
                    <img src={step4Img} alt="" />
                  </div>
                  <div className={styles.stepText}>
                    {t("accountIsReady")}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.center}>
              <p className={styles.easyTostart}>{t("itsEasyToStart")}!</p>
              <div>
                <Link className={styles.greenBtn} to="/signup">
                  {t("getStarted")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
