import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Form, Input, Button, Row, Col, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../hooks";
import footerBg from "./assets/footerBg.png";
import styles from "./footer.module.css";
import "../../../App.css";

export const FooterContent = () => {
  const { t } = useTranslation("home");

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const accessToken = useAppSelector((state) => state.main.accessToken);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onFinish = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/contact-us/`,
        {
          name,
          email,
          message,
        },
        {
          headers: {
            Authorization: `Baerer ${accessToken}`,
          },
        }
      )
      .then(() => {
        notification.success({
          message: t("yourMessageWasSent"),
        });
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch(() => {
        notification.error({
          message: t("yourMessageWasNotSent"),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row align="middle" justify="center">
      <Col xl={8} xs={20}>
        <div className={styles.formWrapper}>
          <Form
            name="submit-form"
            id="contactForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="submit-form"
            fields={[
              {
                name: "name",
                value: name,
              },
              {
                name: "email",
                value: email,
              },
              {
                name: "yourMessage",
                value: message,
              },
            ]}
          >
            <div className={styles.formMainHeader}>Contact us</div>
            <Form.Item label={t("name")} name="username" className="submit-form-label">
              <Input value={name} onChange={handleNameChange} />
            </Form.Item>

            <Form.Item
              label={t("email")}
              name="email"
              className="submit-form-label"
              rules={[{ required: true, message: "Enter valid email", type: "email" }]}
            >
              <Input value={email} onChange={handleEmailChange} />
            </Form.Item>

            <Form.Item
              name="yourMessage"
              label={t("yourMessage")}
              className="submit-form-label"
              rules={[{ required: true, message: "Message Could not be empty" }]}
            >
              <Input.TextArea value={message} onChange={handleMessageChange} />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" className={styles.submitFormButton} disabled={isLoading}>
                {isLoading ? <LoadingOutlined /> : t("sendMessage")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <Col xl={16} xs={20} className={styles.contactsWrapper}>
        <img src={footerBg} alt={t("contactsMap")} className={styles.contactsMap} />
        <p className={styles.contactsText}>{t("pastPerformance")}.</p>
      </Col>
    </Row>
  );
};
