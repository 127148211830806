import React, { useEffect, useState, FC, useCallback } from "react";
import { Row, Col } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/header";
import { GraphSelectCard } from "./graphSelectCard";
import { GraphSection } from "./graphSection";
import { SelectedLeaderBoard } from "./selectedLeaderboard";
import { Leaderboard } from "./leaderboard";
import { Loading } from "../../components/loading";
import { apiClient } from "../../services/base";
import styles from "./dashboard.module.css";

const endpoints = [
  `${process.env.REACT_APP_API_URL}/fs_index/2`,
  `${process.env.REACT_APP_API_URL}/fs_index/3`,
  `${process.env.REACT_APP_API_URL}/fs_index/4`,
  `${process.env.REACT_APP_API_URL}/fs_index/5`,
  `${process.env.REACT_APP_API_URL}/fs_index/6`,
  `${process.env.REACT_APP_API_URL}/fs_index/7`,
  `${process.env.REACT_APP_API_URL}/fs_index/8`,
  `${process.env.REACT_APP_API_URL}/fs_index/9`,
];

export const Dashboard: FC = () => {
  const { t } = useTranslation("dashboard");

  const [selectedChartName, setSelectedChartName] = useState<string>("fundseeder");
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [restLoading, setRestLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .fsIndexIndexIdGet(1, { signal: controller.signal })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        axios
          .all(
            endpoints.map((endpoint) => {
              const getCurrentFsIndex = (value: any) => {
                const urlArray = value?.split("/");
                return urlArray[urlArray.length - 1];
              };
              return apiClient.fsIndexIndexIdGet(getCurrentFsIndex(endpoint), { signal: controller.signal });
            })
          )
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setRestLoading(false);
          });
      });
    return () => controller.abort();
  }, []);

  const handleGraphChange = useCallback((value: string) => {
    setSelectedChartName(value);
  }, []);

  const handleZoom = (value: boolean) => {
    setIsZoomed(value);
  };

  return (
    <>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>{t("fundSeederCompositeIndexes")}</div>
      </div>
      <div className={styles.mainContentWrapper}>
        <Row>
          <Col xs={24}>
            <GraphSelectCard handleGraphChange={handleGraphChange} selectedChartName={selectedChartName} />
          </Col>
        </Row>
        <Row className={styles.graphSelectionRow}>
          <Col xs={24}>
            <GraphSection
              selectedChartName={selectedChartName}
              isLoading={isLoading}
              handleZoom={handleZoom}
              isZoomed={isZoomed}
            />
          </Col>
        </Row>
        <Row className={styles.pastPerformanceRow}>
          <Col xs={24}>
            <p className={styles.pastPerformanceHeader}>
              <span>{t("pastPerformance")}.</span>
            </p>
            <p className={styles.pastPerformanceText}>
              <span>* {t("presentation")}.</span>
            </p>
          </Col>
        </Row>
        <div className={styles.leaderBoardsWrapper}>
          <Row className={styles.selectedLeaderboardRow}>
            <Col xs={24}>{restLoading || <SelectedLeaderBoard />}</Col>
          </Row>
          <Row className={styles.leaderboardRow}>
            <Col xs={24} className={styles.leaderCardsWrapper}>
              {restLoading ? <Loading /> : <Leaderboard />}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
