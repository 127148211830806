import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import { format } from "date-fns";
import "chartjs-adapter-date-fns";
import { Chart } from "react-chartjs-2";
import { ChartOptions, Tick } from "chart.js";
import { useAppSelector } from "../../hooks";
import styles from "./tradingAccount.module.css";

export const Underwater: FC = () => {
  const { t } = useTranslation("tradingAccount");
  const underwater = useAppSelector((state) => state.tradingAccount.underwater);
  const date = underwater.map((dayValue) => dayValue.date);
  const underwaterValues = underwater.map((dayValue) => dayValue.metric_value);
  const labels = date;
  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any[]) => {
            if (context.length) {
              return format(context[0].parsed.x, "MMM dd yyyy");
            }
            return format(new Date(Date.now()), "MMM dd yyyy");
          },
          label: (context: any) => {
            return ` ${t("performance")}: ${Number(context.raw)?.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        title: {
          display: true,
          text: t("Drawdown") + " (%)",
          padding: 2,
          color: "#72c6ed",
        },
        display: true,
        position: "left" as const,
        beginAtZero: true,
        offset: true,
        ticks: {
          color: "#72c6ed",
        },
        stacked: "single",
        max: 0,
        min: Math.min(...underwaterValues),
      },
      x: {
        axis: "x",
        type: "timeseries",
        min: date[0],
        max: date[date.length - 1],
        display: true,
        ticks: {
          callback: (value: string | number, index: number, ticks: Tick[]) => {
            if (labels.length && index === 0) {
              return format(new Date(labels[0]), "MMM yyyy");
            }
            if (labels.length && index !== 0) {
              if (index % Math.round(ticks.length / 6) === 0) {
                return value;
              }
            }
          },
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
          align: "center",
        },
      },
    },
  };
  const data = {
    labels: date,
    datasets: [
      {
        type: "line" as const,
        label: `${t("performance")}`,
        borderColor: "#72c6ed",
        borderWidth: 1,
        fill: {
          target: "origin",
          below: "#dff0f4",
        },
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgb(114, 198, 237)",
        data: underwaterValues,
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      {!underwater.length ? <Empty /> : <Chart id="underwater" options={options} type="bar" data={data} />}
    </div>
  );
};
