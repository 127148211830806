import React, { FC } from "react";
import { Col, Row, Table } from "antd";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { monthData } from "./reducer";
import { ceil10 } from "../../utils/decimalAdjustment";
import { useAppSelector } from "../../hooks";
import styles from "./tradingAccount.module.css";

const cx = classNames.bind(styles);

interface monthlyValue {
  date: string;
  value: number;
}

interface dataByMonth {
  value: number;
  date: string;
}

interface totalYearValue {
  value: number;
  date: number;
  year: boolean;
}

interface dataByYears {
  key: number;
  year: number;
  Jan?: dataByMonth;
  Feb?: dataByMonth;
  Mar?: dataByMonth;
  Apr?: dataByMonth;
  May?: dataByMonth;
  Jun?: dataByMonth;
  Jul?: dataByMonth;
  Aug?: dataByMonth;
  Sep?: dataByMonth;
  Oct?: dataByMonth;
  Nov?: dataByMonth;
  Dec?: dataByMonth;
  total: totalYearValue;
}

interface year {
  year: number;
}

interface yearValue {
  date: string;
  monthly_return: number;
}

interface performanceTableContent {
  handleModalOpen: (event: React.MouseEvent<HTMLTableCellElement>) => void;
}

export const PerformanceTableContent: FC<performanceTableContent> = ({ handleModalOpen }) => {
  const { t } = useTranslation("tradingAccount");
  const dailyReturn = useAppSelector((state) => state.tradingAccount.dailyReturn);
  const monthData = useAppSelector((state) => state.tradingAccount.monthData);

  // Array of unique years
  const years: year[] = [];
  monthData?.forEach((oneMonthData: monthData) => {
    if (
      years?.findIndex((yearObject: year) => yearObject?.year === new Date(oneMonthData?.date).getFullYear()) === -1
    ) {
      years.push({ year: new Date(oneMonthData?.date).getFullYear() });
    }
  });

  const columns = [
    {
      title: t("year"),
      key: "action",
      dataIndex: "year",
    },
    {
      title: t("jan"),
      dataIndex: "Jan",
      key: "Jan",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("feb"),
      dataIndex: "Feb",
      key: "Feb",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("mar"),
      dataIndex: "Mar",
      key: "Mar",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("apr"),
      key: "Apr",
      dataIndex: "Apr",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("may"),
      key: "May",
      dataIndex: "May",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("jun"),
      key: "Jun",
      dataIndex: "Jun",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("jul"),
      key: "Jul",
      dataIndex: "Jul",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("aug"),
      key: "Aug",
      dataIndex: "Aug",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("sep"),
      key: "Sep",
      dataIndex: "Sep",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("oct"),
      key: "Oct",
      dataIndex: "Oct",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("nov"),
      key: "Nov",
      dataIndex: "Nov",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("dec"),
      key: "Dec",
      dataIndex: "Dec",
      render: (monthlyValue: monthlyValue) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(monthlyValue?.value) < 0,
            })}
            data-id={monthlyValue?.date}
          >
            {monthlyValue?.value ? `${monthlyValue?.value}%` : null}
          </span>
        );
      },
    },
    {
      title: t("total"),
      key: "total",
      dataIndex: "total",
      render: (yearData: { date: number; value: number; year: boolean }) => {
        return (
          <span
            className={cx({
              performanceTableCellNegative: Number(yearData?.value) < 0,
            })}
            data-id={yearData?.date}
            data-year={yearData?.year}
          >
            {yearData?.value ? `${yearData?.value}%` : null}
          </span>
        );
      },
    },
  ];

  // Ready for table data
  const dataByYears: dataByYears[] = [];
  years.forEach((oneYearData: year) => {
    const yearValue = monthData.filter(
      (oneMonthData: monthData) => new Date(oneMonthData.date).getFullYear() === oneYearData.year
    );
    const currentYearFsReturn = dailyReturn.filter(
      (oneDayReturn) => new Date(oneDayReturn.date).getFullYear() === oneYearData.year
    );
    const calculatePerformance = (index: number) => {
      let sum: number = currentYearFsReturn[0].fs_return + 1;
      if (index === 0) {
        return currentYearFsReturn[0].fs_return * 100;
      }

      for (let i = index; i > 0; i--) {
        sum *= currentYearFsReturn[i].fs_return + 1;
      }

      return (sum - 1) * 100;
    };

    const performanceResult = currentYearFsReturn.map((data, index: number) => {
      return calculatePerformance(index);
    });

    // Prepare month return value for table cell
    const checkMonthData = (monthNumber: number) => {
      const currentDate = yearValue.find(
        (currentYear: yearValue) => new Date(currentYear?.date).getMonth() === monthNumber
      );
      if (currentDate) {
        return {
          value: ceil10(currentDate.monthly_return * 100, -2),
          date: currentDate.date,
        };
      }
    };
    // Each year total return
    const total = yearValue.reduce((sum: number, current: yearValue) => {
      return sum + current.monthly_return;
    }, 0);

    dataByYears.push({
      key: total,
      year: new Date(yearValue[0].date).getFullYear(),
      Jan: checkMonthData(0),
      Feb: checkMonthData(1),
      Mar: checkMonthData(2),
      Apr: checkMonthData(3),
      May: checkMonthData(4),
      Jun: checkMonthData(5),
      Jul: checkMonthData(6),
      Aug: checkMonthData(7),
      Sep: checkMonthData(8),
      Oct: checkMonthData(9),
      Nov: checkMonthData(10),
      Dec: checkMonthData(11),
      total: {
        value: Number(performanceResult[performanceResult.length - 1]?.toFixed(2)),
        date: new Date(yearValue[0].date).getFullYear(),
        year: true,
      },
    });
  });

  return (
    <Row>
      <Col sm={24}>
        <div className={styles.performanceHeader}>{t("pastPerformance")}.</div>
        <div className={styles.performanceSubHeader}>
          <span className={styles.mainSubHeader}>{t("monthlyPerformance")}</span>
        </div>
        <span className={styles.performanceTableDescription}>{t("clickOnAnyMonth")}</span>
        <Table
          columns={columns}
          dataSource={dataByYears}
          rowClassName={() => styles.performanceTableRow}
          className={styles.performanceTable}
          pagination={false}
          components={{
            body: {
              cell: (props: any) => {
                if (props.children[1]?.props?.children) {
                  return (
                    <td
                      className={styles.performanceTableCell}
                      colSpan={dataByYears.length ? 1 : 14}
                      onClick={(event: any) => handleModalOpen(event)}
                    >
                      {props.children[1]}
                    </td>
                  );
                }
                return (
                  <td className={styles.performanceTableEmptyCell} colSpan={dataByYears.length ? 1 : 14}>
                    {props.children[1]}
                  </td>
                );
              },
            },
            header: {
              cell: (props: any) => {
                return <th className={styles.columnHeader}>{props.children[1]}</th>;
              },
            },
          }}
        />
      </Col>
    </Row>
  );
};
