import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "../home.module.css";

export const TopSection = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.topSection}>
      <div className={styles.topSectionInner}>
        <div className={styles.topSectionWrp}>
          <h1 className={styles.title}>{t("democratizingTheAsset")}</h1>
          <h1 className={styles.titleWrp}>{t("managementWorld")}</h1>
          <p className={styles.text}>
            {t("fundSeederIsAFinancialData")}
            <br /> {t("investmentOpportunities")}.
          </p>
          <Link className={styles.signupBtn} to="/signup">
            {t("getItNowForFree")}!
          </Link>
        </div>
      </div>
    </div>
  );
};
