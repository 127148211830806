import React, { useRef, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Row, Col } from "antd";
import { Underwater } from "./underwater";
import { GraphChart } from "./chart";
import { ZoomChart } from "./zoomChart";
import { GraphZoom } from "./graphZoom";
import { Loading } from "../../components/loading";
import styles from "./tradingAccount.module.css";

const { TabPane } = Tabs;

interface graphTabs {
  underwaterLoading: boolean;
}

export const GraphTabs: FC<graphTabs> = ({ underwaterLoading }) => {
  const { t } = useTranslation("tradingAccount");
  const chartRef = useRef<any>();
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const handleZoom = (value: boolean) => {
    setIsZoomed(value);
  };

  return (
    <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
      <TabPane tab={t("cumReturnCurve")} key="1">
        <h1 className={styles.returnCurveHeader}>{t("cumulativeReturnChart")}.</h1>
        <GraphZoom handleZoom={handleZoom} />
        <Row>
          <Col sm={24} ref={chartRef}>
            {isZoomed ? <ZoomChart /> : <GraphChart />}
          </Col>
        </Row>
      </TabPane>
      <TabPane tab={t("underwaterCurve")} key="2">
        <h1 className={styles.returnCurveHeader}>{t("underwaterCurve")}</h1>
        <Row>
          <Col sm={24}>{underwaterLoading ? <Loading /> : <Underwater />}</Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};
