import React, { FC } from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { useAppSelector } from "../../hooks";
import styles from "./tradingAccount.module.css";

export const ZoomChart: FC = () => {
  const { t } = useTranslation("tradingAccount");
  const filteredDate = useAppSelector((state) => state.tradingAccount.filteredDate);
  const filteredFsReturn = useAppSelector((state) => state.tradingAccount.filteredFsReturn);

  const labels = filteredDate;
  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any[]) => {
            if (context.length) {
              return format(context[0].parsed.x, "MMM dd yyyy");
            }
            return format(new Date(Date.now()), "MMM dd yyyy");
          },
          label: (context: any) => {
            if (context.dataset.type === "line") {
              return ` ${t("performance")}: ${Number(context.raw).toFixed(2)}%`;
            }
            return "0";
          },
        },
        filter: (tooltipItem) => {
          return tooltipItem?.dataset?.type === "line";
        },
        position: "nearest",
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        title: {
          display: true,
          text: `${t("performance")} (%)`,
          padding: 2,
          color: "#72c6ed",
        },
        display: true,
        position: "left" as const,
        grid: {
          drawOnChartArea: true,
        },
        offset: true,
        max: Math.max(...filteredFsReturn),
        min: Math.min(...filteredFsReturn),
        ticks: {
          color: "#72c6ed",
        },
        stacked: "single",
      },
      x: {
        type: "time",
        ticks: {
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
          align: "center",
        },
        grid: {
          drawOnChartArea: true,
          drawTicks: true,
          offset: false,
          color: (context: any) => {
            if (context?.index !== 0 && context?.index !== 6) {
              return "rgb(229, 229, 229)";
            }
          },
        },
        offset: false,
        title: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `${t("performance")}`,
        borderColor: "#72c6ed",
        borderWidth: 2,
        fill: {
          target: "start",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgb(114, 198, 237)",
        data: filteredFsReturn,
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      {!filteredFsReturn.length ? <Empty /> : <Chart options={options} type="bar" data={data} />}
    </div>
  );
};
