import React from "react";
import { useTranslation } from "react-i18next";
import jackSchwager from "./assets/jack-schwager.png";
import book1 from "./assets/book1.png";
import book2 from "./assets/book2.png";
import book3 from "./assets/book3.png";
import book4 from "./assets/book4.png";
import book5 from "./assets/book5.png";
import styles from "./home.module.css";

export const TradersFeature = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.tradersFeature}>
      <div className={styles.tradersFeatureWrp}>
        <div className={styles.tradersFeatureTop}>
          <div className={styles.tradersFeatureMainImg}>
            <img src={jackSchwager} alt="Jack Schwager" />
          </div>
          <div>
            <br />
            <br />
            <p className={styles.titleWrp}>{t("fundSeederTradersFeatured")}</p>
            <p className={styles.titleSecondary}>{t("inLatestMarketWizardsBook")}</p>
            <p className={styles.tradersFeatureDescription}>{t("fundseederCoFounder")}.</p>
            <p className={styles.tradersFeatureDescription}>{t("undiscoveredMarketWizard")}.</p>
          </div>
        </div>
        <div className={styles.books}>
          <div className={styles.book}>
            <img className={styles.bookImg} src={book1} alt="" />
            <span className={styles.bookText}>{t("marketWizards")}</span>
          </div>
          <div className={styles.book}>
            <img className={styles.bookImg} src={book2} alt="" />
            <span className={styles.bookText}>{t("theNewMarketWizards")}</span>
          </div>
          <div className={styles.book}>
            <img className={styles.bookImg} src={book3} alt="" />
            <span className={styles.bookText}>{t("hedgeFundMarketWizards")}</span>
          </div>
          <div className={styles.book}>
            <img className={styles.bookImg} src={book4} alt="" />
            <span className={styles.bookText}>{t("theLittleBookOfMarketWizards")}</span>
          </div>
          <div className={styles.book}>
            <img className={styles.bookImg} src={book5} alt="" />
            <span className={styles.bookText}>{t("unknownMarketWizards")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
