export const home = {
  GET_DATA: "HOME_GET_DATA",
  GET_DATA_RECEIVED: "HOME_GET_DATA_RECEIVED",
};

export const main = {
  SET_COUNTRIES: "MAIN_SET_COUNTRIES",
  SET_CURRENCIES: "MAIN_SET_CURRENCIES",
  SET_FS_INDEX: "MAIN_SET_FS_INDEX",
  SET_INDICES: "MAIN_SET_INDICES",
  SET_LEADERBOARD: "MAIN_SET_LEADERBOARD",
  SET_MONTHLY_RETURN: "MAIN_SET_MONTHLY_RETURN",
  SET_SELECTED_LEADERBOARD: "MAIN_SET_SELECTED_LEADERBOARD",
  SET_TRADING_ACCOUNT: "MAIN_SET_TRADING_ACCOUNT",
  SET_TRADING_ACCOUNT_ID: "MAIN_SET_TRADING_ACCOUNT_ID",
  SET_TRADING_PLATFORMS: "MAIN_SET_TRADING_PLATFORMS",
  SET_USERS_ACCOUNTS: "MAIN_SET_USERS_ACCOUNTS",
  SET_USER_DATA: "MAIN_SET_USER_DATA",
  SET_USER_PROFILE: "MAIN_SET_USER_PROFILE",
};

export const tradingAccount = {
  RESET_GRAPH_ZOOM: "TRADING_ACCOUNT_RESET_GRAPH_ZOOM",
  SET_FILTERED_GRAPH_DATA: "TRADING_ACCOUNT_SET_FILTERED_GRAPH_DATA",
  SET_GRAPH_DATA: "TRADING_ACCOUNT_SET_GRAPH_DATA",
  SET_UNDERWATER: "MAIN_SET_UNDERWATER",
};
