import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Empty } from "antd";
import { format } from "date-fns";
import { DashboardChart } from "./chart";
import { useAppSelector } from "../../hooks";
import { Loading } from "../../components/loading";
import styles from "./dashboard.module.css";
import "../../App.css";

interface graphSection {
  handleZoom: (value: boolean) => void;
  isLoading: boolean;
  isZoomed: boolean;
  selectedChartName: string;
}

export const GraphSection: FC<graphSection> = ({ handleZoom, isLoading, isZoomed, selectedChartName }) => {
  const { t } = useTranslation("dashboard");
  const fsIndices = useAppSelector((state) => state.main.fsIndices);
  const fsIndex = useAppSelector((state) => state.main.fsIndex);
  const oneYearFsIndex = useAppSelector((state) => state.main.oneYearFsIndex);
  const selectedChartId = fsIndices?.find((fsIndicesItem) => fsIndicesItem?.index_name === selectedChartName)?.id || 1;
  const selectedChartData =
    fsIndex?.find((fsIndexItem) => Number(fsIndexItem?.id) === Number(selectedChartId))?.value || [];
  const zoomedFsIndex =
    oneYearFsIndex?.find((fsIndexItem) => Number(fsIndexItem?.id) === Number(selectedChartId))?.value || [];
  const dateArray = selectedChartData?.map((oneDayValue) => oneDayValue?.date);
  const zoomedGraphDate = zoomedFsIndex?.map((oneDay: any) => oneDay?.date);
  const performanceArray = selectedChartData?.map((oneDayValue: any) => oneDayValue?.performance);
  const minDateString = isZoomed ? zoomedGraphDate[0] : dateArray[0];
  const maxDateString = selectedChartData[selectedChartData?.length - 1]?.date;
  const minDate: string = minDateString ? format(new Date(minDateString), "MMM yyyy") : "";
  const maxDate: string = maxDateString ? format(new Date(maxDateString), "MMM yyyy") : "";
  const multilingualChartName = t(`${selectedChartName?.toUpperCase()}`);

  const renderChart = (chartToRender: any, isZoomed: boolean) => {
    if (chartToRender) {
      return <DashboardChart currentChart={chartToRender} isZoomed={isZoomed} />;
    }
  };

  return (
    <div className={styles.graphSectionWrapper}>
      {isLoading || !fsIndex.find((fsItem) => fsItem.id === selectedChartId) ? (
        <Loading />
      ) : (
        <>
          {selectedChartData.length ? (
            <>
              <Card
                title={`FundSeeder ${
                  selectedChartName === "fundseeder" ? "composite" : multilingualChartName.toLowerCase()
                } Indexes`}
                headStyle={{
                  fontSize: 36,
                  color: "#6fba8d",
                  textTransform: "capitalize",
                  lineHeight: 1.1,
                  borderBottom: "none",
                }}
                bodyStyle={{ paddingTop: 0 }}
                bordered={false}
                style={{ width: 250, minWidth: 250, borderRight: "1px solid #e1e8e8" }}
              >
                <div className={styles.fsTopIndex}>
                  <div className={styles.fsTopIndexHeader}>
                    <span className={styles.fsTopIndexName}>{t("fSTopTradersIndex")}</span>
                    <span className={styles.fsTopIndexValue}>
                      {performanceArray[performanceArray.length - 1].toFixed(2)}%
                    </span>
                    <br />
                  </div>
                  <div className={styles.fsTopIndexValues}>
                    <span>{minDate}</span> - <span>{maxDate}</span>
                  </div>
                </div>
                <div className={styles.buttonsHeader}>{t("dataForThe")}:</div>
                <div className={styles.chartZoomButtons}>
                  <Button size="small" className={styles.chartZoomButton} onClick={() => handleZoom(false)}>
                    {t("sinceInception")}
                  </Button>
                  <Button size="small" className={styles.chartZoomButton} onClick={() => handleZoom(true)}>
                    {t("past12Month")}
                  </Button>
                </div>
              </Card>
              {isZoomed ? renderChart(zoomedFsIndex, isZoomed) : renderChart(selectedChartData, isZoomed)}
            </>
          ) : (
            <Empty />
          )}
        </>
      )}
    </div>
  );
};
