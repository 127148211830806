import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "../home.module.css";
import { Title } from "../../../components/title";
import Person1 from "./person-1.png";
import Person1Set from "./person-1@2x.png";
import Person2 from "./person-2.png";
import Person2Set from "./person-2@2x.png";
import Person4 from "./person-4.png";
import Person4Set from "./person-4@2x.png";
import Person5 from "./person-5.png";
import Person5Set from "./person-5@2x.png";
import PersonKarl from "./person-karl.png";
import PersonKarlSet from "./person-karl@2x.png";
import PersonJems from "./person-jems.png";
import PersonJemsSet from "./person-jems@2x.png";
import Person7 from "./person-7.png";
import Person7Set from "./person-7@2x.png";
import Person8 from "./person-8.png";
import Person8Set from "./person-8@2x.png";
import Person9 from "./person-9.png";
import Person9Set from "./person-9@2x.png";
import Person10 from "./person-10.png";
import Person10Set from "./person-10@2x.png";

export const Team: FC = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.team} id="team">
      <div className={styles.teamWrp}>
        <div className={styles.relative}>
          <Title subTitle={t("team")} primary>
            {t("our")}
          </Title>
        </div>
        <br />
        <br />
        <br />
        <div className={styles.heading}>
          <span className={styles.headingText}>{t("executiveTeam")}</span>
        </div>
        <div className={styles.persons}>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person1} srcSet={`${Person1Set} 2x`} alt="Emanuel Balarie" />
            </div>
            <div className={styles.personTitle}>Emanuel Balarie</div>
            <div className={styles.personName}>
              {t("chairman")}, <br />
              {t("chiefExecutiveOfficer")}
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person2} srcSet={`${Person2Set} 2x`} alt="Jack Schwager" />
            </div>
            <div className={styles.personTitle}>Jack Schwager</div>
            <div className={styles.personName}>
              {t("chiefResearchOfficer")},
              <br /> {t("boardMember")}
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person4} srcSet={`${Person4Set} 2x`} alt="Georgios Efstathopoulos" />
            </div>
            <div className={styles.personTitle}>Georgios Efstathopoulos</div>
            <div className={styles.personName}>{t("chiefTechnologyOfficer")}</div>
          </div>

          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={PersonJems} srcSet={`${PersonJemsSet} 2x`} alt="Jim Vitalie" />
            </div>
            <div className={styles.personTitle}>Jim Vitalie</div>
            <div className={styles.personName}>{t("presidentFundseederInvestmentsLLC")}.</div>
          </div>

          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person5} srcSet={`${Person5Set} 2x`} alt="Gary Klopfenstein" />
            </div>
            <div className={styles.personTitle}>Gary Klopfenstein</div>
            <div className={styles.personName}>
              {t("strategicAdvisor")}, <br /> {t("boardMember")}
            </div>
          </div>

          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={PersonKarl} srcSet={`${PersonKarlSet} 2x`} alt="Karl V Chalupa" />
            </div>
            <div className={styles.personTitle}>Karl V Chalupa</div>
            <div className={styles.personName}>{t("chiefRiskOfficer")}</div>
          </div>
        </div>
        <div className={styles.heading}>
          <span className={styles.headingText}>{t("advisoryBoard")}</span>
        </div>
        <div className={styles.persons}>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person7} srcSet={`${Person7Set} 2x`} alt="Robert Santella" />
            </div>
            <div className={styles.personTitle}>Robert Santella</div>
            <div className={styles.personName}>
              {t("chiefExecutiveOfficer")}
              <br /> IPC
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person8} srcSet={`${Person8Set} 2x`} alt="Alvin Fan" />
            </div>
            <div className={styles.personTitle}>Alvin Fan</div>
            <div className={styles.personName}>
              {t("directorCEO")}
              <br /> {t("oPInvestmentManagement")}
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person9} srcSet={`${Person9Set} 2x`} alt="Brendan Holt Dunn" />
            </div>
            <div className={styles.personTitle}>Brendan Holt Dunn</div>
            <div className={styles.personName}>
              CEO
              <br /> {t("holdunFamilyOffice")}
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <img src={Person10} srcSet={`${Person10Set} 2x`} alt="John Omahen" />
            </div>
            <div className={styles.personTitle}>John Omahen</div>
            <div className={styles.personName}>
              {t("seniorVicePresident")},
              <br /> {t("productManagement")}
              <br /> {t("fISGlobal")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
