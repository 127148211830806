import React, { FC } from "react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QuestionCircleFilled } from "@ant-design/icons";
import { main } from "../../constants/actionTypes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import styles from "./dashboard.module.css";

export const SelectedLeaderBoard: FC = () => {
  const { t } = useTranslation("dashboard");
  const dispatch = useAppDispatch();
  const fsIndices = useAppSelector((state) => state.main.fsIndices);
  const currentChart = fsIndices.find((selectedChart) => selectedChart.index_name === "fundseeder");
  const leadersList = currentChart?.leaders.map((leader, index) => {
    return (
      <Link to={`/trading-account/${leader?.account_id}`} className={styles.tradingAccountLink} key={leader?.rank}>
        <li
          className={styles.selectedLeaderboardListItem}
          onClick={() => {
            dispatch({
              type: main.SET_TRADING_ACCOUNT_ID,
              payload: leader?.account_id,
            });
          }}
        >
          <span className={styles.leaderboardPosition}>{index + 1}</span>
          <span>{leader?.fs_nick}</span>
          <span className={styles.leaderboardScore}>{leader?.fs_score?.toFixed()}</span>
        </li>
      </Link>
    );
  });
  return (
    <div className={styles.selectedLeaderboard}>
      <span className={styles.selectedLeaderBoardHeader}>
        {t("lEADERBOARDTOP3FSScore")}{" "}
        <Tooltip
          title={() => {
            return (
              <span>
                {t("fundSeederScore")}{" "}
                <a href="https://uat.fundseeder.com/FundSeeder%20Analytics%20Manual.pdf">{t("link")}</a>
              </span>
            );
          }}
          trigger="click"
        >
          <QuestionCircleFilled className={styles.leaderboardQuestionIcon} />
        </Tooltip>
      </span>
      <ul className={styles.selectedLeaders}>{leadersList}</ul>
    </div>
  );
};
