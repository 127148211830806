import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, Button, DatePicker, Checkbox } from "antd";
import type { DatePickerProps } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import classNames from "classnames/bind";
import { searchResult } from "./source";
import { SearchTableChart } from "./chart";
import styles from "./search.module.css";

const cx = classNames.bind(styles);

export const SearchResult: FC = () => {
  const { t } = useTranslation("search");
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [personalInfo, setPersonalInfo] = useState<boolean>(false);
  const tableData = searchResult.result;
  const columns = [
    {
      title: t("rank"),
      dataIndex: "tplatform_id",
      key: "Rank",
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("account"),
      dataIndex: "nick",
      key: "Account",
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: "",
      dataIndex: "performance_chart",
      key: "Graph",
      width: "130px",
      render: (performanceChart: string[]) => {
        return <SearchTableChart performance={performanceChart} />;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("fSScore"),
      dataIndex: "fs_score_ver",
      key: "FS Score",
      render: (fsScore: number) => {
        return fsScore.toFixed();
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
      sorter: (a: any, b: any) => {
        return a.fs_score_ver - b.fs_score_ver;
      },
    },
    {
      title: t("return"),
      dataIndex: "performance_ver",
      key: "Return",
      render: (performance: number) => {
        return `${(performance * 100).toFixed()}%`;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("assets"),
      dataIndex: "leaderboards",
      key: "Assets",
      render: (leaderboards: any) => {
        if (leaderboards) {
          return Object.keys(leaderboards)?.map((boardName: string) => {
            if (boardName.split("_")[0] === "mt") {
              const boardNameCard = boardName.split("_")[1]?.slice(0, 2);
              return (
                <span className={styles.assetsItems} key={boardName}>
                  {boardNameCard}
                </span>
              );
            }
          });
        }
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("strategy"),
      dataIndex: "leaderboards",
      key: "Strategy",
      render: (leaderboards: any) => {
        if (leaderboards) {
          return Object.keys(leaderboards)?.map((boardName: string) => {
            if (boardName.split("_")[0] === "tm") {
              const boardNameCard = boardName.split("_")[1]?.slice(0, 2);
              return (
                <span className={styles.strategyItems} key={boardName}>
                  {boardNameCard}
                </span>
              );
            }
          });
        }
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("timeTracked"),
      dataIndex: "time_tracked",
      key: "Time Tracked All",
      render: (time: number) => {
        const years = Math.floor(time / 365);
        const months = Math.floor((time % 365) / 30);
        const days = Math.floor((time % 365) % 30);
        const yearsToDisplay = years ? `${years}Y /` : "";
        const monthsToDisplay = months ? `${months}m /` : "";
        const daysToDisplay = `${days}d`;
        return `${yearsToDisplay} ${monthsToDisplay} ${daysToDisplay}`;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
      sorter: (a: any, b: any) => {
        return a.time_tracked - b.time_tracked;
      },
    },
    {
      title: t("accountSize"),
      dataIndex: "nominal_size_usd_ver",
      key: "Account Size All",
      className: styles.tableColumn,
      render: (size: number) => {
        return `$${Math.ceil(size).toLocaleString("en-US")}`;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
      sorter: (a: any, b: any) => {
        return a.nominal_size_usd_ver - b.nominal_size_usd_ver;
      },
    },
  ];

  const handleStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    console.log(startDate);
  };

  const handleEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    console.log(endDate);
  };

  const handlePersonalInfo = (e: CheckboxChangeEvent) => {
    setPersonalInfo(e.target.checked);
    console.log(personalInfo);
  };

  const handleViewFullAccount = (id: number) => {
    navigate(`/trading-account/${id}`);
  };

  return (
    <Table
      dataSource={tableData}
      columns={columns}
      tableLayout="fixed"
      rowClassName={styles.tableRow}
      onRow={({ key }): any => {
        if (expandedRows.includes(key)) {
          return { className: styles.expandedRow };
        }
      }}
      expandable={{
        onExpandedRowsChange: (rows: any) => {
          setExpandedRows(rows);
        },
        expandedRowRender: (record) => {
          const icons = (type: string) => {
            if (record.leaderboards) {
              return Object.keys(record.leaderboards)?.map((boardName: string) => {
                if (boardName.split("_")[0] === type) {
                  const boardNameCard = boardName.split("_")[1]?.slice(0, 2);
                  return (
                    <span className={styles.strategyItems} key={boardName}>
                      {boardNameCard}
                    </span>
                  );
                }
              });
            } else return "";
          };
          return (
            <>
              <Row className={styles.tableExpandRow}>
                <Col xs={24} sm={6}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("accountSizeNLV")}</span>
                      <span className={styles.expandItemValue}>
                        ${record.nominal_size_usd_ver.slice(0, record.nominal_size_usd_ver.indexOf("."))}
                      </span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("strategy")}</span>
                      <span className={styles.expandItemValue}>{icons("tm")}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("assetsTraded")}</span>
                      <span className={styles.expandItemValue}>{icons("mt")}</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={24} sm={6}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("annualizedReturn")}</span>
                      <span className={styles.expandItemValue}>{(record.performance_ann_ver * 100).toFixed(2)}%</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("annualizedVolatility")}</span>
                      <span className={styles.expandItemValue}>{(record.vol_ver * 100).toFixed(2)}%</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("sharpeRatio")}</span>
                      <span className={styles.expandItemValue}>{record.sharpe_ver.toFixed(2)}</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={24} sm={6}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("cumulativeReturn")}</span>
                      <span className={styles.expandItemValue}>{(record.performance_ver * 100).toFixed(2)}%</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("maximumDrawdown")}</span>
                      <span className={styles.expandItemValue}>{(record.maxdd_ver * 100).toFixed(2)}%</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("sortinoRatio")}</span>
                      <span className={styles.expandItemValue}>{record.sortino_ver.toFixed(2)}</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={24} sm={6}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("fSScore")}</span>
                      <span className={styles.expandItemValue}>{record.fs_score_ver.toFixed()}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("dailyGPR")}</span>
                      <span className={styles.expandItemValue}>{record.gpr_ver.toFixed(2)}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("monthlyGPR")}</span>
                      <span className={styles.expandItemValue}>{record.gpr_monthly_ver.toFixed(2)}</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={12}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("strategy")}</span>
                      <span className={styles.expandItemValue}>{record.strategy}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("userFullName")}</span>
                      <span className={styles.expandItemValue}>{record.user_full_name}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("email")}</span>
                      <span className={styles.expandItemValue}>{record.user.email}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("education")}</span>
                      <span className={styles.expandItemValue}>{record.user.education_name}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("regulated")}</span>
                      <span className={styles.expandItemValue}>{record.user.user_regulatorybody}</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={12}>
                  <ul className={styles.tableExpandList}>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("edge")}</span>
                      <span className={styles.expandItemValue}>{record.edge_sources}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("Phone")}</span>
                      <span className={styles.expandItemValue}>
                        {record.user.phone_1 || record.user.phone_2 || "-"}
                      </span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("address")}</span>
                      <span className={styles.expandItemValue}>
                        {record.user.address_1 || ""}
                        {record.user.address_2 || ""}
                      </span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("numberOfTradingExperience")}</span>
                      <span className={styles.expandItemValue}>{record.user.years_experience}</span>
                    </li>
                    <li className={styles.tableExpandItem}>
                      <span className={styles.expandItemName}>{t("seekingInvestorAssetsForManagementOrNot")}</span>
                      <span className={styles.expandItemValue}>
                        {record.user.seeking_for_investor ? "seeking" : "not seeking"}
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className={styles.calcMethodRow}>
                <Col xs={6} className={styles.calcMethodColumn}>
                  <div className={styles.calcMethodHeader}>{t("calculationMethod")}</div>
                  <div>{record.last_calc_method}</div>
                </Col>
                <Col xs={6} className={styles.calcMethodColumn}>
                  <div className={styles.calcMethodHeader}>{t("dateOfStartCalculation")}</div>
                  <div>{record.inception_date_ver}</div>
                </Col>
                <Col xs={6} className={styles.calcMethodColumn}>
                  <div className={styles.calcMethodHeader}>{t("dateOfRequest")}</div>
                  <div>{record.created_at}</div>
                </Col>
                <Col xs={6} className={styles.calcMethodColumn}>
                  <div className={styles.calcMethodHeader}>{t("nAS")}</div>
                </Col>
              </Row>
              <Row className={styles.calcMethodButtonsRow}>
                <Col xs={8} className={styles.calcMethodButtonsWrapper}>
                  <Button className={styles.calcMethodButtons} onClick={() => handleViewFullAccount(record.id)}>
                    {t("viewFullAccount")}
                  </Button>
                </Col>
                <Col xs={8} className={styles.calcMethodButtonsWrapper}>
                  <Button className={styles.calcMethodButtons}>{t("analytics")}</Button>
                </Col>
                <Col xs={8} className={styles.calcMethodButtonsWrapper}>
                  <Button className={styles.calcMethodButtons}>{t("trades")}</Button>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={styles.downloadXLSWrapper}>
                  <DatePicker onChange={handleStartDate} placeholder="Start date" />
                </Col>
                <Col xs={6} className={styles.downloadXLSWrapper}>
                  <DatePicker onChange={handleEndDate} placeholder="End date" />
                </Col>
                <Col xs={6} className={cx("downloadXLSWrapper", "personalInfoCheckbox")}>
                  <Checkbox onChange={handlePersonalInfo}>{t("personalInfo")}</Checkbox>
                </Col>
                <Col xs={6} className={styles.downloadXLSWrapper}>
                  <Button className={styles.calcMethodButtons}>{t("downloadInXLSWithNAVs")}</Button>
                </Col>
              </Row>
            </>
          );
        },
        expandIcon: () => <></>,
        expandIconColumnIndex: -1,
        expandRowByClick: true,
      }}
    />
  );
};
