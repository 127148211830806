import { notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { daily_returns, monthData } from "../pages/tradingAccount/reducer";
import { main, tradingAccount } from "../constants/actionTypes";
import { RestfulApi, Configuration } from "./pdaw";
import { axiosClient } from "./api";

export const SetupInterceptors = (navigate: any, dispatch: any, t: any) => {
  const navigateToLogin = () => navigate("/login");
  const { logout } = useAuth0();

  const logOut = () => {
    window.localStorage.removeItem("token");
    logout({ returnTo: `${window.location.origin}` });
  };

  const checkUrl = (value: any) => {
    const urlArray = value?.split("/");
    return urlArray[urlArray?.length - 2];
  };

  axiosClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (config !== undefined && config.headers !== undefined) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    (response) => {
      if (response?.status === 200) {
        if (checkUrl(response.config?.url) === "user_trading_accounts" && response.config?.method === "get") {
          dispatch({
            type: main.SET_USERS_ACCOUNTS,
            payload: response.data,
          });
        }
        if (
          checkUrl(response.config?.url) === "user_info" &&
          (response.config?.method === "get" || response.config?.method === "put")
        ) {
          dispatch({
            type: main.SET_USER_PROFILE,
            payload: response.data,
          });
        }
        if (checkUrl(response.config?.url) === "countries" && response.config?.method === "get") {
          dispatch({
            type: main.SET_COUNTRIES,
            payload: response.data,
          });
        }
        if (checkUrl(response.config?.url) === "currencies" && response.config?.method === "get") {
          dispatch({
            type: main.SET_CURRENCIES,
            payload: response.data,
          });
        }
        if (response.config?.url?.includes("fs_index/") && response.config?.method === "get") {
          const requestUrl = response.request.responseURL.split("/");
          const chartId = requestUrl[requestUrl?.length - 2];
          dispatch({
            type: main.SET_FS_INDEX,
            payload: {
              id: Number(chartId),
              value: response.data,
            },
          });
        }
        if (checkUrl(response.config?.url) === "fs_indices" && response.config?.method === "get") {
          if (Array.isArray(response.data)) {
            dispatch({
              type: main.SET_INDICES,
              payload: response.data,
            });
          }
        }
        if (response.config?.url && /reset\/\d{5}/.test(response.config?.url) && response.config?.method === "post") {
          notification.success({
            message: t("yourPasswordHasBeenSuccessfullyReset"),
          });
        }
        if (checkUrl(response.config?.url) === "reset" && response.config?.method === "post") {
          notification.success({
            message: t("passwordReset"),
          });
        }
        if (checkUrl(response.config?.url) === "trading_platforms" && response.config?.method === "get") {
          dispatch({
            type: main.SET_TRADING_PLATFORMS,
            payload: response.data,
          });
        }
        if (checkUrl(response.config?.url) === "user_trading_accounts" && response.config?.method === "post") {
          dispatch({
            type: main.SET_TRADING_ACCOUNT_ID,
            payload: response.data?.id,
          });
        }
        if (checkUrl(response.config?.url) === "user" && response.config?.method === "delete") {
          logOut();
        }
        if (
          response.config?.url &&
          /returns_list\/\d{5}/.test(response.config?.url) &&
          response.config?.method === "get"
        ) {
          const returnData: number[] = [];
          const performanceData: number[] = [];
          const dateData: string[] = [];
          const fullMonthData: monthData[] = [];
          const fsReturn: number[] = [];
          const fullDailyReturn: any[] = [];
          // Daily data
          response.data?.daily_returns?.map((oneDayReturn: daily_returns) => {
            dateData.push(oneDayReturn?.date);
            performanceData.push(oneDayReturn?.performance * 100);
            fsReturn.push(oneDayReturn?.fs_return);
            fullDailyReturn.push(oneDayReturn);
          });
          // Monthly data
          response.data?.monthly_returns?.map((oneMonthReturn: monthData) => {
            returnData.push(oneMonthReturn?.monthly_return * 100);
            fullMonthData.push(oneMonthReturn);
          });
          dispatch({
            type: tradingAccount.SET_GRAPH_DATA,
            payload: {
              returnValue: returnData,
              performance: performanceData,
              date: dateData,
              monthData: fullMonthData,
              dailyFsReturn: fsReturn,
              dailyReturn: fullDailyReturn,
            },
          });
        }
        if (
          response.config?.url &&
          /underwater\/\d{5}/.test(response.config?.url) &&
          response.config?.method === "get"
        ) {
          dispatch({
            type: tradingAccount.SET_UNDERWATER,
            payload: response.data,
          });
        }
        if (checkUrl(response.config?.url) === "users" && response.config?.method === "post") {
          notification.info({
            message: t("accountHasBeenCreated"),
          });
        }
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 401 || error.response?.data?.error === "signing_key_unavailable") {
        dispatch({
          type: main.SET_USER_DATA,
          payload: { accessToken: "", userId: "" },
        });
        window.localStorage.removeItem("token");
        navigateToLogin();
      } else {
        if (error.config?.data?.type === "user_info_update") {
          notification.error({
            message: t("profileUpdateFailed"),
          });
        }
        if (checkUrl(error.config?.url) === "user_communication" && error.config?.method === "put") {
          notification.error({
            message: t("failedToSubmitData"),
          });
        }
        if (checkUrl(error.config?.url) === "user" && error.config?.method === "delete") {
          notification.error({
            message: t("accountDeleteFailed"),
          });
        }
        if (error.config?.url && /reset\/\d{5}/.test(error.config?.url) && error.config?.method === "post") {
          notification.error({
            message: t("passwordResetError"),
          });
        }
        if (checkUrl(error.config?.url) === "reset" && error.config?.method === "post") {
          notification.error({
            message: `${t("passwordResetError")}`,
          });
        }
        if (checkUrl(error.config?.url) === "user_trading_accounts" && error.config?.method === "post") {
          notification.error({
            message: `${t("accountWasNotCreated")}`,
          });
        }
        if (error.config?.url && /returns_list\/\d{5}/.test(error.config?.url) && error.config?.method === "get") {
          dispatch({
            type: tradingAccount.SET_GRAPH_DATA,
            payload: {
              returnValue: [],
              performance: [],
              date: [],
              monthData: [],
              dailyFsReturn: [],
            },
          });
        }
        if (checkUrl(error.config?.url) === "users" && error.config?.method === "post") {
          notification.error({
            message: t("accountWasNotCreated"),
          });
        }
      }
    }
  );
};
const configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
});
export const apiClient = new RestfulApi(configuration, process.env.REACT_APP_API_URL, axiosClient);
