import React, { FC } from "react";
import classNames from "classnames/bind";
import { Spin, Space } from "antd";
import styles from "./loading.module.css";

const cx = classNames.bind(styles);

interface loading {
  overContent?: boolean;
}

export const Loading: FC<loading> = ({ overContent }) => {
  return (
    <div className={cx("spinnerWrapper", { overContent: overContent })} data-testid="loading">
      <Space size="large">
        <Spin size="large" />
      </Space>
    </div>
  );
};
