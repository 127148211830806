import React, { FC } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Title } from "../../components/title";
import iconGraph from "./assets/iconGraph.svg";
import iconCircle from "./assets/iconCircle.svg";
import iconStar from "./assets/iconStar.svg";
import iconClock from "./assets/iconClock.svg";
import styles from "./home.module.css";

export const OurPlatform: FC = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <div className={styles.ourPlatform} id="ourPlatform">
        <div className={styles.ourPlatformWrp}>
          <Title className={styles.center} primary subTitle={t("platformProvides")}>
            {t("our")}
          </Title>
          <div className={styles.providers}>
            <div className={styles.provider}>
              <p>
                <img src={iconStar} alt="icon" />
              </p>
              <p className={styles.providerTitle}>
                {t("proprietaryStatistics")}
              </p>
              <p className={styles.providerDescription}>
                {t("riskMeasures")}.
              </p>
            </div>
            <div className={styles.provider}>
              <p>
                <img src={iconCircle} alt="icon" />
              </p>
              <p className={styles.providerTitle}>{t("setOfSpecializedCharts")}</p>
              <p className={styles.providerDescription}>
                {t("varietyOfSpecialized")}
              </p>
            </div>
            <div className={styles.provider}>
              <p>
                <img src={iconGraph} alt="icon" />
              </p>
              <p className={styles.providerTitle}>{t("indexBenchmarks")}</p>
              <p className={styles.providerDescription}>
                {t("abilityToCompare")}.
              </p>
            </div>
            <div className={styles.provider}>
              <p>
                <img src={iconClock} alt="icon" />
              </p>
              <p className={styles.providerTitle}>{t("proprietaryTools")}</p>
              <p className={styles.providerDescription}>
                {t("rollingIndicator")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ourMission} id="ourMission">
        <div className={styles.ourPlatformWrp}>
          <Title
            className={cn(styles.center, styles.mb70)}
            primary
            subTitle={t("mission")}
          >
            {t("our")}
          </Title>
          <Row justify="center">
            <Col xs={12}>
              <p className={styles.mediumText}>
                {t("vastMajority")}.
              </p>
            </Col>
            <Col xs={12}>
              <p className={cn(styles.pl15, styles.pr15, styles.mediumText)}>
                {t("findingSkilledTraders")}.
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};
