import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { HashLink } from "react-router-hash-link";
import { DownOutlined } from "@ant-design/icons";
import { useAppSelector } from "../hooks";
import footerLogo from "../assets/footerLogo.svg";
import styles from "./header.module.css";

const { Option } = Select;

export const Header = () => {
  const { t, i18n } = useTranslation("header");
  const [language, setLanguage] = useState("enGB");
  const accessToken = useAppSelector((state) => state.main.accessToken);
  const { logout } = useAuth0();

  const logOut = () => {
    window.localStorage.removeItem("token");
    logout({ returnTo: `${window.location.origin}` });
  };

  const scrollFunction = (el: HTMLElement) => {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }, 300);
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerWrp}>
          <div className={styles.logo}>
            <Link to="/dashboard">
              <img src={footerLogo} alt={t("fundSeeder")} />
            </Link>
          </div>
          <div className={styles.headerWrp2}>
            <nav className={styles.nav}>
              <HashLink to="/#ourPlatform" scroll={scrollFunction}>
                {t("platformProvides")}
              </HashLink>
              <HashLink to="/#ourMission" scroll={scrollFunction}>
                {t("ourMission")}
              </HashLink>
              <HashLink to="/#team" scroll={scrollFunction}>
                {t("team")}
              </HashLink>
              <HashLink to="/#investors" scroll={scrollFunction}>
                {t("investors")}
              </HashLink>
              <HashLink to="/#footer" scroll={scrollFunction} className={styles.contactUs}>
                {t("contactUs")}
              </HashLink>
            </nav>
          </div>
          <div className={styles.auth}>
            {false && <Link to="/admin">{t("admin")}</Link>}
            {accessToken ? (
              <>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  bordered={false}
                  className={styles.languageSelect}
                  suffixIcon={<DownOutlined className={styles.languageSelectIcon} />}
                >
                  <Option value="enGB">English</Option>
                  <Option value="grGR">Greek</Option>
                </Select>
                <Link to="/my-account">{t("myAccount")}</Link>
                <button onClick={logOut} className={styles.logoutButton}>
                  {t("logout")}
                </button>
              </>
            ) : (
              <>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  bordered={false}
                  className={styles.languageSelect}
                  suffixIcon={<DownOutlined className={styles.languageSelectIcon} />}
                >
                  <Option value="enGB">English</Option>
                  <Option value="grGR">Greek</Option>
                </Select>
                <Link to="/login">{t("signIn")}</Link>
                <Link to="/signup" className={styles.getStarted}>
                  {t("getStarted")}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
