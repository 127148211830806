import React from "react";

import { Loading } from "../components/loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const { user } = useAuth0();

  return (
    <>
      <img
        src={user?.picture}
        alt="Profile"
        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
      />
      <h2>{user?.name} test</h2>
      <p className="lead text-muted">{user?.email}</p>
      {JSON.stringify(user, null, 2)}
    </>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
