import React, { useEffect } from "react";
import { Investors } from "./investors/index";
import { Support } from "./support/index";
import { Footer } from "./footer";
import { TopSection } from "./topSection";
import { Header } from "../../components/header";
import { OurPlatform } from "./ourPlatform";
import { StartUsing } from "./startUsing";
import { TradersFeature } from "./tradersFeature";
import { Team } from "./team";

export const Home = () => {
  useEffect(() => {
    document.title = "FundSeeder: Performance Analytics, Risk Management For Traders, Emerging Manager Support, Trading Education";
  }, []);

  return (
    <>
      <Header />
      <TopSection />
      <OurPlatform />
      <StartUsing />
      <TradersFeature />
      <Team />
      <Investors />
      <Support />
      <Footer />
    </>
  );
};
