import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/logo_small.svg";
import Lamp from "../../assets/lamp.svg";
import Gear from "../../assets/gear.svg";
import Headphones from "../../assets/headphones.svg";
import styles from "./signup.module.css";
import { apiClient } from "../../services/base";

interface ForgotFormData {
  email?: string;
}

export const ForgotPassword = () => {
  const { t } = useTranslation("forgotPassword");

  const [forgotForm, setForgotForm] = useState<ForgotFormData>({});
  const [emailHasSent, setEmailHasSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeHandler = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForgotForm((previousValues) => {
      return { ...previousValues, [name]: event.target.value };
    });
  };

  const onSubmitHandler = (event: FormEvent) => {
    if (!loading) {
      event.preventDefault();
      setLoading(true);
      apiClient
        .resetPost({
          email: forgotForm.email,
        })
        .then(() => {
          setEmailHasSent(true);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div className={styles.logoText}>
          {loading ? (
            <img className={styles.logoImg} src={Logo} />
          ) : (
            <Link to="/">
              <img className={styles.logoImg} src={Logo} />
            </Link>
          )}
          {t("forgotPassword")}
        </div>
        <form action="" onSubmit={onSubmitHandler}>
          {emailHasSent ? (
            <h2 className={styles.wrpInputs}>
              <strong className={styles.success}>
                {t("resetLink")} {forgotForm.email}
              </strong>
            </h2>
          ) : (
            <Spin spinning={loading} tip={t("loading")}>
              <div className={styles.wrpInputs}>
                <div className={styles.controlItem}>
                  <label className={styles.controlLabel} htmlFor="email">
                    {t("email")}
                    <sup>*</sup>
                  </label>
                  <Input
                    required
                    id="email"
                    placeholder={t("email")}
                    type="email"
                    onChange={onChangeHandler("email")}
                    className={styles.input}
                    disabled={loading}
                  />
                </div>

                <div className={styles.loginBtnWrp}>
                  <Button className={styles.loginBtn} type="primary" htmlType="submit" disabled={!forgotForm.email}>
                    {t("submit")}
                  </Button>
                </div>
                <div className="text-center">
                  <p className={styles.controlLabel}>
                    {t("dontHaveAnAccount")}?{" "}
                    {loading ? t("createAccount") : <Link to="/signup">{t("createAccount")}</Link>}
                  </p>
                </div>
              </div>
            </Spin>
          )}
        </form>
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrp}>
          <h1 className={styles.title}>
            {t("whatWe")}
            <span className={styles.titleWrp}>
              <span className={styles.titleText}>{t("offer")}</span>
            </span>
            {t("inOurProduct")}
          </h1>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Lamp} alt={t("flexibleIntegration")} />
              </div>
              <h2 className={styles.h2}>{t("flexibleIntegration")}</h2>
              <p>{t("provision")}</p>
            </div>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Gear} alt={t("customization")} />
              </div>
              <h2 className={styles.h2}>{t("customization")}</h2>
              <p>{t("weOfferIntegration")}</p>
            </div>
          </div>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Headphones} alt={t("support")} />
              </div>
              <h2 className={styles.h2}>{t("support")}</h2>
              <p>{t("ourTools")}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
