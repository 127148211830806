import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Input, notification, Select, Form } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from "@ant-design/icons";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import auth0 from "auth0-js";
import Logo from "../../assets/logo_small.svg";
import Lamp from "../../assets/lamp.svg";
import Gear from "../../assets/gear.svg";
import Headphones from "../../assets/headphones.svg";
import Analytics from "../../assets/analytics.svg";
import Wallet from "../../assets/wallet.svg";
import Increase from "../../assets/increase.svg";
import Ceo from "../../assets/ceo.svg";
import auth0Params from "../../auth0-params.json";
import { encryptPassword } from "../../utils/encryptPassword";
import { main } from "../../constants/actionTypes";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { showServerErrorMsg } from "../../utils/expiredTokenResponse";
import { getCookie, setCookie } from "../../utils/manageCookies";
import { apiClient } from "../../services/base";
import styles from "./signup.module.css";

const auth0Client = new auth0.WebAuth({
  domain: auth0Params.domain,
  clientID: auth0Params.clientId,
  audience: auth0Params.apiAudience,
  redirectUri: auth0Params.callbackUrl,
  scope: auth0Params.scope,
  responseType: "token id_token",
});

interface country {
  alpha_3: string;
  name: string;
}

interface SignupFormData {
  country?: country;
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  name?: string;
  surname?: string;
}

interface authResult {
  accessToken: string;
  expiresIn: number;
  scope: string;
  tokenType: string;
}

export const Signup = () => {
  const { t } = useTranslation("signUp");
  const navigate = useNavigate();
  const countries = useAppSelector((state) => state.main.countries);

  const [signupForm, setSignupForm] = useState<SignupFormData>({ country: { alpha_3: "USA", name: "United States" } });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Sign up";
  }, []);

  useEffect(() => {
    apiClient.countriesGet();
  }, []);

  const onChangeHandler = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignupForm((previousValues) => {
      return { ...previousValues, [name]: event.target?.value || event };
    });
  };

  const handleCountryChange = (value: string) => {
    const selectedCountry = countries.find((country) => country.alpha_3 === value);
    if (selectedCountry) {
      setSignupForm((previousValues) => {
        return { ...previousValues, country: { alpha_3: selectedCountry.alpha_3, name: selectedCountry.name } };
      });
    }
  };

  const embeddedSignup = () => {
    const encryptedPassword = encryptPassword(signupForm.password!);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users`, {
        email: signupForm.email || "",
        encrypted_password: encryptedPassword || "",
        name: signupForm.name || "",
        surname: signupForm.surname || "",
        country: signupForm.country,
      })
      .then(() => {
        setCookie("email", signupForm.email, false);
        notification.info({
          message: t("accountHasBeenCreated"),
        });
        navigate("/login");
      })
      .catch((err) => {
        showServerErrorMsg(err, "Could not signup");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitHandler = (): void => {
    if (!isLoading) {
      setIsLoading(true);
      embeddedSignup();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div className={styles.logoText}>
          <Link to="/">
            <img className={styles.logoImg} src={Logo} />
          </Link>
          {t("signUp")}
        </div>
        <Form
          action=""
          onFinish={onSubmitHandler}
          layout="vertical"
          initialValues={{
            Country: ["USA"],
          }}
        >
          <div className={styles.wrpInputs}>
            <div className={styles.controlItem}>
              <Form.Item name={["Name"]} label={t("name")} rules={[{ required: true }]}>
                <Input
                  id="name"
                  placeholder={t("name")}
                  onChange={onChangeHandler("name")}
                  required
                  className={styles.input}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <Form.Item name={["Second name"]} label={t("secondName")} rules={[{ required: true }]}>
                <Input
                  id="Second name"
                  placeholder={t("secondName")}
                  onChange={onChangeHandler("surname")}
                  required
                  className={styles.input}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <Form.Item name={["Email"]} label={t("email")} rules={[{ type: "email", required: true }]}>
                <Input
                  id="email"
                  placeholder={t("email")}
                  onChange={onChangeHandler("email")}
                  required
                  className={styles.input}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <Form.Item name={["Password"]} label={t("password")} rules={[{ required: true }]} hasFeedback>
                <Input.Password
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  id="password"
                  placeholder={t("passwordPlaceholder")}
                  onChange={onChangeHandler("password")}
                  required
                  className={styles.input}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <Form.Item
                name={["Password Confirmation"]}
                label={t("passwordConfirmation")}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("Password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
                dependencies={["Password"]}
                hasFeedback
              >
                <Input.Password
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  id="passwordConfirmation"
                  placeholder={t("passwordPlaceholder")}
                  onChange={onChangeHandler("passwordConfirmation")}
                  required
                  className={styles.input}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <Form.Item name={["Country"]} label={t("country")}>
                <Select
                  id="country"
                  style={{ width: "100%" }}
                  onChange={handleCountryChange}
                  options={countries.map((country) => {
                    return { label: country.name, value: country.alpha_3 };
                  })}
                  className={styles.select}
                  disabled={isLoading}
                ></Select>
              </Form.Item>
            </div>
            {/*
              <Form.Item>
                <div>
                  <Checkbox onChange={handleChange} disabled={isLoading}>
                    {t("seekingAssetsToManage")}
                  </Checkbox>
                </div>
              </Form.Item>
            */}
            <Form.Item>
              <div className={styles.loginBtnWrp}>
                <Button className={styles.loginBtn} type="primary" htmlType="submit">
                  {isLoading ? <LoadingOutlined /> : t("createAccount")}
                </Button>
              </div>
            </Form.Item>
            <div className="text-center">
              <p className={styles.controlLabel}>
                {t("userAgreementText")}&nbsp;
                {isLoading ? t("userAgreement") : <a href="#">{t("userAgreement")}</a>}
              </p>
            </div>
            <br />
            <div className="text-center">
              <p className={styles.controlLabel}>
                {t("alreadyHaveAnAccount")}? {isLoading ? t("logIn") : <Link to="/login">{t("logIn")}</Link>}
              </p>
            </div>
          </div>
        </Form>
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrp}>
          <h1 className={styles.title}>
            {t("with")}
            <span className={styles.titleWrp}>
              <span className={styles.titleText}>{t("fundSeeder")}</span>
            </span>
            {t("youWillGet")}.
          </h1>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Increase} alt={t("freeAnalyticsPlatform")} />
              </div>
              <h2 className={styles.h2}>{t("freeAnalyticsPlatform")}</h2>
              <p>{t("easyToUse")}</p>
            </div>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Analytics} alt={t("tradeStatistics")} />
              </div>
              <h2 className={styles.h2}>{t("tradeStatistics")}</h2>
              <p>{t("profitFactor")}.</p>
            </div>
          </div>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Wallet} alt={t("monetization")} />
              </div>
              <h2 className={styles.h2}>{t("monetization")}</h2>
              <p>{t("opportunities")}</p>
            </div>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Ceo} alt={t("accountTracking")} />
              </div>
              <h2 className={styles.h2}>{t("accountTracking")}</h2>
              <p>{t("abilityToEstablishRecord")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Login = () => {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Login";
  }, []);

  useLayoutEffect(() => {
    const emailField = getCookie("email");
    if (emailField) {
      setEmail(emailField);
    }
  }, []);

  const embeddedLogin = () => {
    auth0Client.client.login(
      {
        realm: auth0Params.realm,
        username: email!,
        password: encryptPassword(password!),
      },
      (err, authResult: authResult) => {
        if (err) {
          notification.error({
            message: err.description,
            description: "Could not login",
          });
          localStorage.removeItem("email");
          setIsLoading(false);
        }
        if (authResult) {
          localStorage.setItem("token", authResult.accessToken);
          localStorage.removeItem("email");
          const parsedToken = JSON.parse(atob(authResult.accessToken.split(".")[1]));
          const userId: string = parsedToken["https://uat-new.fundseeder.com/user_id"];
          dispatch({
            type: main.SET_USER_DATA,
            payload: { accessToken: authResult.accessToken, userId },
          });
          apiClient.userInfoGet().finally(() => {
            setIsLoading(false);
            navigate("/dashboard");
          });
        }
      }
    );
  };

  /*
    const handleRememberChange = (event: any) => {
      setRemember(event.target.checked);
    };
  */

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onSubmitHandler = (): void => {
    if (!isLoading) {
      setIsLoading(true);
      embeddedLogin();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div className={styles.logoText}>
          <Link to="/">
            <img className={styles.logoImg} src={Logo} />
          </Link>
          {t("logIn")}
        </div>
        <Form
          action=""
          onFinish={onSubmitHandler}
          layout="vertical"
          autoComplete="new-password"
          fields={[
            {
              name: "Email",
              value: email,
            },
            {
              name: "Password",
              value: password,
            },
          ]}
        >
          <div className={styles.wrpInputs}>
            <div className={styles.controlItem}>
              <Form.Item
                name="Email"
                label={t("email")}
                rules={[{ type: "email", required: true, message: "Email is required" }]}
              >
                <Input
                  id="email"
                  placeholder={t("email")}
                  onChange={handleEmailChange}
                  disabled={!!searchParams.get("email") || isLoading}
                  className={styles.input}
                  tabIndex={1}
                  value={email}
                  autoComplete="new-password"
                />
              </Form.Item>
            </div>
            <div className={styles.controlItem}>
              <div className={styles.forgotPasswordText}>
                {isLoading ? (
                  <span>{t("forgotPassword")}?</span>
                ) : (
                  <Link to="/forgot-password">{t("forgotPassword")}?</Link>
                )}
              </div>
              <Form.Item
                name="Password"
                label={t("password")}
                rules={[{ required: true, message: "Password is required" }]}
              >
                <Input.Password
                  id="password"
                  placeholder={t("passwordPlaceholder")}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  onChange={handlePasswordChange}
                  className={styles.input}
                  tabIndex={2}
                  disabled={isLoading}
                  value={password}
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            {/*
              <div className={styles.rememberMe}>
                <Checkbox onChange={handleRememberChange}>{t("rememberMe")}</Checkbox>
              </div>
            */}
            <div className={styles.loginBtnWrp}>
              <Button className={styles.loginBtn} type="primary" htmlType="submit" tabIndex={3}>
                {isLoading ? <LoadingOutlined /> : t("logIn")}
              </Button>
            </div>
            <div className="text-center">
              <p className={styles.controlLabel}>
                {t("dontHaveAnAccount")}?{" "}
                {isLoading ? <span>{t("createAccount")}</span> : <Link to="/signup">{t("createAccount")}</Link>}
              </p>
            </div>
          </div>
        </Form>
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrp}>
          <h1 className={styles.title}>
            {t("whatWe")}
            <span className={styles.titleWrp}>
              <span className={styles.titleText}>{t("offer")}</span>
            </span>
            {t("inOurProduct")}
          </h1>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Lamp} alt={t("flexibleIntegration")} />
              </div>
              <h2 className={styles.h2}>{t("flexibleIntegration")}</h2>
              <p>{t("provision")}</p>
            </div>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Gear} alt={t("customization")} />
              </div>
              <h2 className={styles.h2}>{t("customization")}</h2>
              <p>{t("weOfferIntegration")}</p>
            </div>
          </div>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Headphones} alt={t("support")} />
              </div>
              <h2 className={styles.h2}>{t("support")}</h2>
              <p>{t("ourTools")}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
