import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { Header } from "../../components/header";
import { FooterLinks } from "../home/footer/footerLinks";
import styles from "./cms.module.css";

const cx = classNames.bind(styles);

export const UserAgreement = () => {
  const { t } = useTranslation("cms");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "User agreement";
  }, []);

  return (
    <div className={styles.pageBg}>
      <Header />
      <div className={styles.wrapper}>
        <Row>
          <Col xs={24}>
            <p className={styles.cmsContent}>{t("userAgreement")}</p>
            <p className={styles.cmsContent}>{t("accessThePlatform")}</p>
            <p className={styles.cmsContent}>{t("modifications")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              <b>
                <i>{t("termsOfThisAgreement")}</i>
              </b>
            </p>
            <h3 className={styles.cmsHeader}>{t("descriptionOfThePlatform")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("analyzeAndEvaluate")}</p>
            <h3 className={styles.cmsHeader}>{t("recommendations")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("implicitly")}</p>
            <h3 className={styles.cmsHeader}>{t("limitations")}</h3>
            <p className={styles.cmsContent}>{t("ownRisk")}</p>
            <p className={styles.cmsContent}>{t("acknowledge")}</p>
            <p className={styles.cmsContent}>{t("reviewing")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("relatedParties")}</p>
            <h3 className={styles.cmsHeader}>{t("thirdPartyConduct")}</h3>
            <p className={styles.cmsContent}>{t("jurisdiction")}</p>
            <p className={styles.cmsContent}>{t("endeavor")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("connection")}</p>
            <h3 className={styles.cmsHeader}>{t("useInOtherCountries")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("partOfThisAgreement")}
              <a href="/privacy-policy" target="_blank">
                {t("privacyPolicyLink")}
              </a>
              {t("partOfThisAgreementEnd")}
            </p>
            <h3 className={styles.cmsHeader}>{t("electronicSystemAndTechnologicalFailure")}</h3>
            <p className={styles.cmsContent}>{t("encounter")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              <i>{t("delaysOrOtherIssues")}</i>
            </p>
            <h3 className={styles.cmsHeader}>{t("useOfThePlatform")}</h3>
            <p className={styles.cmsContent}>{t("containsInformation")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("otherAspect")}</p>
            <ul>
              <li className={styles.listItem}>{t("infringes")}</li>
              <li className={styles.listItem}>{t("constitutes")}</li>
              <li className={styles.listItem}>{t("defamatory")}</li>
              <li className={styles.listItem}>{t("exploitative")}</li>
              <li className={styles.listItem}>{t("embodies")}</li>
              <li className={styles.listItem}>{t("violates")}</li>
              <li className={styles.listItem}>{t("misleading")}</li>
              <li className={styles.listItem}>{t("claimsAgainstYou")}</li>
            </ul>
            <p className={styles.cmsContent}>{t("youFurtherAgree")}</p>
            <ol className={styles.lastParagraph}>
              <li className={styles.listItem}>{t("reverseEngineer")}</li>
              <li className={styles.listItem}>{t("interfere")}</li>
              <li className={styles.listItem}>{t("applicableTreaties")}</li>
              <li className={styles.listItem}>{t("commercialEnterprise")}</li>
              <li className={styles.listItem}>{t("extractionTool")}</li>
              <li className={styles.listItem}>{t("disproportionately")}</li>
              <li className={styles.listItem}>
                {t("expressWritten")}
                <a href="/dashboard" target="_blank">
                  {t("fundSeederLink")}
                </a>
                {t("expressWrittenLink")}
              </li>
            </ol>
            <h3 className={styles.cmsHeader}>{t("accessMethods")}</h3>
            <p className={styles.cmsContent}>{t("responsibleForMaintaining")}</p>
            <p className={styles.cmsContent}>{t("determineYourAccess")}</p>
            <p className={styles.cmsContent}>
              {t("personalInformation")}
              <a href="/privacy-policy" target="_blank">
                {t("privacyPolicyLink")}
              </a>
            </p>
            <p className={cx("cmsContent", "lastParagraph")}>
              <i>{t("securityOrPrivacy")}</i>
            </p>
            <h3 className={styles.cmsHeader}>{t("unauthorizedUseOfThePlatform")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("investigate")}</p>
            <h3 className={styles.cmsHeader}>{t("denialOfAccess")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("prohibit")}</p>
            <h3 className={styles.cmsHeader}>{t("trademarksAndCopyrights")}</h3>
            <p className={styles.cmsContent}>{t("namesAndLogos")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("copyrightedWork")}
              <a href="mailto:info@fundseeder.com" target="_blank" rel="noopener noreferrer">
                {t("mailTo")}
              </a>
              {t("copyrightedWorkEnd")}
            </p>
            <ul className={styles.lastParagraph}>
              <li className={styles.listItem}>{t("description")}</li>
              <li className={styles.listItem}>{t("material")}</li>
              <li className={styles.listItem}>{t("address")}</li>
              <li className={styles.listItem}>{t("electronic")}</li>
              <li className={styles.listItem}>{t("statement")}</li>
              <li className={styles.listItem}>{t("penalty")}</li>
            </ul>
            <h3 className={styles.cmsHeader}>{t("submissionsAndDataTransmission")}</h3>
            <p className={styles.cmsContent}>{t("intellectualProperty")}</p>
            <p className={styles.cmsContent}>{t("responsibility")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("potentialLiability")}</p>
            <h3 className={styles.cmsHeader}>{t("thirdPartyAdvertisementsAndLinksToThirdPartySites")}</h3>
            <p className={styles.cmsContent}>{t("discretionProvideLinks")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              <i>{t("partySite")}</i>
            </p>
            <h3 className={styles.cmsHeader}>{t("disclaimer")}</h3>
            <p className={styles.cmsContent}>
              <i>{t("warranties")}</i>
            </p>
            <p className={styles.cmsContent}>
              <i>{t("foregoing")}</i>
            </p>
            <p className={cx("cmsContent", "lastParagraph")}>
              <i>{t("totalLiability")}</i>
            </p>
            <h3 className={styles.cmsHeader}>{t("indemnificationAndRelease")}</h3>
            <p className={styles.cmsContent}>{t("harmless")}</p>
            <p className={styles.cmsContent}>{t("maximumExtent")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("termination")}</p>
            <h3 className={styles.cmsHeader}>{t("disputeResolution")}</h3>
            <p className={styles.cmsContent}>{t("controversies")}</p>
            <p className={styles.cmsContent}>{t("notwithstanding")}</p>
            <p className={styles.cmsContent}>{t("awareAndAcknowledge")}</p>
            <p className={styles.cmsContent}>
              <i>{t("claims")}</i>
            </p>
            <p className={styles.cmsContent}>{t("disputeResolutionSection")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("certainLaws")}
              <a href="mailto:info@fundseeder.com" target="_blank" rel="noopener noreferrer">
                {t("mailTo")}
              </a>
              {t("certainLawsEnd")}
              <a href="tel:+15619534456">+1 561 953 4456</a>
            </p>
            <h3 className={styles.cmsHeader}>{t("terminationHeader")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("discontinue")}</p>
            <h3 className={styles.cmsHeader}>{t("emailCommunications")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("businessNotifications")}</p>
            <h3 className={styles.cmsHeader}>{t("miscellaneous")}</h3>
            <p className={styles.cmsContent}>{t("correspondence")}</p>
            <p className={styles.cmsContent}>{t("relationship")}</p>
            <p className={styles.cmsContent}>{t("circumstances")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("warrant")}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.fixedLinks}>
        <ul className={styles.fixedLinksList}>
          <Link to="/privacy-policy"><li className={styles.fixedLinksListItem}>{t("privacyPolicyLink")}</li></Link>
          <Link to="/security"><li className={styles.fixedLinksListItem}>{t("security")}</li></Link>
          <li className={cx("fixedLinksListItem", "currentPage")}>{t("userAgreementLink")}</li>
        </ul>
      </div>
      <FooterLinks />
    </div>
  );
};
