import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NoMatch = () => {
  const { t } = useTranslation("noMatch");
  return (
    <div>
      <h2>{t("nothingToSeeHere")}!</h2>
      <p>
        <Link to="/">{t("goToTheHomePage")}</Link>
      </p>
    </div>
  );
};
