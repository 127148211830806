export const decimalAdjust = (type: any, value: any, exp: any) => {
  if (Math.abs(value) < 0.01) {
    return "<0.01";
  }
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    // @ts-ignore
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  // @ts-ignore
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
};

// Decimal ceil
// @ts-ignore
export const ceil10 = (Math.ceil10 = function (value: any, exp: any) {
  return decimalAdjust("ceil", value, exp);
});
