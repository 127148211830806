import React, { useEffect } from "react";
import { Row, Col } from "antd";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/header";
import { FooterLinks } from "../home/footer/footerLinks";
import styles from "./cms.module.css";

const cx = classNames.bind(styles);

export const Security = () => {
  const { t } = useTranslation("cms");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Security";
  }, []);

  return (
    <div className={styles.pageBg}>
      <Header />
      <div className={styles.wrapper}>
        <Row>
          <Col xs={24}>
            <h3 className={styles.cmsHeader}>{t("informationStores")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("fundSeederTechnologies")}</p>
            <h3 className={styles.cmsHeader}>{t("accountInformation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("internalApplication")}</p>
            <h3 className={styles.cmsHeader}>{t("loginDetails")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("maintainLoginDetails")}</p>
            <h3 className={styles.cmsHeader}>{t("trackMyAccount")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("publiclyTraded")}</p>
            <h3 className={styles.cmsHeader}>{t("handleSecurity")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("majorConcern")}</p>
            <ul className={styles.lastParagraph}>
              <li className={styles.listItem}>{t("centralBastionHosts")}</li>
              <li className={styles.listItem}>{t("multiFactorAuthentication")}</li>
              <li className={styles.listItem}>{t("resilientAndRedundantInfrastructure")}</li>
              <li className={styles.listItem}>{t("dataEncryption")}</li>
              <li className={styles.listItem}>{t("centralizedSecurity")}</li>
            </ul>
            <h3 className={styles.cmsHeader}>{t("dataAggregation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("examinedAndReviewed")}
              <a href="https://ithandbook.ffiec.gov/media/153119/06-28-12_-_external_cloud_computing_-_public_statement.pdf">
                {t("here")}
              </a>
            </p>
            <h3 className={styles.cmsHeader}>{t("accessOrManipulate")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("loginCredentials")}
              <a href="/user-agreement">{t("userAgreementLink")}</a>
              {t("loginCredentialsLink")}
            </p>
            <h3 className={styles.cmsHeader}>{t("responsibleForLosses")}</h3>
            <p className={styles.cmsContent}>{t("allTheSameRisks")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("technology")}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.fixedLinks}>
        <ul className={styles.fixedLinksList}>
          <Link to="/privacy-policy"><li className={styles.fixedLinksListItem}>{t("privacyPolicyLink")}</li></Link>
          <li className={cx("fixedLinksListItem", "currentPage")}>{t("security")}</li>
          <Link to="/user-agreement"><li className={styles.fixedLinksListItem}>{t("userAgreementLink")}</li></Link>
        </ul>
      </div>
      <FooterLinks />
    </div>
  );
};
