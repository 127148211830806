import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Input, Select } from "antd";
import { useAppSelector } from "../../hooks";
import { countries } from "../../pages/reducer";
import { educationOptions } from "../../pages/myAccount/userInformation";
import { form } from "../../pages/myAccount/personalInfo";
import { country } from "../../pages/myAccount/personalInfo";
import styles from "./itemInformUser.module.css";

type Props = {
  title: string;
  setForm: any;
  isActive: boolean;
  name: string;
  value: string | number;
  classNames?: {
    box?: string;
  };
  countries: countries[];
  educationOptions: educationOptions[];
  disabled: boolean;
  country: country;
};

export const ItemInformUser: React.FC<Props> = ({
  title,
  isActive,
  setForm,
  name,
  value,
  classNames,
  countries,
  educationOptions,
  disabled,
  country,
}) => {
  const { t } = useTranslation("myAccount");
  const userProfile = useAppSelector((state) => state.main.userProfile);

  const convertedTitle: string = title.charAt(0).toUpperCase() + title.slice(1);
  const splitTitleWords: RegExpMatchArray | null = convertedTitle.match(/[A-Z][a-z]+/g);

  const onChangeHandler = (name: string) => (event: any) => {
    let countryData: any;
    if (name === "country") {
      const selectedCountry = countries.find((country: any) => country.name === event);
      countryData = selectedCountry;
    }
    setForm((prevValues: form) => {
      if (name === "country") {
        return {
          ...prevValues,
          [name]: {
            name: countryData.name,
            alpha_3: countryData.alpha_3,
          },
        };
      } else if (name === "education") {
        return {
          ...prevValues,
          [name]: event,
        };
      } else {
        return {
          ...prevValues,
          [name]: event.target?.value,
        };
      }
    });
  };

  return (
    <div className={cn(styles.box, classNames && classNames.box)}>
      <p className={styles.title}>{t(`${splitTitleWords?.join("")}`)}</p>
      {isActive ? (
        <>
          {title === "country" || title === "education" ? (
            <Select
              id={title}
              style={{ width: "250px", marginBottom: "-4px" }}
              size="large"
              defaultValue={title === "country" ? userProfile?.country?.name : userProfile?.education}
              onChange={onChangeHandler(name)}
              options={
                title === "country"
                  ? countries.map((country: any) => {
                      return { label: country.name, value: country.name };
                    })
                  : educationOptions
              }
              disabled={disabled}
              className={styles.value}
            ></Select>
          ) : (
            <Input
              className={cn(styles.value, styles.inputValue)}
              onChange={onChangeHandler(name)}
              defaultValue={value}
              placeholder={t(`${splitTitleWords?.join("")}`)}
              disabled={disabled}
              value={value}
            />
          )}
        </>
      ) : (
        <p className={styles.value} data-testid="userInformation">
          {title === "country" ? country?.name : value}
        </p>
      )}
    </div>
  );
};
