import { AnyAction } from "redux";
import { main } from "../constants/actionTypes";

export interface countries {
  alpha_2: string;
  alpha_3: string;
  name: string;
}

interface country {
  name: string;
}

export interface userProfile {
  firstName: string;
  lastName: string;
  education: string;
  tradingYears: string | number;
  city: string;
  country: country;
}

export interface tradingPlatforms {
  active: boolean;
  full_name: string;
  id: number;
  name: string;
  protocol: any;
  wizard_order: number;
}

export interface performance {
  data: string;
  performance: number;
}

export interface userAccount {
  extid: string;
  fs_nick: string;
  id: number;
  inception_date: Date | number | null;
  manual_inception_date: Date | number | null;
  platform: { full_name: string; id: number };
  performance: performance;
  status: { status: string };
  user_id: number;
  valid_from: string;
  valid_to: string;
  ccy: string;
  last_update: string;
}

export interface leader {
  fs_nick: string;
  fs_score: number;
  rank: number;
  account_id?: any;
}

export interface fsIndices {
  performance: number;
  id: number;
  index_description: string;
  index_display_name: string;
  index_name: string;
  leaders: leader[];
}

export interface value {
  date: string;
  fs_index_id: number;
  performance: number;
}

export interface fsIndex {
  id?: number;
  value: value[];
}

interface initialState {
  accessToken: string;
  countries: countries[];
  currencies: any;
  fsIndex: fsIndex[];
  fsIndices: fsIndices[];
  leaderboard: any[];
  monthlyReturnDate: string;
  monthlyReturnValue: number;
  oneYearFsIndex: fsIndex[];
  selectedLeaderboard: string;
  tradingAccountId: number;
  tradingAccount: any;
  tradingPlatforms: tradingPlatforms[];
  userAccounts: userAccount[];
  userId: string;
  userProfile: userProfile;
}

const initialState: initialState = {
  accessToken: "",
  countries: [],
  currencies: "",
  fsIndex: [],
  fsIndices: [],
  leaderboard: [],
  monthlyReturnDate: "2013-10-31",
  monthlyReturnValue: 0,
  oneYearFsIndex: [],
  selectedLeaderboard: "leaderboard",
  tradingAccountId: 0,
  tradingAccount: {},
  tradingPlatforms: [],
  userAccounts: [],
  userId: "",
  userProfile: {
    firstName: "",
    lastName: "",
    education: "",
    tradingYears: "",
    city: "",
    country: {
      name: "",
    },
  },
};

const mainReducer = (state: initialState = initialState, action: AnyAction): initialState => {
  const { type, payload } = action;

  switch (type) {
    case main.SET_USER_DATA:
      return {
        ...state,
        accessToken: payload.accessToken,
        userId: payload.userId,
      };

    case main.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          firstName: payload.first_name,
          lastName: payload.last_name,
          education: payload.education,
          tradingYears: payload.trading_years,
          city: payload.city,
          country: payload.country,
        },
        userId: payload.id,
      };

    case main.SET_COUNTRIES:
      return {
        ...state,
        countries: Array.isArray(payload) ? payload : state.countries,
      };

    case main.SET_TRADING_PLATFORMS:
      return {
        ...state,
        tradingPlatforms: Array.isArray(payload) ? payload : state.tradingPlatforms,
      };
    case main.SET_USERS_ACCOUNTS:
      return {
        ...state,
        userAccounts: Array.isArray(payload) ? payload : state.userAccounts,
      };
    case main.SET_TRADING_ACCOUNT:
      return {
        ...state,
        tradingAccount: payload,
      };
    case main.SET_MONTHLY_RETURN:
      return {
        ...state,
        monthlyReturnDate: payload.monthlyReturnDate,
        monthlyReturnValue: payload.monthlyReturnValue,
      };
    case main.SET_TRADING_ACCOUNT_ID:
      return {
        ...state,
        tradingAccountId: payload,
      };
    case main.SET_INDICES:
      return {
        ...state,
        fsIndices: Array.isArray(payload) ? payload : state.fsIndices,
      };
    case main.SET_CURRENCIES:
      return {
        ...state,
        currencies: payload,
      };
    case main.SET_SELECTED_LEADERBOARD:
      return {
        ...state,
        selectedLeaderboard: payload,
      };
    case main.SET_LEADERBOARD:
      return {
        ...state,
        leaderboard: payload,
      };
    case main.SET_FS_INDEX:
      if (state.fsIndex.find((fsIndexItem) => fsIndexItem?.id === payload?.value?.all_data?.fs_index_id)) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        fsIndex: Array.isArray(payload?.value?.all_data)
          ? [
              ...state.fsIndex,
              {
                id: payload.id,
                value: payload.value.all_data,
              },
            ]
          : state.fsIndex,
        oneYearFsIndex: Array.isArray(payload?.value?.year_data)
          ? [
              ...state.oneYearFsIndex,
              {
                id: payload.id,
                value: payload.value.year_data,
              },
            ]
          : state.oneYearFsIndex,
      };
    default:
      return state;
  }
};

export default mainReducer;
