import { notification } from "antd";

export const showTokenErrorMsg = (err: any, navigateToLogin: any, tokenMsg: any) => {
  notification.error({
    message: tokenMsg,
    description: "Token has expired",
  });
  navigateToLogin();
};

export const showServerErrorMsg = (err: any, errorMsg: any) => {
  notification.error({
    message: errorMsg,
    description: err.response?.data?.msg
      ? Array.isArray(err.response?.data?.msg)
        ? err.response.data.msg.join(" ")
        : err.response?.data?.msg
      : err.message,
  });
};
