import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import { composeWithDevTools } from "redux-devtools-extension";
import homeReducer from "../pages/home/reducer";
import mainReducer from "../pages/reducer";
import tradingAccountReducer from "../pages/tradingAccount/reducer";

const rootReducer = combineReducers({
  home: homeReducer,
  main: mainReducer,
  tradingAccount: tradingAccountReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type === "MAIN_SET_USER_DATA") {
      return {
        ...action,
        accessToken: null,
      };
    }
    return action;
  },
  stateTransformer: state => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      accessToken: "token",
      dailyFsReturn: null,
      dailyReturn: null,
      date: null,
      filteredDate: null,
      filteredFsReturn: null,
      filteredPerformance: null,
      filteredReturnValue: null,
      monthData: null,
      performance: null,
      returnValue: null,
    };

    return transformedState;
  },
});

const composeEnhancers = composeWithDevTools({});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer));

export type AppDispatch = typeof store.dispatch;

export default rootReducer;
