import React, { FC } from "react";
import {Row, Col} from "antd";
import { useTranslation } from "react-i18next";
import investors1 from "./assets/investors1.png";
import investors2 from "./assets/investors2.png";
import investors3 from "./assets/investors3.png";
import investors4 from "./assets/investors4.png";
import { Title } from "../../../components/title/index";
import styles from "./investors.module.css";

export const Investors: FC = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.investorsWrapper} id="investors">
      <Row align="middle" justify="center" className={styles.investorsRow}>
        <Col lg={24}>
          <Title primary subTitle={t("investors")} className={styles.investorsHeader}>
            {t("our")}
          </Title>
          <Row justify="center">
            <Col lg={6} xs={24} className={styles.investorsImgWrapper}>
              <img src={investors1} alt={t("investors")} className={styles.investorsImg}/>
            </Col>
            <Col lg={6} xs={24} className={styles.investorsImgWrapper}>
              <img src={investors2} alt={t("investors")} className={styles.investorsImg}/>
            </Col>
            <Col lg={6} xs={24} className={styles.investorsImgWrapper}>
              <img src={investors3} alt={t("investors")} className={styles.investorsImg}/>
            </Col>
            <Col lg={6} xs={24} className={styles.investorsImgWrapper}>
              <img src={investors4} alt={t("investors")} className={styles.investorsImg}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
