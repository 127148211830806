import React, { FC } from "react";
import { Form, Input, Space, Checkbox, Row, Col } from "antd";
import styles from "../addTradingAccount/addTradingAccount.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface formItem {
  formLabel?: string;
  lastItem?: boolean;
  name?: any;
  type?: string;
}

export const FormItem: FC<formItem> = ({ formLabel, lastItem, name, type }) => {
  return (
    <Form.Item label={formLabel} className={cx("formItemWrapper", { lastItem: lastItem })}>
      <Space>
        <Form.Item name={name} style={{ marginBottom: 0 }} className={styles.formItem}>
          {type === "checkbox" && <Checkbox />}
          {type === "checkboxGroup" && (
            <Checkbox.Group>
              <Row>
                <Col span={8}>
                  <Checkbox value="A" style={{ lineHeight: "32px" }}>
                    A
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Checkbox value="B" style={{ lineHeight: "32px" }}>
                    B
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          )}
          {type === "input" && <Input className={styles.input} size="middle" style={{ width: "360px" }} />}
          {type === "textarea" && (
            <Input className={styles.input} size="middle" style={{ width: "360px" }} type="textarea" />
          )}
        </Form.Item>
      </Space>
    </Form.Item>
  );
};
