import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import cn from "classnames";
import styles from "./support.module.css";
import "../../../App.css";

const { Panel } = Collapse;

export const Accordion = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <Collapse
        defaultActiveKey={["1"]}
        ghost
        // expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? <MinusCircleOutlined className="minusIcon" /> : <PlusCircleOutlined className="plusIcon" />
        }
        className={cn(styles.supportCollapse, "supportCollapse")}
      >
        <Panel header={t("specificBrokerageFirm")} key="1" className={cn(styles.accordionHeader, "accordionHeader")}>
          <p className={styles.accordionText}>{t("continuallyWorking")}:</p>
          <br />
          <p className={styles.accordionText}>
            <strong>{t("futuresOnly")} — </strong>
            {t("tradeFuturesOnly")}.
          </p>
          <br />
          <p className={styles.accordionText}>
            <strong>{t("equitiesOnly")} — </strong>
            {t("tradeEquitiesOnly")}.
          </p>
          <br />
          <p className={styles.accordionText}>
            <strong>{t("oTCForexOnly")} — </strong>
            {t("tradeOTCForexOnly")}.
          </p>
          <br />
          <p className={styles.accordionText}>
            <strong>{t("combinationOfFuturesEquitiesAndOTCFX")} — </strong>
            {t("tradeAnyCombinationOfFuturesEquitiesAndOTCForex")}.
          </p>
          <br />
          <p className={styles.accordionText}>
            <strong>{t("unverifiedXLSUpload")} — </strong>
            {t("brokerIsNotListed")}.
          </p>
        </Panel>
        <Panel header={t("tradingResultsBeIncluded")} key="2" className={cn(styles.accordionHeader, "accordionHeader")}>
          <p className={styles.accordionText}>{t("availabilityOfPastData")}.</p>
        </Panel>
        <Panel header={t("tradingDays")} key="3" className={cn(styles.accordionHeader, "accordionHeader")}>
          <p className={styles.accordionText}>{t("fSScoreRequires")}.</p>
          <br />
          <p className={styles.accordionText}>{t("displayedOnTheAccountPage")}.</p>
        </Panel>
        <Panel header={t("manuallyUpload")} key="4" className={cn(styles.accordionHeader, "accordionHeader")}>
          <p className={styles.accordionText}>{t("brokerageIsNotAvailable")}.</p>
          <br />
          <p className={styles.accordionText}>{t("unverifiedData")}.</p>
        </Panel>
        <Panel header={t("shareMyFundSeeder")} key="5" className={cn(styles.accordionHeader, "accordionHeader")}>
          <p className={styles.accordionText}>{t("safeguardTheConfidentiality")}.</p>
          <br />
          <p className={styles.accordionText}>{t("linkingTradingHistory")}.</p>
        </Panel>
      </Collapse>
    </>
  );
};
