/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * DEV FST API
 * API for backend built in flask 2.0.2
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface CountriesCountryNameGet200Response
 */
export interface CountriesCountryNameGet200Response {
  /**
   * The alpha2 of the country
   * @type {string}
   * @memberof CountriesCountryNameGet200Response
   */
  alpha2?: string;
  /**
   * The alpha3 of the country
   * @type {string}
   * @memberof CountriesCountryNameGet200Response
   */
  alpha3?: string;
  /**
   * The country id
   * @type {number}
   * @memberof CountriesCountryNameGet200Response
   */
  id?: number;
  /**
   * The name of the country
   * @type {string}
   * @memberof CountriesCountryNameGet200Response
   */
  name?: string;
}
/**
 *
 * @export
 * @interface CurrenciesGet200Response
 */
export interface CurrenciesGet200Response {
  /**
   * Currency code
   * @type {object}
   * @memberof CountriesCountryNameGet200Response
   */
  [key: string]: number;
}
/**
 *
 * @export
 * @interface EducationGet200Response
 */
export interface EducationGet200Response {
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  associates?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  bachelors?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  highschool?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  masters?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  mba?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  med_doctor?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  other?: string;
  /**
   *
   * @type {string}
   * @memberof EducationGet200Response
   */
  phd?: string;
}
/**
 *
 * @export
 * @interface FsIndexIndexIdGet200Response
 */
export interface FsIndexIndexIdGet200Response {
  /**
   * All of the performance data
   * @type {object}
   * @memberof FsIndexIndexIdGet200Response
   */
  all_data?: object;
  /**
   * Only the last year
   * @type {object}
   * @memberof FsIndexIndexIdGet200Response
   */
  year_data?: object;
}
/**
 *
 * @export
 * @interface FsIndicesGet200Response
 */
export interface FsIndicesGet200Response {
  /**
   * Id
   * @type {number}
   * @memberof FsIndicesGet200Response
   */
  id?: number;
  /**
   * Description
   * @type {string}
   * @memberof FsIndicesGet200Response
   */
  index_description?: string;
  /**
   * Display name
   * @type {string}
   * @memberof FsIndicesGet200Response
   */
  index_display_name?: string;
  /**
   * Name
   * @type {string}
   * @memberof FsIndicesGet200Response
   */
  index_name?: string;
  /**
   * Leaders list
   * @type {array}
   * @memberof FsIndicesGet200Response
   */
  leaders?: any[];
  /**
   * Performance value
   * @type {number}
   * @memberof FsIndicesGet200Response
   */
  performance?: number;
}
/**
 *
 * @export
 * @interface ResetPost200Response
 */
export interface ResetPost200Response {
  /**
   * The expiration time for reseting the password
   * @type {Datetime}
   * @memberof ResetPost200Response
   */
  expires_at?: Datetime;
  /**
   * Password reset success
   * @type {string}
   * @memberof ResetPost200Response
   */
  msg?: string;
  /**
   * The time of the reset password request
   * @type {Datetime}
   * @memberof ResetPost200Response
   */
  requested_at?: Datetime;
  /**
   * Response status
   * @type {number}
   * @memberof ResetPost200Response
   */
  status?: number;
}
/**
 *
 * @export
 * @interface ResetPostRequest
 */
export interface ResetPostRequest {
  /**
   * The email of the user
   * @type {string}
   * @memberof ResetPostRequest
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ResetTokenPost200Response
 */
export interface ResetTokenPost200Response {
  /**
   * The email of user
   * @type {string}
   * @memberof ResetTokenPost200Response
   */
  email?: string;
  /**
   * Password reset success
   * @type {string}
   * @memberof ResetTokenPost200Response
   */
  msg?: string;
  /**
   * Response status
   * @type {string}
   * @memberof ResetTokenPost200Response
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ResetTokenPostRequest
 */
export interface ResetTokenPostRequest {
  /**
   * new encrypted password of user
   * @type {string}
   * @memberof ResetTokenPostRequest
   */
  reset_password?: string;
}
/**
 *
 * @export
 * @interface TradingAccountsAccountIdDelete200Response
 */
export interface TradingAccountsAccountIdDelete200Response {
  /**
   * The ID the broker gives us
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  extid?: string;
  /**
   * The ID Fundseder shows to the user for this account
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  fs_nick?: string;
  /**
   * The ID of the account
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  inception_date?: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  manual_inception_date?: string;
  /**
   * The FK to trading platforms
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  platform_id?: number;
  /**
   *
   * @type {TradingAccountsAccountIdDelete200ResponseStatus}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  status?: TradingAccountsAccountIdDelete200ResponseStatus;
  /**
   * The user ID
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  user_id?: number;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  valid_from?: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response
   */
  valid_to?: string;
}
/**
 *
 * @export
 * @interface TradingAccountsAccountIdDelete200Response1
 */
export interface TradingAccountsAccountIdDelete200Response1 {
  /**
   * The ID the broker gives us
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  extid?: string;
  /**
   * The ID Fundseder shows to the user for this account
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  fs_nick?: string;
  /**
   * The id of the account
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  inception_date?: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  manual_inception_date?: string;
  /**
   * The FK to trading platforms
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  platform_id?: number;
  /**
   *
   * @type {TradingAccountsAccountIdDelete200ResponseStatus}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  status?: TradingAccountsAccountIdDelete200ResponseStatus;
  /**
   * The user ID
   * @type {number}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  user_id?: number;
  /**
   * account valid from
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  valid_from?: string;
  /**
   * account valid to
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200Response1
   */
  valid_to?: string;
}
/**
 *
 * @export
 * @interface TradingAccountsAccountIdDelete200ResponseStatus
 */
export interface TradingAccountsAccountIdDelete200ResponseStatus {
  /**
   * Status of account
   * @type {string}
   * @memberof TradingAccountsAccountIdDelete200ResponseStatus
   */
  status?: TradingAccountsAccountIdDelete200ResponseStatusStatusEnum;
}

export const TradingAccountsAccountIdDelete200ResponseStatusStatusEnum = {
  Newbie: "newbie",
  Active: "active",
  Inactive: "inactive",
  Deleted: "deleted",
} as const;

export type TradingAccountsAccountIdDelete200ResponseStatusStatusEnum =
  typeof TradingAccountsAccountIdDelete200ResponseStatusStatusEnum[keyof typeof TradingAccountsAccountIdDelete200ResponseStatusStatusEnum];

/**
 *
 * @export
 * @interface TradingAccountsAccountIdDeleteRequest
 */
export interface TradingAccountsAccountIdDeleteRequest {
  /**
   * The ID the broker gives us
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  extid: string;
  /**
   * The ID Fundseder shows to the user for this account
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  fs_nick: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  inception_date?: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  manual_inception_date?: string;
  /**
   *
   * @type {TradingAccountsAccountIdDeleteRequestPlatform}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  platform: TradingAccountsAccountIdDeleteRequestPlatform;
  /**
   *
   * @type {TradingAccountsAccountIdDeleteRequestStatus}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  status?: TradingAccountsAccountIdDeleteRequestStatus;
  /**
   *
   * @type {number}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  user_id: number;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  valid_from?: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequest
   */
  valid_to?: string;
}
/**
 *
 * @export
 * @interface TradingAccountsAccountIdDeleteRequestPlatform
 */
export interface TradingAccountsAccountIdDeleteRequestPlatform {
  /**
   * The id of the trading platform
   * @type {number}
   * @memberof TradingAccountsAccountIdDeleteRequestPlatform
   */
  id?: number;
}
/**
 *
 * @export
 * @interface TradingAccountsAccountIdDeleteRequestStatus
 */
export interface TradingAccountsAccountIdDeleteRequestStatus {
  /**
   * Status of account
   * @type {string}
   * @memberof TradingAccountsAccountIdDeleteRequestStatus
   */
  status?: TradingAccountsAccountIdDeleteRequestStatusStatusEnum;
}

export const TradingAccountsAccountIdDeleteRequestStatusStatusEnum = {
  Newbie: "newbie",
  Active: "active",
  Inactive: "inactive",
  Deleted: "deleted",
} as const;

export type TradingAccountsAccountIdDeleteRequestStatusStatusEnum =
  typeof TradingAccountsAccountIdDeleteRequestStatusStatusEnum[keyof typeof TradingAccountsAccountIdDeleteRequestStatusStatusEnum];

/**
 *
 * @export
 * @interface UnverifiedPostRequest
 */
export interface UnverifiedPostRequest {
  /**
   * The account id
   * @type {number}
   * @memberof UnverifiedPostRequest
   */
  account_id: number;
  /**
   * entry currency
   * @type {number}
   * @memberof UnverifiedPostRequest
   */
  currency_id?: number;
  /**
   * (Enum) returns/ nlvs
   * @type {string}
   * @memberof UnverifiedPostRequest
   */
  data_type: string;
  /**
   * string generated from bytes (use u64 encode) - the uploaded file in compressed format
   * @type {string}
   * @memberof UnverifiedPostRequest
   */
  file: string;
  /**
   * (Enum) daily/ monthly
   * @type {string}
   * @memberof UnverifiedPostRequest
   */
  frequency?: string;
}
/**
 *
 * @export
 * @interface UserCommunicationGet200Response
 */
export interface UserCommunicationGet200Response {
  /**
   * If account info of the user is available
   * @type {boolean}
   * @memberof UserCommunicationGet200Response
   */
  account_info?: boolean;
  /**
   * The id of the user communication preferences
   * @type {number}
   * @memberof UserCommunicationGet200Response
   */
  id?: number;
  /**
   * If user opts for marketing
   * @type {boolean}
   * @memberof UserCommunicationGet200Response
   */
  marketing?: boolean;
  /**
   * If user opts for receiving newsletter
   * @type {boolean}
   * @memberof UserCommunicationGet200Response
   */
  news?: boolean;
}
/**
 *
 * @export
 * @interface UserCommunicationGetRequest
 */
export interface UserCommunicationGetRequest {
  /**
   * If account info of the user is available
   * @type {boolean}
   * @memberof UserCommunicationGetRequest
   */
  account_info: boolean;
  /**
   * If user opts for marketing
   * @type {boolean}
   * @memberof UserCommunicationGetRequest
   */
  marketing: boolean;
  /**
   * If user opts for receiving newsletter
   * @type {boolean}
   * @memberof UserCommunicationGetRequest
   */
  news: boolean;
}
/**
 *
 * @export
 * @interface UserCommunicationGetRequest1
 */
export interface UserCommunicationGetRequest1 {
  /**
   * If account info of the user is available
   * @type {boolean}
   * @memberof UserCommunicationGetRequest1
   */
  account_info?: boolean;
  /**
   * If user opts in/out of marketing
   * @type {boolean}
   * @memberof UserCommunicationGetRequest1
   */
  marketing?: boolean;
  /**
   * If user opts in/out of newsletter
   * @type {boolean}
   * @memberof UserCommunicationGetRequest1
   */
  news?: boolean;
}
/**
 *
 * @export
 * @interface UserDelete200Response
 */
export interface UserDelete200Response {
  /**
   * The email of the user
   * @type {string}
   * @memberof UserDelete200Response
   */
  email?: string;
  /**
   * The id of the user
   * @type {number}
   * @memberof UserDelete200Response
   */
  id?: number;
}
/**
 *
 * @export
 * @interface UserDelete200Response1
 */
export interface UserDelete200Response1 {
  /**
   * The id of the user
   * @type {number}
   * @memberof UserDelete200Response1
   */
  id?: number;
  /**
   * User valid from
   * @type {string}
   * @memberof UserDelete200Response1
   */
  valid_from?: string;
  /**
   * User valid to
   * @type {string}
   * @memberof UserDelete200Response1
   */
  valid_to?: string;
}
/**
 *
 * @export
 * @interface UserInfoGet200Response
 */
export interface UserInfoGet200Response {
  /**
   * The id of the user profile
   * @type {number}
   * @memberof UserInfoGet200Response
   */
  id?: number;
}
/**
 *
 * @export
 * @interface UserInfoGetRequest
 */
export interface UserInfoGetRequest {
  /**
   * City of user
   * @type {string}
   * @memberof UserInfoGetRequest
   */
  city: string;
  /**
   *
   * @type {UserInfoGetRequestCountry}
   * @memberof UserInfoGetRequest
   */
  country: UserInfoGetRequestCountry;
  /**
   * Education of user
   * @type {string}
   * @memberof UserInfoGetRequest
   */
  education: UserInfoGetRequestEducationEnum;
  /**
   * Name of user
   * @type {string}
   * @memberof UserInfoGetRequest
   */
  first_name: string;
  /**
   * Surname of user
   * @type {string}
   * @memberof UserInfoGetRequest
   */
  last_name: string;
  /**
   * trading experience in years
   * @type {number}
   * @memberof UserInfoGetRequest
   */
  trading_years: number;
}

export const UserInfoGetRequestEducationEnum = {
  Highschool: "highschool",
  Associates: "associates",
  Bachelors: "bachelors",
  Masters: "masters",
  Mba: "mba",
  MedDoctor: "med_doctor",
  Phd: "phd",
  Other: "other",
} as const;

export type UserInfoGetRequestEducationEnum =
  typeof UserInfoGetRequestEducationEnum[keyof typeof UserInfoGetRequestEducationEnum];

/**
 *
 * @export
 * @interface UserInfoGetRequest1
 */
export interface UserInfoGetRequest1 {
  /**
   * City of user
   * @type {string}
   * @memberof UserInfoGetRequest1
   */
  city?: string;
  /**
   *
   * @type {UserInfoGetRequestCountry}
   * @memberof UserInfoGetRequest1
   */
  country?: UserInfoGetRequestCountry;
  /**
   * Education of user
   * @type {string}
   * @memberof UserInfoGetRequest1
   */
  education?: UserInfoGetRequest1EducationEnum;
  /**
   * trading experience in years
   * @type {number}
   * @memberof UserInfoGetRequest1
   */
  trading_years?: number;
}

export const UserInfoGetRequest1EducationEnum = {
  Highschool: "highschool",
  Associates: "associates",
  Bachelors: "bachelors",
  Masters: "masters",
  Mba: "mba",
  LawDoctor: "law_doctor",
  MedDoctor: "med_doctor",
  Phd: "phd",
  Engineer: "engineer",
  Other: "other",
} as const;

export type UserInfoGetRequest1EducationEnum =
  typeof UserInfoGetRequest1EducationEnum[keyof typeof UserInfoGetRequest1EducationEnum];

/**
 *
 * @export
 * @interface UserInfoGetRequestCountry
 */
export interface UserInfoGetRequestCountry {
  /**
   * dictionary with key alpha_3 and value the country\'s alpha_3
   * @type {string}
   * @memberof UserInfoGetRequestCountry
   */
  alpha_3?: string;
  /**
   * dictionary with key name and value the country\'s name
   * @type {string}
   * @memberof UserInfoGetRequestCountry
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UserTradingAccountsGet200Response
 */
export interface UserTradingAccountsGet200Response {
  /**
   * The ID the broker gives us
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  extid?: string;
  /**
   * The ID Fundseder shows to the user for this account
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  fs_nick?: string;
  /**
   * The trading account id
   * @type {number}
   * @memberof UserTradingAccountsGet200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  inception_date?: string;
  /**
   *
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  manual_inception_date?: string;
  /**
   * The FK to trading platforms
   * @type {number}
   * @memberof UserTradingAccountsGet200Response
   */
  platform_id?: number;
  /**
   * The user ID
   * @type {number}
   * @memberof UserTradingAccountsGet200Response
   */
  user_id?: number;
  /**
   *
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  valid_from?: string;
  /**
   *
   * @type {string}
   * @memberof UserTradingAccountsGet200Response
   */
  valid_to?: string;
}
/**
 *
 * @export
 * @interface UserTradingAccountsGetRequest
 */
export interface UserTradingAccountsGetRequest {
  /**
   * The ID the broker gives us
   * @type {string}
   * @memberof UserTradingAccountsGetRequest
   */
  extid: string;
  /**
   * The ID Fundseder gives to this account
   * @type {string}
   * @memberof UserTradingAccountsGetRequest
   */
  fs_nick: string;
  /**
   *
   * @type {TradingAccountsAccountIdDeleteRequestPlatform}
   * @memberof UserTradingAccountsGetRequest
   */
  platform: TradingAccountsAccountIdDeleteRequestPlatform;
  /**
   *
   * @type {UserTradingAccountsGetRequestStatus}
   * @memberof UserTradingAccountsGetRequest
   */
  status: UserTradingAccountsGetRequestStatus;
}
/**
 *
 * @export
 * @interface UserTradingAccountsGetRequestStatus
 */
export interface UserTradingAccountsGetRequestStatus {
  /**
   * The status of the account
   * @type {string}
   * @memberof UserTradingAccountsGetRequestStatus
   */
  status?: string;
}
/**
 *
 * @export
 * @interface UsersGet201Response
 */
export interface UsersGet201Response {
  /**
   * The id of the new user
   * @type {number}
   * @memberof UsersGet201Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UsersGet201Response
   */
  msg?: string;
  /**
   * Response status
   * @type {number}
   * @memberof UsersGet201Response
   */
  status?: number;
}
/**
 *
 * @export
 * @interface UsersGetRequest
 */
export interface UsersGetRequest {
  /**
   *
   * @type {UserInfoGetRequestCountry}
   * @memberof UsersGetRequest
   */
  country?: UserInfoGetRequestCountry;
  /**
   * email of user
   * @type {string}
   * @memberof UsersGetRequest
   */
  email: string;
  /**
   * encrypted password of user
   * @type {string}
   * @memberof UsersGetRequest
   */
  encrypted_password: string;
  /**
   * Name of user
   * @type {string}
   * @memberof UsersGetRequest
   */
  name?: string;
  /**
   * Surname of user
   * @type {string}
   * @memberof UsersGetRequest
   */
  surname?: string;
}

/**
 * RestfulApi - axios parameter creator
 * @export
 */
export const RestfulApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Retrieve account metrics for a specifc account from the redshift db
     * @param {number} [accountId] The account id for which we require the metrics, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountMetricsAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/account_metrics/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a country from the redshift db
     * @param {string} [countryName] The name of the country, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryNameGet: async (countryName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/countries/{country_name}/`.replace(
        `{${"country_name"}}`,
        encodeURIComponent(String(countryName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of countries from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/countries/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve the cumulative performance chart data
     * @param {number} [accountId] The account id for which we require the cumulative performance chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cumulativeReturnAccountIdGet: async (
      accountId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cumulative_return/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve the currency mapping from the db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currenciesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/currencies/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dailyVarAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/daily_var/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dailyVolatilityAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/daily_volatility/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List education options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    educationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/education/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retreive an index from db
     * @param {number} [indexId] The index id, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fsIndexIndexIdGet: async (indexId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/fs_index/{index_id}/`.replace(`{${"index_id"}}`, encodeURIComponent(String(indexId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retreive a list of indices from db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fsIndicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/fs_indices/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * contains mysql user get from mysql DB<br/>
     * @summary Create new password request, api sends email with tokenized url
     * @param {ResetPostRequest} resetPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPost: async (resetPostRequest: ResetPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'resetPostRequest' is not null or undefined
      assertParamExists("resetPost", "resetPostRequest", resetPostRequest);
      const localVarPath = `/reset/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(resetPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * contains mysql user get and update with mysql DB<br/>
     * @summary Save new password in redshift and mysql db
     * @param {ResetTokenPostRequest} resetTokenPostRequest
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetTokenPost: async (
      resetTokenPostRequest: ResetTokenPostRequest,
      token?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetTokenPostRequest' is not null or undefined
      assertParamExists("resetTokenPost", "resetTokenPostRequest", resetTokenPostRequest);
      const localVarPath = `/reset/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(token)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(resetTokenPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    returnsListAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/returns_list/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rolling6mVolAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/rolling_6m_vol/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a trading account from redshift db
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdDelete: async (
      accountId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/trading_accounts/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a trading account from the redshift db
     * @param {number} [accountId] The account id of the trading account, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/trading_accounts/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update trading account in redshift db
     * @param {TradingAccountsAccountIdDeleteRequest} tradingAccountsAccountIdDeleteRequest
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdPut: async (
      tradingAccountsAccountIdDeleteRequest: TradingAccountsAccountIdDeleteRequest,
      accountId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tradingAccountsAccountIdDeleteRequest' is not null or undefined
      assertParamExists(
        "tradingAccountsAccountIdPut",
        "tradingAccountsAccountIdDeleteRequest",
        tradingAccountsAccountIdDeleteRequest
      );
      const localVarPath = `/trading_accounts/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tradingAccountsAccountIdDeleteRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a list of trading platforms (aka brokers) from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingPlatformsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/trading_platforms/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve the underwater chart data
     * @param {number} [accountId] The account id for which we require the underwater chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    underwaterAccountIdGet: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/underwater/{account_id}/`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Uploads a new unverified file to the db, and to s3
     * @param {UnverifiedPostRequest} unverifiedPostRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unverifiedPost: async (
      unverifiedPostRequest: UnverifiedPostRequest,
      userId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'unverifiedPostRequest' is not null or undefined
      assertParamExists("unverifiedPost", "unverifiedPostRequest", unverifiedPostRequest);
      const localVarPath = `/unverified/`.replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(unverifiedPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retreive user-communication preferences from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user_communication/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Partially update user communication preferences in redshift db
     * @param {UserCommunicationGetRequest1} userCommunicationGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationPatch: async (
      userCommunicationGetRequest1: UserCommunicationGetRequest1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userCommunicationGetRequest1' is not null or undefined
      assertParamExists("userCommunicationPatch", "userCommunicationGetRequest1", userCommunicationGetRequest1);
      const localVarPath = `/user_communication/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userCommunicationGetRequest1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create/Update user_communication preferences in redshift db
     * @param {UserCommunicationGetRequest} userCommunicationGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationPut: async (
      userCommunicationGetRequest: UserCommunicationGetRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userCommunicationGetRequest' is not null or undefined
      assertParamExists("userCommunicationPut", "userCommunicationGetRequest", userCommunicationGetRequest);
      const localVarPath = `/user_communication/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userCommunicationGetRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a user from redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDelete: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/`.replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retreive a user from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retreive user-info from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user_info/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Partially update a new user_info profile in redshift db
     * @param {UserInfoGetRequest1} userInfoGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoPatch: async (
      userInfoGetRequest1: UserInfoGetRequest1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userInfoGetRequest1' is not null or undefined
      assertParamExists("userInfoPatch", "userInfoGetRequest1", userInfoGetRequest1);
      const localVarPath = `/user_info/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userInfoGetRequest1, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create/Update a new user_info profile in redshift db
     * @param {UserInfoGetRequest} userInfoGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoPut: async (
      userInfoGetRequest: UserInfoGetRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userInfoGetRequest' is not null or undefined
      assertParamExists("userInfoPut", "userInfoGetRequest", userInfoGetRequest);
      const localVarPath = `/user_info/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userInfoGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve a user\'s trading accounts from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userTradingAccountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user_trading_accounts/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new trading account for user in redshift db
     * @param {UserTradingAccountsGetRequest} userTradingAccountsGetRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userTradingAccountsPost: async (
      userTradingAccountsGetRequest: UserTradingAccountsGetRequest,
      userId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userTradingAccountsGetRequest' is not null or undefined
      assertParamExists("userTradingAccountsPost", "userTradingAccountsGetRequest", userTradingAccountsGetRequest);
      const localVarPath = `/user_trading_accounts/`.replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userTradingAccountsGetRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary A list of active users in redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/`.replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * contains mysql user get and post with mysql DB<br/>
     * @summary Create a new user in redshift db
     * @param {UsersGetRequest} usersGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPost: async (usersGetRequest: UsersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'usersGetRequest' is not null or undefined
      assertParamExists("usersPost", "usersGetRequest", usersGetRequest);
      const localVarPath = `/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(usersGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RestfulApi - functional programming interface
 * @export
 */
export const RestfulApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RestfulApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieve account metrics for a specifc account from the redshift db
     * @param {number} [accountId] The account id for which we require the metrics, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountMetricsAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountMetricsAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a country from the redshift db
     * @param {string} [countryName] The name of the country, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryNameGet(
      countryName?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountriesCountryNameGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryNameGet(countryName, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of countries from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountriesCountryNameGet200Response[]>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve the cumulative performance chart data
     * @param {number} [accountId] The account id for which we require the cumulative performance chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cumulativeReturnAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cumulativeReturnAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve the currency mapping from the db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async currenciesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrenciesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dailyVarAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dailyVarAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dailyVolatilityAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dailyVolatilityAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List education options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async educationGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.educationGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retreive an index from db
     * @param {number} [indexId] The index id, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fsIndexIndexIdGet(
      indexId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FsIndexIndexIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fsIndexIndexIdGet(indexId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retreive a list of indices from db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fsIndicesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FsIndicesGet200Response[]>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fsIndicesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * contains mysql user get from mysql DB<br/>
     * @summary Create new password request, api sends email with tokenized url
     * @param {ResetPostRequest} resetPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPost(
      resetPostRequest: ResetPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPost(resetPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * contains mysql user get and update with mysql DB<br/>
     * @summary Save new password in redshift and mysql db
     * @param {ResetTokenPostRequest} resetTokenPostRequest
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetTokenPost(
      resetTokenPostRequest: ResetTokenPostRequest,
      token?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetTokenPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetTokenPost(resetTokenPostRequest, token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async returnsListAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.returnsListAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rolling6mVolAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rolling6mVolAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a trading account from redshift db
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradingAccountsAccountIdDelete(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountsAccountIdDelete200Response1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAccountsAccountIdDelete(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a trading account from the redshift db
     * @param {number} [accountId] The account id of the trading account, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradingAccountsAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountsAccountIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAccountsAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update trading account in redshift db
     * @param {TradingAccountsAccountIdDeleteRequest} tradingAccountsAccountIdDeleteRequest
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradingAccountsAccountIdPut(
      tradingAccountsAccountIdDeleteRequest: TradingAccountsAccountIdDeleteRequest,
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAccountsAccountIdPut(
        tradingAccountsAccountIdDeleteRequest,
        accountId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a list of trading platforms (aka brokers) from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradingPlatformsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradingPlatformsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve the underwater chart data
     * @param {number} [accountId] The account id for which we require the underwater chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async underwaterAccountIdGet(
      accountId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.underwaterAccountIdGet(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Uploads a new unverified file to the db, and to s3
     * @param {UnverifiedPostRequest} unverifiedPostRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unverifiedPost(
      unverifiedPostRequest: UnverifiedPostRequest,
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unverifiedPost(unverifiedPostRequest, userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retreive user-communication preferences from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCommunicationGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCommunicationGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCommunicationGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Partially update user communication preferences in redshift db
     * @param {UserCommunicationGetRequest1} userCommunicationGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCommunicationPatch(
      userCommunicationGetRequest1: UserCommunicationGetRequest1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCommunicationPatch(
        userCommunicationGetRequest1,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create/Update user_communication preferences in redshift db
     * @param {UserCommunicationGetRequest} userCommunicationGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCommunicationPut(
      userCommunicationGetRequest: UserCommunicationGetRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCommunicationPut(
        userCommunicationGetRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a user from redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userDelete(
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retreive a user from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retreive user-info from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInfoGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Partially update a new user_info profile in redshift db
     * @param {UserInfoGetRequest1} userInfoGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInfoPatch(
      userInfoGetRequest1: UserInfoGetRequest1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoPatch(userInfoGetRequest1, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create/Update a new user_info profile in redshift db
     * @param {UserInfoGetRequest} userInfoGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInfoPut(
      userInfoGetRequest: UserInfoGetRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoPut(userInfoGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieve a user\'s trading accounts from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userTradingAccountsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTradingAccountsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userTradingAccountsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a new trading account for user in redshift db
     * @param {UserTradingAccountsGetRequest} userTradingAccountsGetRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userTradingAccountsPost(
      userTradingAccountsGetRequest: UserTradingAccountsGetRequest,
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userTradingAccountsPost(
        userTradingAccountsGetRequest,
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary A list of active users in redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(
      userId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * contains mysql user get and post with mysql DB<br/>
     * @summary Create a new user in redshift db
     * @param {UsersGetRequest} usersGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPost(
      usersGetRequest: UsersGetRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersGet201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(usersGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RestfulApi - factory interface
 * @export
 */
export const RestfulApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RestfulApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieve account metrics for a specifc account from the redshift db
     * @param {number} [accountId] The account id for which we require the metrics, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountMetricsAccountIdGet(accountId?: number, options?: any): AxiosPromise<any> {
      return localVarFp.accountMetricsAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a country from the redshift db
     * @param {string} [countryName] The name of the country, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryNameGet(countryName?: string, options?: any): AxiosPromise<CountriesCountryNameGet200Response> {
      return localVarFp.countriesCountryNameGet(countryName, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of countries from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet(options?: any): AxiosPromise<CountriesCountryNameGet200Response[]> {
      return localVarFp.countriesGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve the cumulative performance chart data
     * @param {number} [accountId] The account id for which we require the cumulative performance chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cumulativeReturnAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.cumulativeReturnAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve the currency mapping from the db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currenciesGet(options?: any): AxiosPromise<CurrenciesGet200Response> {
      return localVarFp.currenciesGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dailyVarAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.dailyVarAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dailyVolatilityAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.dailyVolatilityAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List education options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    educationGet(options?: any): AxiosPromise<EducationGet200Response> {
      return localVarFp.educationGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retreive an index from db
     * @param {number} [indexId] The index id, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fsIndexIndexIdGet(indexId?: number, options?: any): AxiosPromise<FsIndexIndexIdGet200Response> {
      return localVarFp.fsIndexIndexIdGet(indexId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retreive a list of indices from db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fsIndicesGet(options?: any): AxiosPromise<FsIndicesGet200Response[]> {
      return localVarFp.fsIndicesGet(options).then((request) => request(axios, basePath));
    },
    /**
     * contains mysql user get from mysql DB<br/>
     * @summary Create new password request, api sends email with tokenized url
     * @param {ResetPostRequest} resetPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPost(resetPostRequest: ResetPostRequest, options?: any): AxiosPromise<ResetPost200Response> {
      return localVarFp.resetPost(resetPostRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * contains mysql user get and update with mysql DB<br/>
     * @summary Save new password in redshift and mysql db
     * @param {ResetTokenPostRequest} resetTokenPostRequest
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetTokenPost(
      resetTokenPostRequest: ResetTokenPostRequest,
      token?: string,
      options?: any
    ): AxiosPromise<ResetTokenPost200Response> {
      return localVarFp
        .resetTokenPost(resetTokenPostRequest, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    returnsListAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.returnsListAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of returns from the materialed view in redshift db
     * @param {number} [accountId] The account id for which we require the returns, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rolling6mVolAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.rolling6mVolAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a trading account from redshift db
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdDelete(
      accountId?: number,
      options?: any
    ): AxiosPromise<TradingAccountsAccountIdDelete200Response1> {
      return localVarFp.tradingAccountsAccountIdDelete(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a trading account from the redshift db
     * @param {number} [accountId] The account id of the trading account, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdGet(
      accountId?: number,
      options?: any
    ): AxiosPromise<TradingAccountsAccountIdDelete200Response> {
      return localVarFp.tradingAccountsAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update trading account in redshift db
     * @param {TradingAccountsAccountIdDeleteRequest} tradingAccountsAccountIdDeleteRequest
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingAccountsAccountIdPut(
      tradingAccountsAccountIdDeleteRequest: TradingAccountsAccountIdDeleteRequest,
      accountId?: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .tradingAccountsAccountIdPut(tradingAccountsAccountIdDeleteRequest, accountId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a list of trading platforms (aka brokers) from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradingPlatformsGet(options?: any): AxiosPromise<object> {
      return localVarFp.tradingPlatformsGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve the underwater chart data
     * @param {number} [accountId] The account id for which we require the underwater chart, required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    underwaterAccountIdGet(accountId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.underwaterAccountIdGet(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Uploads a new unverified file to the db, and to s3
     * @param {UnverifiedPostRequest} unverifiedPostRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unverifiedPost(unverifiedPostRequest: UnverifiedPostRequest, userId?: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .unverifiedPost(unverifiedPostRequest, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retreive user-communication preferences from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationGet(options?: any): AxiosPromise<UserCommunicationGet200Response> {
      return localVarFp.userCommunicationGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Partially update user communication preferences in redshift db
     * @param {UserCommunicationGetRequest1} userCommunicationGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationPatch(
      userCommunicationGetRequest1: UserCommunicationGetRequest1,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .userCommunicationPatch(userCommunicationGetRequest1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create/Update user_communication preferences in redshift db
     * @param {UserCommunicationGetRequest} userCommunicationGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCommunicationPut(userCommunicationGetRequest: UserCommunicationGetRequest, options?: any): AxiosPromise<any> {
      return localVarFp
        .userCommunicationPut(userCommunicationGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a user from redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDelete(userId?: number, options?: any): AxiosPromise<UserDelete200Response1> {
      return localVarFp.userDelete(userId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retreive a user from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet(options?: any): AxiosPromise<UserDelete200Response> {
      return localVarFp.userGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retreive user-info from redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoGet(options?: any): AxiosPromise<UserInfoGet200Response> {
      return localVarFp.userInfoGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Partially update a new user_info profile in redshift db
     * @param {UserInfoGetRequest1} userInfoGetRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoPatch(userInfoGetRequest1: UserInfoGetRequest1, options?: any): AxiosPromise<any> {
      return localVarFp.userInfoPatch(userInfoGetRequest1, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create/Update a new user_info profile in redshift db
     * @param {UserInfoGetRequest} userInfoGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInfoPut(userInfoGetRequest: UserInfoGetRequest, options?: any): AxiosPromise<any> {
      return localVarFp.userInfoPut(userInfoGetRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve a user\'s trading accounts from the redshift db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userTradingAccountsGet(options?: any): AxiosPromise<UserTradingAccountsGet200Response> {
      return localVarFp.userTradingAccountsGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new trading account for user in redshift db
     * @param {UserTradingAccountsGetRequest} userTradingAccountsGetRequest
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userTradingAccountsPost(
      userTradingAccountsGetRequest: UserTradingAccountsGetRequest,
      userId?: number,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .userTradingAccountsPost(userTradingAccountsGetRequest, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary A list of active users in redshift db
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(userId?: number, options?: any): AxiosPromise<object> {
      return localVarFp.usersGet(userId, options).then((request) => request(axios, basePath));
    },
    /**
     * contains mysql user get and post with mysql DB<br/>
     * @summary Create a new user in redshift db
     * @param {UsersGetRequest} usersGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPost(usersGetRequest: UsersGetRequest, options?: any): AxiosPromise<UsersGet201Response> {
      return localVarFp.usersPost(usersGetRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * RestfulApi - object-oriented interface
 * @export
 * @class RestfulApi
 * @extends {BaseAPI}
 */
export class RestfulApi extends BaseAPI {
  /**
   *
   * @summary Retrieve account metrics for a specifc account from the redshift db
   * @param {number} [accountId] The account id for which we require the metrics, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public accountMetricsAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .accountMetricsAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a country from the redshift db
   * @param {string} [countryName] The name of the country, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public countriesCountryNameGet(countryName?: string, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .countriesCountryNameGet(countryName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of countries from the redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public countriesGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .countriesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve the cumulative performance chart data
   * @param {number} [accountId] The account id for which we require the cumulative performance chart, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public cumulativeReturnAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .cumulativeReturnAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve the currency mapping from the db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public currenciesGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .currenciesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of returns from the materialed view in redshift db
   * @param {number} [accountId] The account id for which we require the returns, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public dailyVarAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .dailyVarAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of returns from the materialed view in redshift db
   * @param {number} [accountId] The account id for which we require the returns, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public dailyVolatilityAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .dailyVolatilityAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List education options
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public educationGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .educationGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retreive an index from db
   * @param {number} [indexId] The index id, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public fsIndexIndexIdGet(indexId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .fsIndexIndexIdGet(indexId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retreive a list of indices from db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public fsIndicesGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .fsIndicesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * contains mysql user get from mysql DB<br/>
   * @summary Create new password request, api sends email with tokenized url
   * @param {ResetPostRequest} resetPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public resetPost(resetPostRequest: ResetPostRequest, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .resetPost(resetPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * contains mysql user get and update with mysql DB<br/>
   * @summary Save new password in redshift and mysql db
   * @param {ResetTokenPostRequest} resetTokenPostRequest
   * @param {string} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public resetTokenPost(resetTokenPostRequest: ResetTokenPostRequest, token?: string, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .resetTokenPost(resetTokenPostRequest, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of returns from the materialed view in redshift db
   * @param {number} [accountId] The account id for which we require the returns, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public returnsListAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .returnsListAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of returns from the materialed view in redshift db
   * @param {number} [accountId] The account id for which we require the returns, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public rolling6mVolAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .rolling6mVolAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a trading account from redshift db
   * @param {number} [accountId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public tradingAccountsAccountIdDelete(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .tradingAccountsAccountIdDelete(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a trading account from the redshift db
   * @param {number} [accountId] The account id of the trading account, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public tradingAccountsAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .tradingAccountsAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update trading account in redshift db
   * @param {TradingAccountsAccountIdDeleteRequest} tradingAccountsAccountIdDeleteRequest
   * @param {number} [accountId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public tradingAccountsAccountIdPut(
    tradingAccountsAccountIdDeleteRequest: TradingAccountsAccountIdDeleteRequest,
    accountId?: number,
    options?: AxiosRequestConfig
  ) {
    return RestfulApiFp(this.configuration)
      .tradingAccountsAccountIdPut(tradingAccountsAccountIdDeleteRequest, accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a list of trading platforms (aka brokers) from the redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public tradingPlatformsGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .tradingPlatformsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve the underwater chart data
   * @param {number} [accountId] The account id for which we require the underwater chart, required
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public underwaterAccountIdGet(accountId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .underwaterAccountIdGet(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Uploads a new unverified file to the db, and to s3
   * @param {UnverifiedPostRequest} unverifiedPostRequest
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public unverifiedPost(unverifiedPostRequest: UnverifiedPostRequest, userId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .unverifiedPost(unverifiedPostRequest, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retreive user-communication preferences from redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userCommunicationGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userCommunicationGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Partially update user communication preferences in redshift db
   * @param {UserCommunicationGetRequest1} userCommunicationGetRequest1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userCommunicationPatch(
    userCommunicationGetRequest1: UserCommunicationGetRequest1,
    options?: AxiosRequestConfig
  ) {
    return RestfulApiFp(this.configuration)
      .userCommunicationPatch(userCommunicationGetRequest1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create/Update user_communication preferences in redshift db
   * @param {UserCommunicationGetRequest} userCommunicationGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userCommunicationPut(userCommunicationGetRequest: UserCommunicationGetRequest, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userCommunicationPut(userCommunicationGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a user from redshift db
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userDelete(userId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userDelete(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retreive a user from redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retreive user-info from redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userInfoGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userInfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Partially update a new user_info profile in redshift db
   * @param {UserInfoGetRequest1} userInfoGetRequest1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userInfoPatch(userInfoGetRequest1: UserInfoGetRequest1, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userInfoPatch(userInfoGetRequest1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create/Update a new user_info profile in redshift db
   * @param {UserInfoGetRequest} userInfoGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userInfoPut(userInfoGetRequest: UserInfoGetRequest, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userInfoPut(userInfoGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve a user\'s trading accounts from the redshift db
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userTradingAccountsGet(options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .userTradingAccountsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new trading account for user in redshift db
   * @param {UserTradingAccountsGetRequest} userTradingAccountsGetRequest
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public userTradingAccountsPost(
    userTradingAccountsGetRequest: UserTradingAccountsGetRequest,
    userId?: number,
    options?: AxiosRequestConfig
  ) {
    return RestfulApiFp(this.configuration)
      .userTradingAccountsPost(userTradingAccountsGetRequest, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary A list of active users in redshift db
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public usersGet(userId?: number, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .usersGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * contains mysql user get and post with mysql DB<br/>
   * @summary Create a new user in redshift db
   * @param {UsersGetRequest} usersGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestfulApi
   */
  public usersPost(usersGetRequest: UsersGetRequest, options?: AxiosRequestConfig) {
    return RestfulApiFp(this.configuration)
      .usersPost(usersGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
