import React, { FC } from "react";
import { Tick, ChartData, ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { fsIndex } from "../reducer";
import styles from "./dashboard.module.css";

interface chart {
  accountsTable?: boolean;
  currentFsIndex: fsIndex;
}

export const LeaderboardChart: FC<chart> = ({ currentFsIndex, accountsTable }) => {
  const filteredDate = currentFsIndex?.value?.map((currentFsIndexItem) => currentFsIndexItem?.date);
  const filteredPerformance = currentFsIndex?.value?.map((oneDayPerformance) => oneDayPerformance?.performance * 100);
  const performanceLabels = currentFsIndex?.value?.map((oneDayPerformance) =>
    String(oneDayPerformance?.performance * 100)
  );
  console.log("T1", currentFsIndex);
  const labels = filteredDate;
  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        type: "linear" as "category",
        labels: performanceLabels,
        title: {
          display: false,
        },
        display: false,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        offset: false,
        max: Math.max(...filteredPerformance),
        min: Math.min(...filteredPerformance),
      },
      x: {
        type: "timeseries",
        min: labels[0],
        max: labels[labels.length - 1],
        ticks: {
          callback: function (value: string | number, index: number, ticks: Tick[]) {
            if (labels.length && index === 0) {
              return format(new Date(labels[0]), "MMM yyyy");
            }
            if (labels.length && index === ticks.length - 1) {
              return format(new Date(labels[labels.length - 1]), "MMM yyyy");
            }
          },
          maxTicksLimit: 2,
          maxRotation: 0,
          color: "#869fae",
          display: !Boolean(accountsTable),
        },
        time: {
          unit: "day",
        },
        offset: false,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data: ChartData = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "Performance",
        borderColor: "#72c6ed",
        borderWidth: 1,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointHoverBorderWidth: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgba(114, 198, 237, 0)",
        data: filteredPerformance,
        fill: {
          target: "start",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      <Chart options={options} type="line" data={data} />
    </div>
  );
};
