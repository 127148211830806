import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "antd";
import { useAppSelector } from "../../hooks";
import { FormItem } from "./formItem";
import { UploadModal } from "../../utils/uploadModal";
import { Loading } from "../../components/loading";
import { apiClient } from "../../services/base";
import styles from "./addTradingAccount.module.css";

interface unverifiedAccountResult {
  connection: string;
  brokerName: string;
}

export const UnverifiedAccountResult: FC<unverifiedAccountResult> = ({ connection, brokerName }) => {
  const { t } = useTranslation("addTradingAccount");

  const tradingAccountId = useAppSelector((state) => state.main.tradingAccountId);

  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [currenciesLoading, setCurrenciesLoading] = useState<boolean>(true);

  const closeModal = () => {
    setUploadModal(false);
  };

  const openModal = () => {
    setUploadModal(true);
  };

  useEffect(() => {
    apiClient.currenciesGet().finally(() => {
      setCurrenciesLoading(false);
    });
  }, []);

  return (
    <>
      {currenciesLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.resultForm}
            onFinish={openModal}
          >
            <FormItem
              formLabel={t("chosenMethod")}
              name="ChooseConnectionMethod"
              connection={connection}
              needToRenderTooltip={false}
              changed={true}
            />
            <FormItem
              formLabel={t("brokerName")}
              name="BrokerName"
              connection={brokerName}
              needToRenderTooltip={false}
              changed={true}
            />
            <div className={styles.resultTextWrapper}>
              <h1 className={styles.resultTitle}>{t("yourUnverifiedAccount")}</h1>
              <p className={styles.resultText}>{t("accountCreation")}.</p>
            </div>
            <Form.Item className={styles.formItem} wrapperCol={{ offset: 8, span: 16 }}>
              <Button htmlType="submit" className={styles.confirmButton} style={{ width: "360px" }}>
                {t("uploadUnverifiedData")}
              </Button>
            </Form.Item>
          </Form>
          <UploadModal
            title={t("uploadAccountData")}
            visible={uploadModal}
            onCancel={closeModal}
            cancelText={t("cancel")}
            nlvHeader={t("nLVDaily")}
            performanceHeader={t("performanceDaily")}
            downloadTemplate={t("downloadTemplateLabel")}
            uploadingText={t("uploading")}
            startUpload={t("startUpload")}
            uploadSuccessfully={t("uploadSuccessfully")}
            uploadFailed={t("uploadFailed")}
            selectedAccountId={tradingAccountId}
          />
        </>
      )}
    </>
  );
};
