import React from "react";
import {Row, Col} from "antd";
import { HelpCenter } from "./helpCenter";
import { Accordion } from "./accordion";
import styles from "./support.module.css";

export const Support = () => {
  return (
    <Row justify="center" className={styles.supportRow}>
      <Col lg={8} xs={24} className={styles.helpCenterWrapper}>
        <HelpCenter />
      </Col>
      <Col lg={14} xs={24} offset={2} className={styles.accordionWrapper}>
        <Accordion />
      </Col>
    </Row>
  )
};
