import React, { useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components/loading";
import { Layout } from "./components/layout";
import { NoMatch } from "./components/noMatch";
import { Home } from "./pages/home";
import Profile from "./pages/profile";
import { Signup, Login } from "./pages/signup";
import { ResetPassword } from "./pages/signup/resetPassword";
import { ForgotPassword } from "./pages/signup/forgotPassword";
import { MyAccount } from "./pages/myAccount";
import { AddTradingAccount } from "./pages/addTradingAccount";
import { DownloadTemplate } from "./pages/addTradingAccount/downloadTemplate";
import { EditTradingAccount } from "./pages/editTradingAccount";
import { TradingAccount } from "./pages/tradingAccount";
import { Dashboard } from "./pages/dashboard";
import { Leaderboard } from "./pages/leaderboard";
import { Search } from "./pages/search";
import { PrivacyPolicy } from "./pages/cms/privacyPolicy";
import { Security } from "./pages/cms/security";
import { UserAgreement } from "./pages/cms/userAgreement";
import { ChangePassword } from "./pages/signup/changePassword";
import { Admin } from "./pages/admin";
import { main } from "./constants/actionTypes";
import { useAppDispatch } from "./hooks";
import { ProtectedRoute } from "./utils/protectedRoute";
import "./i18n";
import "./App.css";

notification.config({
  maxCount: 1,
});

const App = () => {
  const dispatch = useAppDispatch();
  const { error } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user_info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          const parsedToken = JSON.parse(atob(token.split(".")[1]));
          const userId: string = parsedToken["https://uat-new.fundseeder.com/user_id"];
          dispatch({
            type: main.SET_USER_DATA,
            payload: { accessToken: token, userId },
          });
        })
        .catch(() => {
          window.localStorage.removeItem("token");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:id" element={<ResetPassword />} />
        <Route path="signup" element={<Signup />} />
        <Route path="trading-account" element={<TradingAccount />} />
        <Route path="profile" element={<ProtectedRoute component={<Profile />} />} />
        <Route path="my-account" element={<ProtectedRoute component={<MyAccount />} />} />
        <Route path="edit/my-account" element={<ProtectedRoute component={<MyAccount />} />} />
        <Route path="add-trading-account" element={<ProtectedRoute component={<AddTradingAccount />} />} />
        <Route path="download-template" element={<ProtectedRoute component={<DownloadTemplate />} />} />
        <Route path="edit/trading-account/:id" element={<ProtectedRoute component={<EditTradingAccount />} />} />
        <Route path="trading-account/:id" element={<ProtectedRoute component={<TradingAccount />} />} />
        <Route path="dashboard" element={<ProtectedRoute component={<Dashboard />} />} />
        <Route path="leaderboard" element={<ProtectedRoute component={<Leaderboard />} />} />
        <Route path="search" element={<ProtectedRoute component={<Search />} />} />
        <Route path="credentials" element={<ProtectedRoute component={<ChangePassword />} />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="security" element={<Security />} />
        <Route path="user-agreement" element={<UserAgreement />} />
        <Route path="admin" element={<Admin />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

export default App;
