import React, { useState } from "react";
import { Input, Row, Col, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { formatDistance } from "date-fns";
import { Header } from "../../components/header";
import styles from "./admin.module.css";

const data = [
  {
    name: "Kya Nicholson",
    email: "testuser1@gmail.com",
    logins: 100,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
  },
  {
    name: "Finnlay Sanchez",
    email: "testuser12@gmail.com",
    logins: 100,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 4),
  },
  {
    name: "Sana Hibbert",
    email: "testuser3@gmail.com",
    logins: 8,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 22),
  },
  {
    name: "Haya Watt",
    email: "testuser4@gmail.com",
    logins: 253,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 8),
  },
  {
    name: "Raees Butt",
    email: "testuser5@gmail.com",
    logins: 412,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 45),
  },
  {
    name: "Kanye Ellison",
    email: "testuser6@gmail.com",
    logins: 3,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 2),
  },
  {
    name: "Bryson Mackie",
    email: "testuser7@gmail.com",
    logins: 1,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 20),
  },
  {
    name: "Marisa Cardenas",
    email: "testuser8@gmail.com",
    logins: 19,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
  },
  {
    name: "Habib Mccormick",
    email: "testuser9@gmail.com",
    logins: 157,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 13),
  },
  {
    name: "Ruby-Rose Chase",
    email: "testuser10@gmail.com",
    logins: 43,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 2),
  },
  {
    name: "Caspar Lowery",
    email: "testuser11@gmail.com",
    logins: 243,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 4),
  },
  {
    name: "Camden Beard",
    email: "testuser12@gmail.com",
    logins: 8,
    latestLogin: new Date(new Date().getTime() - 1000 * 60 * 56),
  },
];

export const Admin = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Logins",
      dataIndex: "logins",
      key: "latestLogin",
      sorter: (a: any, b: any) => {
        return a.logins - b.logins;
      },
    },
    {
      title: "Latest login",
      key: "latestLogin",
      dataIndex: "latestLogin",
      render: (context: any) => {
        return formatDistance(context, new Date(), { addSuffix: true });
      },
      sorter: (a: any, b: any) => {
        return new Date(a.latestLogin).getTime() - new Date(b.latestLogin).getTime();
      },
    },
  ];

  const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setFilteredData(
      data.filter(
        (prevDataItem) =>
          prevDataItem.name.toLowerCase().includes(event.target.value) ||
          prevDataItem.email.toLowerCase().includes(event.target.value)
      )
    );
  };

  return (
    <>
      <Header />
      <Row className={styles.searchRow}>
        <Col xs={12} offset={2}>
          <Input
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValue}
            prefix={<SearchOutlined /> || <span></span>}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={20} offset={2}>
          <Table columns={columns} dataSource={filteredData} />
        </Col>
      </Row>
    </>
  );
};
