import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Checkbox, Empty } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Loading } from "../../components/loading";
import { TitleEdit } from "../../components/titleEdite";
import { apiClient } from "../../services/base";
import styles from "./myAccount.module.css";

interface subscriptionOptions {
  account_info: boolean;
  marketing: boolean;
  news: boolean;
}

export const SubscriptionForm: FC = () => {
  const { t } = useTranslation("myAccount");

  const [form, setForm] = useState<subscriptionOptions>({ account_info: false, marketing: false, news: false });
  const [initialForm, setInitialForm] = useState<subscriptionOptions>({
    account_info: false,
    marketing: false,
    news: false,
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editSubscription, setEditSubscription] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .userCommunicationGet({ signal: controller.signal })
      .then((res) => {
        if (res.data?.account_info !== undefined && res.data?.marketing !== undefined && res.data?.news !== undefined) {
          setForm({
            account_info: res.data.account_info,
            marketing: res.data.marketing,
            news: res.data.news,
          });
          setInitialForm({
            account_info: res.data.account_info,
            marketing: res.data.marketing,
            news: res.data.news,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
    return () => controller.abort();
  }, []);

  const handleChange = (name: string) => (event: CheckboxChangeEvent) => {
    setTouched(true);
    setForm((prevForm) => {
      return {
        ...prevForm,
        [name]: event.target.checked,
      };
    });
  };

  const cancelSubscriptionEdit = () => {
    setForm(initialForm);
    setEditSubscription(false);
  };

  const submitForm = () => {
    setIsLoading(true);
    apiClient
      .userCommunicationPut({ ...form })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        toggleSubscriptionEdit();
      });
  };

  const toggleSubscriptionEdit = () => {
    setEditSubscription((prevValue: boolean) => !prevValue);
  };

  return (
    <div className={styles.emailSubscriptionWrapper}>
      <TitleEdit
        onClick={toggleSubscriptionEdit}
        title={t("emailSubscription")}
        linkUrl="/edit/my-account"
        disabled={isLoading || !Object.keys(form).length || process.env.REACT_APP_READ_ONLY === "enabled"}
      />
      {isLoading ? (
        <Loading overContent={false} />
      ) : !isLoading && Object.keys(form).length ? (
        <>
          <Form name="subscribe options" onFinish={submitForm}>
            {editSubscription && (
              <>
                <Button
                  disabled={!editSubscription || !touched}
                  htmlType="submit"
                  type="primary"
                  data-testid="submitSubscriptionForm"
                >
                  {t("submit")}
                </Button>
                &nbsp; &nbsp;
                <Button onClick={cancelSubscriptionEdit} data-testid="cancelSubscriptionForm">
                  {t("cancel")}
                </Button>
              </>
            )}
            {(Object.keys(form) as (keyof subscriptionOptions)[]).map((inputValue) => (
              <Form.Item style={{ marginBottom: "6px" }} key={inputValue}>
                <Checkbox
                  id={inputValue}
                  data-testid={inputValue}
                  style={{ lineHeight: "32px" }}
                  onChange={handleChange(`${inputValue}`)}
                  checked={form[inputValue]}
                  className={styles.subscriptionInput}
                  disabled={!editSubscription}
                >
                  {inputValue === "account_info"
                    ? t("receiveEmails")
                    : inputValue === "marketing"
                    ? t("featuresNotified")
                    : t("receivePromotional")}
                  .
                </Checkbox>
              </Form.Item>
            ))}
          </Form>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};
