import React, { FC } from "react";
import { ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Empty } from "antd";
import "chartjs-adapter-date-fns";
import styles from "./search.module.css";

interface chart {
  performance: string[];
}

export const SearchTableChart: FC<chart> = ({ performance }) => {
  const labels = performance.map((performanceItem) => {
    return Number(performanceItem);
  });

  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        title: {
          display: false,
        },
        display: false,
        position: "left" as const,
        beginAtZero: true,
        max: Math.max(...labels),
        min: 0,
        offset: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        labels: performance,
        ticks: {
          display: false,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
          align: "center",
        },
        offset: false,
        display: false,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "Performance",
        borderColor: "#72c6ed",
        borderWidth: 1.5,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBorderColor: "rgba(114, 198, 237, 0)",
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgba(114, 198, 237, 0)",
        data: performance,
        fill: {
          target: "origin",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      {!performance.length ? <Empty /> : <Chart options={options} type="bar" data={data} />}
    </div>
  );
};
