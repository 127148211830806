import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { useAppSelector } from "../../hooks";
import styles from "./tradingAccount.module.css";

interface modalChart {
  fullYearSelected: boolean;
  selectedDate: string | Date | null;
}

export const ModalChart: FC<modalChart> = ({ fullYearSelected, selectedDate }) => {
  const { t } = useTranslation("tradingAccount");
  const dailyReturn = useAppSelector((state) => state.tradingAccount.dailyReturn);
  const oneMonthData = dailyReturn?.filter((dayData) => {
    if (selectedDate) {
      return (
        format(new Date(dayData?.date), `${fullYearSelected ? "yyyy" : "MM-yyyy"}`) ===
        format(new Date(selectedDate), `${fullYearSelected ? "yyyy" : "MM-yyyy"}`)
      );
    }
  });
  const oneMonthDate = oneMonthData?.map((oneDayData) => oneDayData?.date);
  const oneMonthFsReturn = oneMonthData?.map((oneDayData) => {
    return oneDayData?.fs_return;
  });

  const calculatePerformance = (index: number) => {
    let sum: number = oneMonthFsReturn[0] + 1;
    if (index === 0) {
      return oneMonthFsReturn[0] * 100;
    }

    for (let i = index; i > 0; i--) {
      sum *= oneMonthFsReturn[i] + 1;
    }

    return (sum - 1) * 100;
  };

  const performanceResult = oneMonthFsReturn.map((data: number, index: number) => {
    return calculatePerformance(index);
  });

  const labels = oneMonthDate;
  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any[]) => {
            if (context.length) {
              return format(context[0].parsed.x, "MMM dd yyyy");
            }
            return format(new Date(Date.now()), "MMM dd yyyy");
          },
          label: (context: any) => {
            if (context.dataset.type === "line") {
              return ` ${t("performance")}: ${Number(context.formattedValue).toFixed(2)}%`;
            }
            return "0";
          },
        },
        filter: (tooltipItem: any) => {
          return tooltipItem?.dataset?.type === "line";
        },
        position: "nearest",
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        title: {
          display: true,
          text: ` ${t("performance")}(%)`,
          padding: 2,
          color: "#72c6ed",
        },
        display: true,
        position: "left" as const,
        grid: {
          drawOnChartArea: true,
        },
        offset: true,
        max: Math.max(...performanceResult),
        ticks: {
          color: "#72c6ed",
        },
        stacked: "single",
      },
      x: {
        type: "time",
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          align: "center",
        },
        time: {
          unit: "day",
          round: "day",
        },
        grid: {
          drawTicks: true,
        },
        offset: false,
        title: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `${t("performance")}`,
        borderColor: "#72c6ed",
        borderWidth: 2,
        fill: {
          target: "start",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgb(114, 198, 237)",
        data: performanceResult,
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      <Chart options={options} type="bar" data={data} />
    </div>
  );
};
