import React, { FC, useState } from "react";
import { Button, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/header";
import { encryptPassword } from "../../utils/encryptPassword";
import { apiClient } from "../../services/base";
import { useAppSelector } from "../../hooks";
import styles from "./signup.module.css";

export const ChangePassword: FC = () => {
  const { t } = useTranslation("signUp");
  const navigate = useNavigate();
  const accessToken = useAppSelector((state) => state.main.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");

  const submitPasswordChanges = (values: any) => {
    console.log(newEmail);
    if (!isLoading) {
      setIsLoading(true);
      apiClient
        .resetTokenPost(
          {
            reset_password: encryptPassword(newPassword),
          },
          accessToken
        )
        .finally(() => {
          setIsLoading(false);
        });
      console.log("Success:", values);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleInputChange = (inputName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputName === "password") {
      setNewPassword(event.target.value);
    }
    if (inputName === "passwordConfirmation") {
      setConfirmPassword(event.target.value);
    }
    if (inputName === "email") {
      setNewEmail(event.target.value);
    }
  };

  const backToMyAccount = () => {
    navigate("/my-account");
  };

  return (
    <>
      <Header />
      <div className={styles.changePasswordWrapper}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={submitPasswordChanges}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelWrap={true}
        >
          <div className={styles.controlItem}>
            <Form.Item
              name={["Email"]}
              label={t("email")}
              rules={[{ type: "email", required: true }]}
              className={styles.formInput}
            >
              <Input
                id="email"
                placeholder={t("email")}
                onChange={handleInputChange("email")}
                required
                className={styles.input}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
          <div className={styles.controlItem}>
            <Form.Item
              name={["Password"]}
              label={t("password")}
              rules={[{ required: true }]}
              hasFeedback
              className={styles.formInput}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                id="password"
                placeholder={t("passwordPlaceholder")}
                onChange={handleInputChange("password")}
                required
                className={styles.input}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
          <div className={styles.controlItem}>
            <Form.Item
              name={["Password Confirmation"]}
              label={t("passwordConfirmation")}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("Password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                  },
                }),
              ]}
              dependencies={["Password"]}
              hasFeedback
              className={styles.formInput}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                id="passwordConfirmation"
                placeholder={t("passwordPlaceholder")}
                onChange={handleInputChange("passwordConfirmation")}
                required
                className={styles.input}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
            <Button
              type="default"
              className={styles.changePasswordSubmitButton}
              onClick={backToMyAccount}
              disabled={isLoading}
            >
              {t("backToMyAccount")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={newPassword !== confirmPassword || process.env.REACT_APP_READ_ONLY === "enabled"}
              className={styles.changePasswordSubmitButton}
            >
              {isLoading ? <LoadingOutlined /> : t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
