import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { Row, Col, Button } from "antd";
import { Header } from "../../components/header";
import { Footer } from "../home/footer";
import styles from "./addTradingAccount.module.css";

const cx = classNames.bind(styles);

export const DownloadTemplate = () => {
  const { t } = useTranslation("addTradingAccount");
  return (
    <>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>{t("myAccount")}</div>
      </div>
      <div className={styles.mainContent}>
        <p className={styles.templateHeader}>{t("downloadTemplate")}.</p>
        <Row className={styles.templateRow}>
          <Col xs={24} className={styles.templateBlock}>
            <div className={styles.templateTitle}>{t("unverifiedDataTemplate")}</div>
            <div className={styles.templateDescription}>
              <p className={styles.templateText}>
                <strong>{t("newFundSeederRegistrants")}</strong> - {t("thisTemplateCanBeUsed")}.
              </p>
              <p className={styles.templateText}>
                <strong>{t("brokerLinkedAccounts")}</strong> -{t("fillInDataPrior")}.
              </p>
            </div>
            <div className={styles.buttonWrapper}>
              <Button htmlType="submit" className={styles.confirmButton} style={{ width: "160px" }}>
                {t("downloadTemplateLabel")}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={styles.templateRow}>
          <Col xs={24} className={cx("templateBlock", "uploadBlock")}>
            <fieldset>
              <input type="file" name="unverified" className={styles.uploadFile} />
              <span>{t("browseOrDropYourFile")}</span>
            </fieldset>
          </Col>
        </Row>
        <Row className={styles.templateRow}>
          <Col xs={24} className={styles.templateBlock}></Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
