import React, { FC, useState, useRef, useEffect } from "react";
import { Button, Table, Tooltip, Empty } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { DownloadOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { main } from "../../constants/actionTypes";
import { Loading } from "../../components/loading";
import { UploadModal } from "./uploadModal";
import { DeleteTradingAccount } from "./deleteModal";
import { LeaderboardChart } from "../dashboard/leaderboardChart";
import { apiClient } from "../../services/base";
import styles from "./myAccount.module.css";

const cx = classNames.bind(styles);

const { Column } = Table;

export interface status {
  active: boolean;
  title: string;
}

export interface platform {
  full_name: string;
  id: number;
}

export interface tableData {
  key: number;
  fsName: string;
  broker: string;
  brokerId: string;
  currency: string;
  preview: string;
  status: status;
  activationDate: string;
  lastUpdateDate: string;
  actions: string;
  platform: platform;
}

interface mapUsersAccount {
  actions: string;
  activationDate: string;
  brokerId: string;
  fsName: string;
  key: number;
  lastUpdateDate: string;
  preview: string;
  status: { active: boolean; title: string };
  platform: platform;
  ccy?: string;
}

export const AccountsTable: FC = () => {
  const { t } = useTranslation("myAccount");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const controlButtonsCellRef = useRef<any>();
  const userAccounts = useAppSelector((state) => state.main.userAccounts);

  const [accountsLoading, setAccountsLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .userTradingAccountsGet({ signal: controller.signal })
      .catch(() => {})
      .finally(() => {
        setAccountsLoading(false);
      });
    return () => controller.abort();
  }, []);

  const mapUserAccounts = userAccounts?.map((userAccount) => {
    const formatActivationDate = Date.parse(userAccount?.valid_from)
      ? format(new Date(userAccount?.valid_from), "yyyy-MM-dd")
      : userAccount?.valid_from;
    const formatLastUpdateDate = Date.parse(userAccount.last_update)
      ? format(new Date(userAccount.last_update), "yyyy-MM-dd")
      : userAccount?.last_update;
    return {
      key: userAccount?.id || 0,
      fsName: userAccount?.fs_nick || "",
      broker: userAccount?.platform?.full_name || "",
      brokerId: userAccount?.extid || "",
      currency: userAccount?.ccy,
      status: {
        active: false,
        title: userAccount?.status?.status || "",
      },
      performance: userAccount?.performance,
      activationDate: formatActivationDate,
      lastUpdateDate: formatLastUpdateDate,
      actions: "",
      platform: userAccount?.platform,
    };
  });

  const handleAccountsLoading = (value: boolean) => {
    setAccountsLoading(value);
  };

  return (
    <div className={styles.accountsTableWrapper}>
      {accountsLoading ? (
        <Loading />
      ) : (
        <>
          {mapUserAccounts?.length ? (
            <Table
              className="my-account-table"
              dataSource={mapUserAccounts}
              components={{
                body: {
                  row: (props: any) => {
                    return (
                      <tr
                        onClick={() => {
                          dispatch({
                            type: main.SET_TRADING_ACCOUNT_ID,
                            payload: props["data-row-key"],
                          });
                          navigate(`/trading-account/${props["data-row-key"]}`);
                        }}
                      >
                        {props.children}
                      </tr>
                    );
                  },
                  cell: (props: any) => {
                    if (props.children[1]?.ref) {
                      return (
                        <td
                          onClick={(event: React.MouseEvent<HTMLTableDataCellElement>) => {
                            event.stopPropagation();
                          }}
                          colSpan={mapUserAccounts.length ? 1 : 9}
                        >
                          {props.children[1]}
                        </td>
                      );
                    }
                    return (
                      <td
                        className={cx({ linkToTradeAccount: mapUserAccounts.length })}
                        colSpan={mapUserAccounts.length ? 1 : 9}
                      >
                        {props.children[1]}
                      </td>
                    );
                  },
                },
              }}
            >
              <Column
                title={() => <span data-testid="fsNameColumn">{t("fSName")}</span>}
                dataIndex="fsName"
                key="fsName"
                sorter={(a: tableData, b: tableData) => {
                  return a.fsName?.localeCompare(b.fsName);
                }}
              />
              <Column
                title={() => <span data-testid="brokerColumn">{t("broker")}</span>}
                dataIndex="broker"
                key="broker"
                sorter={(a: tableData, b: tableData) => {
                  return a.broker?.localeCompare(b.broker);
                }}
              />
              <Column
                title={() => <span data-testid="brokerIDColumn">{t("brokerID")}</span>}
                dataIndex="brokerId"
                key="brokerId"
                sorter={(a: tableData, b: tableData) => {
                  return a.brokerId?.localeCompare(b.brokerId);
                }}
              />
              <Column title={t("currency")} dataIndex="currency" key="currency" />
              <Column
                title={t("preview")}
                dataIndex="performance"
                key="performance"
                align="center"
                render={(chart) => {
                  const currentFsIndex: any = {
                    value: chart,
                  };
                  if (!chart?.length) {
                    return <></>;
                  }
                  return (
                    <div className={styles.tableChartWrapper}>
                      {`${(chart[chart?.length - 1]?.performance * 100).toFixed(1)}%`}
                      <LeaderboardChart currentFsIndex={currentFsIndex} accountsTable={true} />
                    </div>
                  );
                }}
              />
              <Column
                title={() => <span data-testid="statusColumn">{t("status")}</span>}
                dataIndex="status"
                key="status"
                render={(status) => {
                  if (!status.title) {
                    return null;
                  }
                  return (
                    <Tooltip title={t("unverifiedData")}>
                      <strong>{status.title}</strong>
                    </Tooltip>
                  );
                }}
                sorter={(a: tableData, b: tableData) => {
                  return a.status?.title.localeCompare(b?.status?.title);
                }}
              />
              <Column
                title={() => <span data-testid="activationDateColumn">{t("activationDate")}</span>}
                dataIndex="activationDate"
                key="activationDate"
                defaultSortOrder="descend"
                sorter={(a: tableData, b: tableData) => {
                  if (Date.parse(a.activationDate) && Date.parse(b.activationDate)) {
                    return new Date(a.activationDate).getTime() - new Date(b.activationDate).getTime();
                  }
                  return 0;
                }}
              />
              <Column
                title={() => <span data-testid="lastUpdateDateColumn">{t("lastUpdateDate")}</span>}
                dataIndex="lastUpdateDate"
                key="lastUpdateDate"
                sorter={(a: tableData, b: tableData) => {
                  if (Date.parse(a.lastUpdateDate) && Date.parse(b.lastUpdateDate)) {
                    return new Date(a.lastUpdateDate).getTime() - new Date(b.lastUpdateDate).getTime();
                  }
                  return 0;
                }}
              />
              <Column
                title={t("actions")}
                dataIndex="Actions"
                key="Actions"
                render={(text, record: mapUsersAccount) => {
                  return (
                    <div style={{ textAlign: "right" }} ref={controlButtonsCellRef}>
                      {text}
                      <div>
                        {record?.platform?.id === 24 && (
                          <>
                            <UploadModal record={record} />
                            {false && (
                              <Tooltip title={t("downloadAccountData")}>
                                <Button className={styles.actionIcon} shape="circle" icon={<DownloadOutlined />} />
                              </Tooltip>
                            )}
                          </>
                        )}
                        <DeleteTradingAccount
                          record={record}
                          accountsLoading={accountsLoading}
                          handleAccountsLoading={handleAccountsLoading}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </Table>
          ) : (
            <Empty />
          )}{" "}
        </>
      )}
    </div>
  );
};
