import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/index";
import { Login } from "../pages/signup";

export const ProtectedRoute = ({ component }: { component: ReactElement<any, any> }) => {
  const navigate = useNavigate();
  const accessToken = useAppSelector((state) => state.main.accessToken);
  if (accessToken) {
    return <>{component}</>;
  }
  navigate("/login");
  return <Login />;
};
