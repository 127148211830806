import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Select, Modal } from "antd";
import { useParams } from "react-router-dom";
import classNames from "classnames/bind";
import { Header } from "../../components/header";
import { AccountStrategy } from "./accountStrategy";
import { GraphTabs } from "./graphTabs";
import { PerformanceTable } from "./performanceTable";
import { useAppSelector } from "../../hooks";
import { Loading } from "../../components/loading";
import { graphSelectData } from "../dashboard/sources";
import { apiClient } from "../../services/base";
import styles from "./tradingAccount.module.css";

const cx = classNames.bind(styles);

export const TradingAccount = () => {
  const { t } = useTranslation("tradingAccount");
  const { id } = useParams();

  const userAccounts = useAppSelector((state) => state.main.userAccounts);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [underwaterLoading, setUnderwaterLoading] = useState<boolean>(true);
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>(
    userAccounts.find((userAccount) => userAccount?.id === Number(id))?.fs_nick
  );
  const [openModal, setOpenModal] = useState(false);

  const ownAccount = userAccounts.find((userAccount) => Number(userAccount?.id) === Number(id));
  let userName;
  const mapLeaderBoardsList = graphSelectData.map((chart) => {
    const usersLeaderboard = chart?.leaderboard?.find((userInTheChart) => userInTheChart.id === Number(id));
    userName = usersLeaderboard?.nick;
    if (usersLeaderboard) {
      return (
        <div className={styles.leaderboard} key={chart?.name}>
          <span className={styles.leaderboardChartName}>{chart?.name}</span>
          <ol className={styles.leaderboardUsersList}>
            {chart.leaderboard?.map((boardUsers) => {
              return (
                <li
                  className={cx("leaderboardUsers", {
                    leaderboardSelectedUser: boardUsers?.id === Number(id),
                  })}
                  key={boardUsers?.id}
                >
                  {boardUsers?.nick}
                </li>
              );
            })}
          </ol>
        </div>
      );
    }
  });

  useEffect(() => {
    document.title = "Trading Account";
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    apiClient.userTradingAccountsGet({ signal: controller.signal });
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    apiClient.underwaterAccountIdGet(Number(id), { signal: controller.signal }).finally(() => {
      setUnderwaterLoading(false);
    });
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    apiClient.returnsListAccountIdGet(Number(id), { signal: controller.signal }).finally(() => {
      setIsLoading(false);
    });
    return () => controller.abort();
  }, []);

  const loadNewAccountData = (value: string) => {
    setIsLoading(true);
    const accountId = userAccounts?.find((userAccount) => userAccount?.extid === value)?.id;
    const accountName = userAccounts?.find((userAccount) => userAccount?.extid === value)?.fs_nick;
    setSelectedAccount(accountName);
    apiClient.returnsListAccountIdGet(accountId).finally(() => {
      setIsLoading(false);
    });
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>
          {ownAccount ? (
            <>
              <span className={styles.myAccount}>{t("myAccount")}</span>
              <Select
                defaultValue={selectedAccount || userAccounts[0]?.fs_nick}
                style={{ width: 120 }}
                onChange={loadNewAccountData}
                options={userAccounts.map((account) => {
                  return { label: account.extid, value: account.extid };
                })}
              />
              <span className={styles.fs_nick}>{selectedAccount || userAccounts[0]?.fs_nick}</span>
            </>
          ) : (
            <span className={styles.userName}>{userName}</span>
          )}
        </div>
      </div>
      <div className={styles.mainContentWrapper}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col xs={24} className={styles.leaderboardWrapper}>
                {mapLeaderBoardsList}
              </Col>
            </Row>
            <AccountStrategy />
            <Row>
              <Col sm={24} className={styles.performanceMeasures}>
                <span className={styles.performanceMeasuresText} role="button" onClick={handleModalOpen}>
                  {t("performanceMeasureDefinitions")} <span className={styles.link}>{t("clickHere")}</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <GraphTabs underwaterLoading={underwaterLoading} />
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <PerformanceTable />
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <div className={styles.analyticModule}>{t("analyticsModule")}.</div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Modal
        visible={openModal}
        width="100%"
        footer={<></>}
        bodyStyle={{ height: "650px", paddingTop: 50 }}
        onCancel={handleModalClose}
        destroyOnClose={true}
      >
        <iframe src="/fundSeederAnalyticsManual.pdf" style={{ width: "100%", height: "100%" }} frameBorder="0"></iframe>
      </Modal>
    </>
  );
};
