import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { useAppSelector } from "../../hooks";
import { Loading } from "../../components/loading";
import { apiClient } from "../../services/base";
import styles from "./dashboard.module.css";

const cx = classNames.bind(styles);

interface graphSelectCard {
  handleGraphChange: (value: string) => void;
  selectedChartName: string;
}

export const GraphSelectCard: FC<graphSelectCard> = ({ handleGraphChange, selectedChartName }) => {
  const { t } = useTranslation("dashboard");
  const fsIndices = useAppSelector((state) => state.main.fsIndices);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .fsIndicesGet()
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {!isLoading && <h3 className={styles.mainHeader}> {t("subsetIndexes")}.</h3>}
      <div className={styles.selectCardWrapper}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {fsIndices.map((card) => {
              const barValue = Number(card?.performance?.toFixed(2));
              const barHeight = `${54 * (card?.performance / 2 / 100)}`;
              const name = card?.index_display_name;
              return (
                <div
                  key={card.id}
                  className={cx("selectCard", { activeSelectCard: card?.index_name === selectedChartName })}
                  onClick={() => handleGraphChange(card?.index_name)}
                  role="button"
                >
                  <span className={styles.barName}>{t(`${name}`)}</span>
                  <br />
                  <span className={styles.barValue}>{barValue}%</span>
                  <div className={styles.progressBarWrapper}>
                    <div
                      className={cx("progressBar", {
                        activeProgressBar: card?.index_name === selectedChartName,
                        negativeProgressBar: Number(barHeight.slice(0, barHeight?.length - 2)) < 0,
                      })}
                      style={{
                        height: `${Math.abs(Number(barHeight))}px`,
                        transform: `translateY(${
                          Number(barHeight.slice(0, barHeight?.length - 2)) < 0 ? -barHeight : 0
                        }px)`,
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
