// @ts-nocheck
import React, { FC } from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Tooltip } from "chart.js";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { useAppSelector } from "../../hooks";
import styles from "./tradingAccount.module.css";

Tooltip.positioners.myCustomPositioner = (elements) => {
  return {
    x: elements[0]?.element.x,
    y: elements[0]?.element.y,
  };
};

export const GraphChart: FC = () => {
  const { t } = useTranslation("tradingAccount");
  const filteredDate = useAppSelector((state) => state.tradingAccount.filteredDate);
  const filteredPerformance = useAppSelector((state) => state.tradingAccount.filteredPerformance);
  const filteredReturnValue = useAppSelector((state) => state.tradingAccount.filteredReturnValue);
  const monthData = useAppSelector((state) => state.tradingAccount.monthData);
  const monthlyDataPreparedForGraph: number[] = [];
  const monthlyLabels: string[] = [];
  const latestDateValue = new Date(filteredDate[filteredDate?.length - 1]).getTime();
  const firstDateValue = new Date(filteredDate[0]).getTime();
  const dateDifference = latestDateValue - firstDateValue;
  const shortestMonth = 2419200000;

  // Do not return anything
  filteredDate?.forEach((dateValue) => {
    const transformDate: string = format(new Date(dateValue), "yyyy-MM");
    monthData.forEach((item) => {
      const transformMonthDate: string = format(new Date(item?.date), "yyyy-MM");
      if (transformMonthDate === transformDate) {
        monthlyDataPreparedForGraph.push(item?.monthly_return * 100);
        monthlyLabels.push(item?.date);
      }
    });
  });

  const labels = filteredDate;

  const performanceRange =
    Math.max(...filteredPerformance) + (Math.min(...filteredPerformance) < 0 ? -Math.min(...filteredPerformance) : 0);
  const returnValueRange =
    Math.max(...filteredReturnValue) + (Math.min(...filteredReturnValue) < 0 ? -Math.min(...filteredReturnValue) : 0);

  const options: ChartOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any[]) => {
            if (context.length) {
              return context[0].dataset.type === "line"
                ? format(context[0].parsed.x, "MMM dd yyyy")
                : format(new Date(context[0].label), "MMM yyyy");
            }
            return format(new Date(Date.now()), "MMM dd yyyy");
          },
          label: (context: any) => {
            if (context.dataset.type === "line") {
              return `${t("performance")}: ${Number(context.raw).toFixed(2)}%`;
            }
            return `${t("monthlyReturn")}: ${Number(context.raw).toFixed(2)}%`;
          },
        },
        position: "myCustomPositioner",
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        title: {
          display: true,
          text: `${t("monthlyReturn")} (%)`,
          padding: 2,
          color: "rgb(100, 148, 124)",
        },
        display: true,
        position: "left" as const,
        beginAtZero: true,
        offset: true,
        ticks: {
          color: "rgb(100, 148, 124)",
          stepSize: returnValueRange / 8,
          beginAtZero: true,
        },
        max: Math.max(Math.max(...filteredReturnValue), -Math.min(...filteredReturnValue)),
        min:
          Math.min(...filteredReturnValue) < 0
            ? Math.min(-Math.max(...filteredReturnValue), Math.min(...filteredReturnValue))
            : 0,
      },
      y2: {
        type: "linear" as const,
        title: {
          display: true,
          text: `${t("performance")} (%)`,
          padding: 2,
          color: "#72c6ed",
        },
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        offset: true,
        ticks: {
          color: "#72c6ed",
          stepSize: performanceRange / 8,
          beginAtZero: true,
          maxTicksLimit: 10,
        },
        max: Math.max(Math.max(...filteredPerformance), -Math.min(...filteredPerformance)),
        min:
          Math.min(...filteredPerformance) < 0
            ? Math.min(-Math.max(...filteredPerformance), Math.min(...filteredPerformance))
            : 0,
      },
      x: {
        type: "timeseries",
        min: labels[0],
        max: labels[labels.length - 1],
        ticks: {
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
          align: "center",
        },
        offset: false,
        title: {
          display: false,
        },
        grid: {
          drawOnChartArea: true,
          drawTicks: true,
          offset: false,
          color: (context) => {
            if (context.index !== 0) {
              return "rgb(229, 229, 229)";
            }
          },
        },
      },
      x2: {
        axis: "x",
        labels: monthlyLabels,
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `${t("performance")}`,
        borderColor: "#72c6ed",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointHitRadius: 0,
        pointBackgroundColor: "rgba(114, 198, 237, 0)",
        pointHoverBackgroundColor: "rgb(114, 198, 237)",
        data: filteredPerformance,
        fill: {
          target: "origin",
          above: "rgba(233, 248, 251, 0.3)",
          below: "rgba(233, 248, 251, 0.3)",
        },
        yAxisID: "y2",
        xAxisID: "x",
      },
      {
        type: "bar" as const,
        label: `${t("monthlyReturn")}`,
        backgroundColor: (d: any) => {
          if (d.raw < 0) {
            return "#fbb5aa";
          }
          return "#a1d0b9";
        },
        hoverBackgroundColor: (d: any) => {
          if (d.raw < 0) {
            return "#fbb5aa";
          }
          return "#a1d0b9";
        },
        data: dateDifference > shortestMonth ? monthlyDataPreparedForGraph : null,
        borderColor: "white",
        borderWidth: 0,
        inflateAmount: 0.5,
        yAxisID: "y",
        xAxisID: "x2",
      },
    ],
  };
  return (
    <div className={styles.chart}>
      {!filteredPerformance?.length || !filteredReturnValue?.length ? (
        <Empty />
      ) : (
        <Chart options={options} type="bar" data={data} />
      )}
    </div>
  );
};
