import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/logo_small.svg";
import Lamp from "../../assets/lamp.svg";
import Gear from "../../assets/gear.svg";
import Headphones from "../../assets/headphones.svg";
import { encryptPassword } from "../../utils/encryptPassword";
import { apiClient } from "../../services/base";
import styles from "./signup.module.css";

interface ResetFormData {
  password?: string;
  confirmationPassword?: string;
}

export const ResetPassword = () => {
  const { t } = useTranslation("resetPassword");
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<ResetFormData>({});
  const [resetPasswordMsg, setResetPasswordMsg] = useState<null | string | undefined>(null);
  const [hasSubmitTriggered, setHasSubmitTriggered] = useState(false);
  const [samePassword, setSamePassword] = useState(false);

  const onChangeHandler = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setResetForm((previousValues) => {
      return { ...previousValues, [name]: event.target.value };
    });
  };

  useEffect(() => {
    if (hasSubmitTriggered) {
      if (resetForm.password === resetForm.confirmationPassword) {
        setSamePassword(true);
      } else {
        setSamePassword(false);
      }
    }
  }, [resetForm.password, resetForm.confirmationPassword]);

  const onSubmitHandler = (event: FormEvent): void => {
    if (!loading) {
      event.preventDefault();
      setHasSubmitTriggered(true);
      if (resetForm.password !== resetForm.confirmationPassword) {
        setSamePassword(false);
        return;
      } else {
        setSamePassword(true);
      }
      setLoading(true);
      apiClient
        .resetTokenPost(
          {
            reset_password: encryptPassword(resetForm.password!),
          },
          id
        )
        .then((res) => {
          setResetPasswordMsg(res?.data?.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div className={styles.logoText}>
          {loading ? (
            <img className={styles.logoImg} src={Logo} />
          ) : (
            <Link to="/">
              <img className={styles.logoImg} src={Logo} />
            </Link>
          )}
          {t("resetPassword")}
        </div>
        <form action="" onSubmit={onSubmitHandler}>
          <div className={styles.wrpInputs}>
            {resetPasswordMsg ? (
              <div>
                <h2>{t("passwordHasBeenSuccessfullyReset")}!</h2>
                <p>
                  {t("please")} {loading ? t("signIn") : <a href="https://fundseeder.com/">{t("signIn")}</a>}
                </p>
              </div>
            ) : (
              <Spin spinning={loading} tip="Loading...">
                <div className={styles.controlItem}>
                  <label className={styles.controlLabel} htmlFor="password">
                    {t("newPassword")}
                    <sup>*</sup>
                  </label>
                  <Input.Password
                    id="password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    placeholder={t("newPassword")}
                    required
                    onChange={onChangeHandler("password")}
                    disabled={loading}
                  />
                </div>
                <div className={styles.controlItem}>
                  <label className={styles.controlLabel} htmlFor="confirmationPassword">
                    {t("repeatPassword")}
                    <sup>*</sup>
                  </label>
                  <Input.Password
                    id="confirmationPassword"
                    placeholder={t("enterConfirmationPassword")}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    required
                    onChange={onChangeHandler("confirmationPassword")}
                    disabled={loading}
                  />
                  <>
                    {!samePassword && hasSubmitTriggered && (
                      <div className={styles.error}>{t("providedPassword")}.</div>
                    )}
                  </>
                </div>
                <div className={styles.loginBtnWrp}>
                  <Button className={styles.loginBtn} type="primary" htmlType="submit">
                    {t("resetPassword")}
                  </Button>
                </div>
                <div className="text-center">
                  <p className={styles.controlLabel}>
                    {t("dontHaveAnAccount")}? <a href="https://fundseeder.com/">{t("createAccount")}</a>
                  </p>
                </div>
              </Spin>
            )}
          </div>
        </form>
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrp}>
          <h1 className={styles.title}>
            {t("whatWe")}
            <span className={styles.titleWrp}>
              <span className={styles.titleText}>{t("offer")}</span>
            </span>
            {t("inOurProduct")}
          </h1>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Lamp} alt={t("flexibleIntegration")} />
              </div>
              <h2 className={styles.h2}>{t("flexibleIntegration")}</h2>
              <p>{t("provisionOverTheWeb")}</p>
            </div>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Gear} alt={t("customization")} />
              </div>
              <h2 className={styles.h2}>{t("customization")}</h2>
              <p>{t("weOfferIntegration")}.</p>
            </div>
          </div>
          <div className={styles.blocks}>
            <div className={styles.blockItem}>
              <div className={styles.blockImg}>
                <img src={Headphones} alt={t("support")} />
              </div>
              <h2 className={styles.h2}>{t("support")}</h2>
              <p>{t("ourTools")}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
