import React, { useState } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Integrations } from "@sentry/tracing";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChartJS from "chart.js/auto";
import { registerables } from "chart.js";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { SetupInterceptors } from "./services/base";
import { useAppDispatch } from "./hooks";
import "./index.css";

const environment = window.location.hostname === "localhost" ? "development" : "production";

Sentry.init({
  dsn: "https://53c3b4dcf9264331943ce4645864c84d@o1118666.ingest.sentry.io/6152716",
  integrations: [new Integrations.BrowserTracing()],
  environment: environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ChartJS.register(...registerables);

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

export const NavigateFunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("request");
  const [ran, setRan] = useState(false);

  if (!ran) {
    SetupInterceptors(navigate, dispatch, t);
    setRan(true);
  }
  return <></>;
};

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <Provider store={store}>
        <Auth0Provider
          domain="dev-k2i14rcq.us.auth0.com"
          clientId="ET5WobQICwyqIlguKMS7uTiop3hUeEVZ"
          redirectUri={window.location.origin}
        >
          <BrowserRouter>
            <App />
            <NavigateFunctionComponent />
          </BrowserRouter>
        </Auth0Provider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
