import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { useAppSelector } from "../../hooks";
import styles from "./leaderboard.module.css";

interface dataRecord {
  account_id: number;
  annual_performance: number;
  annual_volatility: number;
  first_date: string;
  fs_nick: string;
  fs_score: number;
  gain_to_pain: number;
  rank: number;
  sharpe: number;
  sortino: number;
}

export const RanksTable: FC = () => {
  const { t } = useTranslation("leaderboard");
  const leaderboard = useAppSelector((state) => state.main.leaderboard);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const columns = [
    {
      title: t("rank"),
      dataIndex: "rank",
      key: "Rank",
      render: (rank: number) => {
        return rank + 1;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("account"),
      dataIndex: "fs_nick",
      key: "Account",
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("fSScore"),
      dataIndex: "fs_score",
      key: "FS Score",
      render: (fsScore: number) => {
        return fsScore.toFixed();
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("return"),
      dataIndex: "annual_performance",
      key: "Return",
      render: (performance: number) => {
        return `${(performance * 100).toFixed()}%`;
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
    },
    {
      title: t("timeTrackedAll"),
      dataIndex: "first_date",
      key: "Time Tracked All",
      render: (time: string) => {
        const timeDifference = new Date(Date.now())?.getTime() - new Date(time)?.getTime();
        const years = Math.floor(timeDifference / 1000 / 60 / 60 / 24 / 365);
        const days = Math.floor(timeDifference / 1000 / 60 / 60 / 24);
        const yearsToDisplay = years ? `${years}Y /` : "";
        const daysToDisplay = `${days - years * 365}d`;
        return `${yearsToDisplay} ${daysToDisplay}`;
      },
      filters: [
        { text: "All", value: true },
        { text: ">=1Y", value: 31556926000 },
        { text: ">=2Y", value: 63113852000 },
        { text: ">=3Y", value: 94670778000 },
        { text: ">=4Y", value: 126227704000 },
        { text: ">=5Y", value: 157784630000 },
      ],
      onFilter: (value: any, record: dataRecord) => {
        return value <= new Date()?.getTime() - new Date(record.first_date)?.getTime();
      },
      onCell: () => {
        return { className: styles.tableCell };
      },
      filterMultiple: false,
    },
  ];

  return (
    <Table
      dataSource={leaderboard}
      columns={columns}
      tableLayout="fixed"
      rowClassName={styles.tableRow}
      rowKey="account_id"
      expandable={{
        onExpandedRowsChange: (rows: any) => {
          setExpandedRows(rows);
        },
        expandIcon: () => <></>,
        expandIconColumnIndex: -1,
        expandRowByClick: true,
      }}
    />
  );
};
