import React, { FC } from "react";
import { Col, Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LeaderboardChart } from "./leaderboardChart";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fsIndices } from "../reducer";
import { main } from "../../constants/actionTypes";
import styles from "./dashboard.module.css";

interface LeaderboardCard {
  leader: fsIndices;
}

export const LeaderboardCard: FC<LeaderboardCard> = ({ leader }) => {
  const { t } = useTranslation("dashboard");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fsIndex = useAppSelector((state) => state.main.fsIndex);
  const currentFsIndex = fsIndex.find((fsIndexItem) => Number(fsIndexItem?.id) === Number(leader?.id)) || {
    id: 1,
    value: [],
  };

  const navigateToTradingAccount = (account_id: string | number) => () => {
    dispatch({
      type: main.SET_TRADING_ACCOUNT_ID,
      payload: account_id,
    });
  };

  const navigateToLeaderBoard = (value: string) => () => {
    dispatch({
      type: main.SET_SELECTED_LEADERBOARD,
      payload: value === "fundseeder" ? "leaderboard" : value,
    });
    navigate("/leaderboard");
  };

  return (
    <Col xs={24} sm={6} className={styles.cardWrapper}>
      <Card
        title={
          <span onClick={navigateToLeaderBoard(leader?.index_name)} className={styles.linkToLeaderboard} role="button">
            {t(`${leader?.index_display_name}`)}
          </span>
        }
        extra={<span className={styles.leaderboardIndex}>{leader.performance.toFixed() + "%"}</span>}
        className={styles.leaderboardCard}
        headStyle={{
          color: "#c7d6df",
          textTransform: "uppercase",
          borderBottom: "none",
          fontSize: 18,
        }}
        bodyStyle={{ paddingTop: 0 }}
        role="button"
      >
        <ul className={styles.leadersList}>
          {leader?.leaders.map((position) => {
            return (
              <Link
                to={`/trading-account/${position?.account_id}`}
                className={styles.tradingAccountLink}
                key={position.fs_score}
              >
                <li className={styles.leaderboardListItem} onClick={navigateToTradingAccount(position?.account_id)}>
                  <span className={styles.leaderboardPosition}>{position.rank + 1}</span>
                  <span className={styles.leaderboardNick}>{position.fs_nick}</span>
                  <span className={styles.leaderboardScore}>{position.fs_score.toFixed()}</span>
                </li>
              </Link>
            );
          })}
        </ul>
        <LeaderboardChart currentFsIndex={currentFsIndex} />
      </Card>
    </Col>
  );
};
