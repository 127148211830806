import React from "react";
import { Tooltip, Typography } from "antd";
import styles from "./addTradingAccount.module.css";

export const renderTooltip = (
  label: string,
  renderAlternativeTooltip?: boolean,
  resetData?: any,
  switchDataToUnverified?: any,
  t?: any
) => {
  if (label === "Choose Connection Method") {
    return (
      <Tooltip
        title={() => {
          if (renderAlternativeTooltip) {
            return (
              <>
                <p>{t("notCurrentlySupported")}:</p>
                <ol>
                  <li>{t("dailyReturnData")}</li>
                  <li>{t("dailyNLVData")}</li>
                  <li>{t("monthlyReturnData")}</li>
                  <li>{t("monthlyNLVData")}</li>
                </ol>
                <p>{t("monthlyTemplates")}.</p>
                <p>{t("downloadTheSelectedTemplate")}.</p>
              </>
            );
          }
          return (
            <p>
              {t("ifYouWantToChangeMethodClick")}{" "}
              <a role="button" className={styles.resetDataLink} onClick={resetData}>
                {t("here")}
              </a>
            </p>
          );
        }}
        trigger="click"
        overlayClassName={styles.tooltipContent}
        overlayInnerStyle={{ width: renderAlternativeTooltip ? "450px" : "250px" }}
      >
        <Typography className={styles.tooltip}>
          {renderAlternativeTooltip ? t("whatIsUnverifiedData") : t("iWantToChangeTheMethod")}
        </Typography>
      </Tooltip>
    );
  } else if (label === "Select Brokerage Firm") {
    return (
      <Tooltip
        title={() => {
          return (
            <>
              <p>{t("additionalBrokerageFirms")}.</p>
              <p>
                {t("toContinuePleaseUseOur")}
                <a role="button" className={styles.resetDataLink} onClick={switchDataToUnverified}>
                  {" "}
                  {t("unverifiedData")}{" "}
                </a>
                {t("connectionOptionByClickingHere")}.
              </p>
            </>
          );
        }}
        trigger="click"
        overlayClassName={styles.tooltipContent}
      >
        <Typography className={styles.tooltip}>{t("iDontSeeMyFirm")}</Typography>
      </Tooltip>
    );
  } else if (label === "Broker Name") {
    return (
      <Tooltip
        title={() => {
          return <p>{t("pleaseFillInNameOfYourBrokerageFirm")}.</p>;
        }}
        trigger="click"
        overlayClassName={styles.tooltipContent}
      >
        <Typography className={styles.tooltip}>{t("whatIsBrokerName")}?</Typography>
      </Tooltip>
    );
  } else if (label === "Account Number") {
    return (
      <Tooltip
        title={() => {
          return <p>{t("accountNumberShouldLook")}.</p>;
        }}
        trigger="click"
        overlayClassName={styles.tooltipContent}
      >
        <Typography className={styles.tooltip}>{t("whatIsIt")}</Typography>
      </Tooltip>
    );
  } else if (label === "Calculation Method") {
    return (
      <Tooltip
        title={() => {
          return <p>{t("netLiquidationValueOfTheAccount")}.</p>;
        }}
        trigger="click"
        overlayClassName={styles.tooltipContent}
      >
        <Typography className={styles.tooltip}>{t("whatIsNLV")}?</Typography>
      </Tooltip>
    );
  }
};
