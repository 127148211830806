export const searchResult = {
  pages: 11,
  count_results: 107,
  result: [
    {
      key: 1,
      id: 17504,
      user_id: 16692,
      tplatform_id: 1,
      nick: "TA8153574",
      fs_score_mix: 85.2586,
      inception_date_mix: "2020-11-02",
      last_update_mix: "2022-07-15",
      extid: "U6512287",
      currency_id: 6,
      nominal_size_acc_mix: "421869.035529615",
      performance_mix: 0.7071012981259965,
      performance_ytd_mix: 0.4800457717703206,
      performance_ann_mix: 0.36934572652500863,
      maxdd_mix: 0.1599786962213059,
      vol_mix: 0.1257918063822468,
      sharpe_mix: 2.9361668072614227,
      sortino_mix: 3.423934928142026,
      gpr_mix: 0.5751432605440514,
      calmar_mix: 2.308718193415426,
      var_mix: -0.01022227373969999,
      es_mix: -0.016065669325869565,
      mean_mix: 0.0012353451376846847,
      stdev_mix: 0.0077868218075474865,
      skewness_mix: 0.6238066557608158,
      kurtosis_mix: 6.549223163900848,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2020-12-14T09:55:31.000Z",
      updated_at: "2022-07-18T10:49:19.000Z",
      strategy: "Fundamental, Long-only Value strategy",
      edge_sources: "Security selection, Portfolio Management.",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: true,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 1.420676893504399,
      gpr_monthly_mix: 4.531655982861701,
      mar_mix: 2.308718193415426,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: {
        leaderboard: [
          {
            position: 1,
            nick: "TA8153574",
            score: 86.2785,
            icon: true,
            change: 1,
          },
          { position: 2, nick: "TA4855740", score: 85.2083, icon: false, change: -1 },
          {
            position: 3,
            nick: "TA1786105",
            score: 84.1601,
            icon: false,
            change: 0,
          },
        ],
        tm_discretionary: [
          {
            position: 1,
            nick: "TA8153574",
            score: 86.2785,
            icon: true,
            change: 1,
          },
          { position: 2, nick: "TA4855740", score: 85.2083, icon: false, change: -1 },
          {
            position: 3,
            nick: "TA1786105",
            score: 84.1601,
            icon: false,
            change: 0,
          },
        ],
        tm_fundamental: [
          {
            position: 1,
            nick: "TA8153574",
            score: 86.2785,
            icon: true,
            change: 1,
          },
          { position: 2, nick: "TA4855740", score: 85.2083, icon: false, change: -1 },
          {
            position: 3,
            nick: "TA1786105",
            score: 84.1601,
            icon: false,
            change: 0,
          },
        ],
        mt_equities: [
          {
            position: 1,
            nick: "TA8153574",
            score: 86.2785,
            icon: true,
            change: 1,
          },
          { position: 2, nick: "TA4855740", score: 85.2083, icon: false, change: -1 },
          {
            position: 3,
            nick: "TA1786105",
            score: 84.1601,
            icon: false,
            change: 0,
          },
        ],
      },
      performance_chart: [
        "1.133001519883824",
        "1.153411151660225",
        "1.232623140555983",
        "1.373994112363584",
        "1.456553123133906",
        "1.568703645075275",
        "1.691140151473895",
        "1.752756349165141",
        "1.772401179063505",
        "1.765849976699205",
        "1.73812340259481",
        "1.78654842847549",
        "1.803101009731946",
        "1.822214542989648",
        "1.838453441455322",
        "1.598400502347526",
        "1.723164711612861",
        "1.689710944466267",
        "1.746328655998111",
        "1.707101298119519",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "421869.035529615",
      nlv_usd_mix: "421869.035529615",
      inception_date_ver: "2020-11-02",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "421869.035529615",
      nominal_size_acc_ver: "421869.035529615",
      nominal_size_usd_ver: "421869.035529615",
      fs_score_ver: 85.2586,
      gpr_ver: 0.5751432605440514,
      sharpe_ver: 2.9361668072614227,
      sortino_ver: 3.423934928142026,
      performance_ann_ver: 0.36934572652500863,
      performance_ver: 0.7071012981259965,
      performance_ytd_ver: 0.4800457717703206,
      maxdd_ver: 0.1599786962213059,
      vol_ver: 0.1257918063822468,
      calmar_ver: 2.308718193415426,
      var_ver: -0.01022227373969999,
      es_ver: -0.016065669325869565,
      mean_ver: 0.0012353451376846847,
      stdev_ver: 0.0077868218075474865,
      skewness_ver: 0.6238066557608158,
      kurtosis_ver: 6.549223163900848,
      mar_ver: 2.308718193415426,
      sterling_ver: 1.420676893504399,
      gpr_monthly_ver: 4.531655982861701,
      subscribe_steps: true,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: true,
      aum: 300000.0,
      avg_positions: 40.0,
      appr_trades: 700.0,
      avg_holding_period: 180.0,
      signup_reason: null,
      strategy_equities: "long",
      region_equities: "asia,japan,australia_new_zealand,emerging",
      sector_equities: null,
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 620,
      user_full_name: "Thibault Potie",
      have_unverified: false,
      user_regulated: false,
      user_regulatorybody: "",
      navs_type: "verified",
      have_trades: false,
      user: {
        email: "thibaultpo@hotmail.com",
        phone_1: null,
        phone_2: null,
        address_1: "Flat A, 43/F, Tower 1",
        address_2: "Grand Yoho",
        seeking_for_investor: false,
        user_regulatorybody: "",
        personal_info_filled: true,
        years_experience: "17",
        education_name: "Master’s Degree",
      },
      calcmethods: [
        {
          id: 20351,
          calc_method: "nlv",
          start_date: "2020-12-14",
          created_at: "2020-12-14T09:55:31.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 2,
      id: 42,
      user_id: 111,
      tplatform_id: 1,
      nick: "TA9671849",
      fs_score_mix: 79.15,
      inception_date_mix: "2013-01-02",
      last_update_mix: "2022-07-15",
      extid: "U866849",
      currency_id: 9,
      nominal_size_acc_mix: "277132.238585188",
      performance_mix: 7.544833563342252,
      performance_ytd_mix: 0.11012405753690468,
      performance_ann_mix: 0.252176810799851,
      maxdd_mix: 0.24994375718458484,
      vol_mix: 0.17386381301920648,
      sharpe_mix: 1.4504272419930961,
      sortino_mix: 1.5618098898491795,
      gpr_mix: 0.2853253579342092,
      calmar_mix: 0.9447148126710245,
      var_mix: -0.015216931193999991,
      es_mix: -0.024103803883336,
      mean_mix: 0.0009230579602507054,
      stdev_mix: 0.010781117243257029,
      skewness_mix: 0.21166813850272542,
      kurtosis_mix: 6.6368104735185725,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 1445390.0,
      max_trade_length: 26697600.0,
      min_trade_length: 86400.0,
      avg_win: 0.00051993,
      max_win: 0.00914225,
      min_win: 0.0,
      avg_loss: 0.000550176,
      max_loss: 0.0206187,
      min_loss: 0.0,
      created_at: "2015-09-30T18:04:09.000Z",
      updated_at: "2022-07-18T09:36:06.000Z",
      strategy: "Mulitstrat. Long and Short over various time frames (daily to multiyear holds). ",
      edge_sources: "Target retail driven stocks. ",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: true,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: true,
      mt4server_id: null,
      sterling_mix: 0.7206209730063431,
      gpr_monthly_mix: 2.307432996618986,
      mar_mix: 1.008934224404801,
      num_trades: 6088,
      num_positive: 2689,
      num_negative: 3399,
      leaderboards: null,
      performance_chart: [
        "1.138030052185059",
        "1.605909943580627",
        "1.991129994392395",
        "2.327559947967529",
        "2.27239990234375",
        "2.466929912567139",
        "2.356719970703125",
        "2.668349981307983",
        "3.065459966659546",
        "3.763910055160523",
        "3.910112142562866",
        "4.048190116882324",
        "4.259871006011963",
        "4.679500481115125",
        "4.976121104257149",
        "6.955880995093674",
        "7.809208888839007",
        "8.828971365318988",
        "8.935963408847773",
        "8.544864382727566",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "193406.545177743039",
      nlv_usd_mix: "193406.545177743039",
      inception_date_ver: "2013-01-02",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "193406.545177743039",
      nominal_size_acc_ver: "277132.238585188",
      nominal_size_usd_ver: "193406.545177743039",
      fs_score_ver: 79.15,
      gpr_ver: 0.2853253579342092,
      sharpe_ver: 1.4504272419930961,
      sortino_ver: 1.5618098898491795,
      performance_ann_ver: 0.252176810799851,
      performance_ver: 7.544833563342252,
      performance_ytd_ver: 0.11012405753690468,
      maxdd_ver: 0.24994375718458484,
      vol_ver: 0.17386381301920648,
      calmar_ver: 0.9447148126710245,
      var_ver: -0.015216931193999991,
      es_ver: -0.024103803883336,
      mean_ver: 0.0009230579602507054,
      stdev_ver: 0.010781117243257029,
      skewness_ver: 0.21166813850272542,
      kurtosis_ver: 6.6368104735185725,
      mar_ver: 1.008934224404801,
      sterling_ver: 0.7206209730063431,
      gpr_monthly_ver: 2.307432996618986,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: "79",
      become_regulated: false,
      aum: 7500000.0,
      avg_positions: 25.0,
      appr_trades: 300.0,
      avg_holding_period: 1.0,
      signup_reason: null,
      strategy_equities: "long/short,neutral,event",
      region_equities: "us,europe,australia_new_zealand,emerging",
      sector_equities: "true",
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: "equities",
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: "Biotechnology",
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: "other",
      sector_futures_items: null,
      regulated: true,
      non_otc_markets_items: null,
      options_volatility: "none",
      futures_specific_asset: false,
      regulatory_body:
        "I use regulatory hosting company (Lawson Connor).  They are registered with the FCA. https://www.lawsonconner.com/regulatory-hosting/",
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 3481,
      user_full_name: "Michael Kean",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "New Zealand Financial Services Provider Register",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "michael@steelroadcapital.com",
        phone_1: "07503789554",
        phone_2: "07503789554",
        address_1: "87 Chinbrook Road",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "New Zealand Financial Services Provider Register",
        personal_info_filled: true,
        years_experience: "19",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 42,
          calc_method: "nlv",
          start_date: "2015-09-30",
          created_at: "2015-09-30T18:04:09.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 3,
      id: 1541,
      user_id: 111,
      tplatform_id: 1,
      nick: "TA9362513",
      fs_score_mix: 78.0223,
      inception_date_mix: "2016-03-23",
      last_update_mix: "2022-07-15",
      extid: "U1582319",
      currency_id: 9,
      nominal_size_acc_mix: "427644.89618852",
      performance_mix: 6.403855741341758,
      performance_ytd_mix: 0.09591698113251024,
      performance_ann_mix: 0.3728408293558618,
      maxdd_mix: 0.33746245372185946,
      vol_mix: 0.19450543972086518,
      sharpe_mix: 1.916865820775634,
      sortino_mix: 2.1194100772017666,
      gpr_mix: 0.3729601351112293,
      calmar_mix: 0.9225221155651302,
      var_mix: -0.016073320410799993,
      es_mix: -0.025991716109518073,
      mean_mix: 0.0012909818823108273,
      stdev_mix: 0.012057688619819255,
      skewness_mix: 0.22143680498757046,
      kurtosis_mix: 7.223131536443761,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2016-03-20T16:05:04.000Z",
      updated_at: "2022-07-18T09:44:32.000Z",
      strategy:
        "Majority of trading is news and catalyst driven, high portion of trading is in small cap and biotech stocks. Very concentrated swing positions (10% options, 25-50% stock), and daily trading positions (5-20%).",
      edge_sources: "Target retail driven stocks.",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: true,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: true,
      mt4server_id: null,
      sterling_mix: 0.852280752745276,
      gpr_monthly_mix: 3.820852323563728,
      mar_mix: 1.1048364795662917,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: null,
      performance_chart: [
        "1.107924699783325",
        "1.242721557617188",
        "1.540088295936585",
        "1.860137701034546",
        "2.063643932342529",
        "2.345193386077881",
        "2.475259304046631",
        "2.732130527496338",
        "2.924643516540527",
        "3.229294680727067",
        "3.414399071450342",
        "3.946979787201474",
        "4.798597208651916",
        "6.145592643896362",
        "6.64630807315673",
        "7.635105383548639",
        "7.935178587293816",
        "8.083038204179766",
        "8.068306085842559",
        "7.404080074674869",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "298447.132520427106",
      nlv_usd_mix: "298447.132520427106",
      inception_date_ver: "2016-03-23",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "298447.132520427106",
      nominal_size_acc_ver: "427644.89618852",
      nominal_size_usd_ver: "298447.132520427106",
      fs_score_ver: 78.0223,
      gpr_ver: 0.3729601351112293,
      sharpe_ver: 1.916865820775634,
      sortino_ver: 2.1194100772017666,
      performance_ann_ver: 0.3728408293558618,
      performance_ver: 6.403855741341758,
      performance_ytd_ver: 0.09591698113251024,
      maxdd_ver: 0.33746245372185946,
      vol_ver: 0.19450543972086518,
      calmar_ver: 0.9225221155651302,
      var_ver: -0.016073320410799993,
      es_ver: -0.025991716109518073,
      mean_ver: 0.0012909818823108273,
      stdev_ver: 0.012057688619819255,
      skewness_ver: 0.22143680498757046,
      kurtosis_ver: 7.223131536443761,
      mar_ver: 1.1048364795662917,
      sterling_ver: 0.852280752745276,
      gpr_monthly_ver: 3.820852323563728,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: "79",
      become_regulated: false,
      aum: 7500000.0,
      avg_positions: 25.0,
      appr_trades: 300.0,
      avg_holding_period: 1.0,
      signup_reason: null,
      strategy_equities: "long/short,neutral,event",
      region_equities: "us,europe,australia_new_zealand,emerging",
      sector_equities: "true",
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: "equities",
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: "Biotechnology",
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: "other",
      sector_futures_items: null,
      regulated: true,
      non_otc_markets_items: null,
      options_volatility: "none",
      futures_specific_asset: false,
      regulatory_body:
        "I use regulatory hosting company (Lawson Connor).  They are registered with the FCA. https://www.lawsonconner.com/regulatory-hosting/",
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 2305,
      user_full_name: "Michael Kean",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "New Zealand Financial Services Provider Register",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "michael@steelroadcapital.com",
        phone_1: "07503789554",
        phone_2: "07503789554",
        address_1: "87 Chinbrook Road",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "New Zealand Financial Services Provider Register",
        personal_info_filled: true,
        years_experience: "19",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 1657,
          calc_method: "nlv",
          start_date: "2016-03-20",
          created_at: "2016-03-20T16:05:04.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 4,
      id: 2266,
      user_id: 111,
      tplatform_id: 1,
      nick: "TA7172323",
      fs_score_mix: 73.3015,
      inception_date_mix: "2015-12-22",
      last_update_mix: "2022-07-15",
      extid: "U1601838",
      currency_id: 9,
      nominal_size_acc_mix: "451446.516618347",
      performance_mix: 13.988192449042558,
      performance_ytd_mix: 0.037410555402327184,
      performance_ann_mix: 0.5099445570061252,
      maxdd_mix: 0.2977225400523543,
      vol_mix: 0.30160912603427265,
      sharpe_mix: 1.6907464429580252,
      sortino_mix: 1.8891553405053494,
      gpr_mix: 0.34497565328340674,
      calmar_mix: 0.9437727352585138,
      var_mix: -0.023954115380599997,
      es_mix: -0.03935856325655814,
      mean_mix: 0.0017590855238285546,
      stdev_mix: 0.01870043047885172,
      skewness_mix: 0.636896924908082,
      kurtosis_mix: 19.47848781100321,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2016-05-30T10:08:58.000Z",
      updated_at: "2022-07-18T09:48:02.000Z",
      strategy: "Extreme risk, best ideas portfolio. Day trading to multi month swing.",
      edge_sources: "Target retail driven trades",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: true,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: true,
      mt4server_id: null,
      sterling_mix: 1.28216157157952,
      gpr_monthly_mix: 3.4202289364167244,
      mar_mix: 1.7128181054630656,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: null,
      performance_chart: [
        "1.220149993896484",
        "1.761010050773621",
        "2.160579919815064",
        "2.520190000534058",
        "3.555000066757202",
        "4.367959976196289",
        "4.42549991607666",
        "5.331907272338867",
        "4.945629596710205",
        "7.115521907806397",
        "6.907214053038542",
        "7.807953087720806",
        "8.199986550359456",
        "10.571569542162953",
        "14.592108474542667",
        "16.790495410373624",
        "18.092859565932423",
        "17.566179154302685",
        "16.481508420633257",
        "14.987910749302675",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "315057.936086500803",
      nlv_usd_mix: "315057.936086500803",
      inception_date_ver: "2015-12-22",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "315057.936086500803",
      nominal_size_acc_ver: "451446.516618347",
      nominal_size_usd_ver: "315057.936086500803",
      fs_score_ver: 73.3015,
      gpr_ver: 0.34497565328340674,
      sharpe_ver: 1.6907464429580252,
      sortino_ver: 1.8891553405053494,
      performance_ann_ver: 0.5099445570061252,
      performance_ver: 13.988192449042558,
      performance_ytd_ver: 0.037410555402327184,
      maxdd_ver: 0.2977225400523543,
      vol_ver: 0.30160912603427265,
      calmar_ver: 0.9437727352585138,
      var_ver: -0.023954115380599997,
      es_ver: -0.03935856325655814,
      mean_ver: 0.0017590855238285546,
      stdev_ver: 0.01870043047885172,
      skewness_ver: 0.636896924908082,
      kurtosis_ver: 19.47848781100321,
      mar_ver: 1.7128181054630656,
      sterling_ver: 1.28216157157952,
      gpr_monthly_ver: 3.4202289364167244,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: "79",
      become_regulated: false,
      aum: 7500000.0,
      avg_positions: 15.0,
      appr_trades: 2000.0,
      avg_holding_period: 1.0,
      signup_reason: null,
      strategy_equities: "long/short,neutral,event",
      region_equities: "us,asia,europe,emerging",
      sector_equities: "true",
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: "equities",
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: "Biotechnology",
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: "other",
      sector_futures_items: null,
      regulated: true,
      non_otc_markets_items: null,
      options_volatility: "none",
      futures_specific_asset: false,
      regulatory_body:
        "I use regulatory hosting company (Lawson Connor).  They are registered with the FCA. https://www.lawsonconner.com/regulatory-hosting/\n",
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 2397,
      user_full_name: "Michael Kean",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "New Zealand Financial Services Provider Register",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "michael@steelroadcapital.com",
        phone_1: "07503789554",
        phone_2: "07503789554",
        address_1: "87 Chinbrook Road",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "New Zealand Financial Services Provider Register",
        personal_info_filled: true,
        years_experience: "19",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 2447,
          calc_method: "nlv",
          start_date: "2016-05-30",
          created_at: "2016-05-30T10:08:58.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 5,
      id: 5435,
      user_id: 7605,
      tplatform_id: 1,
      nick: "TA1270461",
      fs_score_mix: 71.1097,
      inception_date_mix: "2011-11-15",
      last_update_mix: "2022-07-15",
      extid: "U647895",
      currency_id: 6,
      nominal_size_acc_mix: "357539.495176448",
      performance_mix: 0.7377694288003676,
      performance_ytd_mix: 0.085277025819251,
      performance_ann_mix: 0.05313455870433503,
      maxdd_mix: 0.2154333456860047,
      vol_mix: 0.05892177060147449,
      sharpe_mix: 0.9017814326001494,
      sortino_mix: 0.8072774105971331,
      gpr_mix: 0.28943241656619545,
      calmar_mix: 0.19316373883428312,
      var_mix: -0.0031267320155999954,
      es_mix: -0.007332446732769784,
      mean_mix: 0.00020579529192185812,
      stdev_mix: 0.0036530102510916713,
      skewness_mix: -6.512387078022439,
      kurtosis_mix: 183.68830903493543,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2017-05-19T15:43:42.000Z",
      updated_at: "2022-07-18T10:03:51.000Z",
      strategy:
        "Merger-Arbitrage - a true fixed-income alternative, with returns in the 6% annualized range and risk less than any bond fund we know of.",
      edge_sources:
        "Many years of experience, we scour the world for opportunities and trade nimbly and opportunistically. Plus LOW FEES.",
      tm_discretionary: true,
      tm_systematic: true,
      tm_fundamental: false,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 0.16844940280101953,
      gpr_monthly_mix: 1.9116065812197185,
      mar_mix: 0.2466403635664599,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: {
        leaderboard: [
          {
            position: 13,
            nick: "TA7796194",
            score: 71.7347,
            icon: false,
            change: -1,
          },
          { position: 14, nick: "TA1270461", score: 70.9281, icon: true, change: -3 },
          {
            position: 15,
            nick: "TA0905509",
            score: 70.3327,
            icon: false,
            change: -1,
          },
        ],
        tm_discretionary: [
          {
            position: 7,
            nick: "TA4040109",
            score: 73.6813,
            icon: false,
            change: 1,
          },
          { position: 8, nick: "TA1270461", score: 70.9281, icon: true, change: -1 },
          {
            position: 9,
            nick: "TA0905509",
            score: 70.3327,
            icon: false,
            change: 0,
          },
        ],
        tm_systematic: [
          {
            position: 10,
            nick: "TA7796194",
            score: 71.7347,
            icon: false,
            change: -1,
          },
          { position: 11, nick: "TA1270461", score: 70.9281, icon: true, change: -3 },
          {
            position: 12,
            nick: "TA0905509",
            score: 70.3327,
            icon: false,
            change: -1,
          },
        ],
        mt_equities: [
          {
            position: 9,
            nick: "TA7796194",
            score: 71.7347,
            icon: false,
            change: 1,
          },
          { position: 10, nick: "TA1270461", score: 70.9281, icon: true, change: -1 },
          {
            position: 11,
            nick: "TA0905509",
            score: 70.3327,
            icon: false,
            change: 0,
          },
        ],
      },
      performance_chart: [
        "1.054399967193604",
        "1.081640005111694",
        "1.157940030097961",
        "1.235780000686646",
        "1.220600008964539",
        "1.21439003944397",
        "1.265980005264282",
        "1.309470057487488",
        "1.340289950370789",
        "1.367409944534302",
        "1.426159977912903",
        "1.436597466468811",
        "1.468126773834229",
        "1.544188403344449",
        "1.579625824811707",
        "1.523831088151942",
        "1.600180441748294",
        "1.686376352274093",
        "1.756207135811256",
        "1.737795225730628",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "357539.495176448",
      nlv_usd_mix: "357539.495176448",
      inception_date_ver: "2011-11-15",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "357539.495176448",
      nominal_size_acc_ver: "357539.495176448",
      nominal_size_usd_ver: "357539.495176448",
      fs_score_ver: 71.1097,
      gpr_ver: 0.28943241656619545,
      sharpe_ver: 0.9017814326001494,
      sortino_ver: 0.8072774105971331,
      performance_ann_ver: 0.05313455870433503,
      performance_ver: 0.7377694288003676,
      performance_ytd_ver: 0.085277025819251,
      maxdd_ver: 0.2154333456860047,
      vol_ver: 0.05892177060147449,
      calmar_ver: 0.19316373883428312,
      var_ver: -0.0031267320155999954,
      es_ver: -0.007332446732769784,
      mean_ver: 0.00020579529192185812,
      stdev_ver: 0.0036530102510916713,
      skewness_ver: -6.512387078022439,
      kurtosis_ver: 183.68830903493543,
      mar_ver: 0.2466403635664599,
      sterling_ver: 0.16844940280101953,
      gpr_monthly_ver: 1.9116065812197185,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: false,
      aum: null,
      avg_positions: null,
      appr_trades: null,
      avg_holding_period: null,
      signup_reason: null,
      strategy_equities: null,
      region_equities: null,
      sector_equities: null,
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 3895,
      user_full_name: "Harvey Fram",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "New York, New Jersey",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "hfram@constantiaquant.com",
        phone_1: "+16099473352",
        phone_2: "+16095121812",
        address_1: "17 Hendrickson Rd",
        address_2: null,
        seeking_for_investor: true,
        user_regulatorybody: "New York, New Jersey",
        personal_info_filled: true,
        years_experience: "22",
        education_name: "Master of Business Administration (M.B.A.)",
      },
      calcmethods: [
        {
          id: 5936,
          calc_method: "nlv",
          start_date: "2017-05-19",
          created_at: "2017-05-19T15:43:42.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 6,
      id: 5340,
      user_id: 241,
      tplatform_id: 1,
      nick: "TA0351784",
      fs_score_mix: 67.0485,
      inception_date_mix: "2014-10-28",
      last_update_mix: "2022-07-15",
      extid: "U7413140",
      currency_id: 15,
      nominal_size_acc_mix: "1275621.411805033",
      performance_mix: 0.779885201651147,
      performance_ytd_mix: 0.016888605782584998,
      performance_ann_mix: 0.07753626125303104,
      maxdd_mix: 0.22257268937875385,
      vol_mix: 0.09059048004704556,
      sharpe_mix: 0.8558985581350801,
      sortino_mix: 0.9429826675250164,
      gpr_mix: 0.20237288191527325,
      calmar_mix: 0.6795234891330164,
      var_mix: -0.007868649992399999,
      es_mix: -0.012894208562128712,
      mean_mix: 0.00030303773837967113,
      stdev_mix: 0.005618662385191927,
      skewness_mix: 0.8086066943743515,
      kurtosis_mix: 10.515176233573813,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2017-05-03T09:35:40.000Z",
      updated_at: "2022-07-18T10:03:34.000Z",
      strategy:
        "Classical technical chartist looking for buy setups within winning industries (and shorts in losing / topping industries in weak broad markets).",
      edge_sources:
        "Broad universe with a wide variety of industries to trade, looking for confirmation within the specific industry as well as the broader market. Risk conscious: Position sizes may be reduced or the goal would be to if in a draw down equal to 300 BP or a single month intra trading yields realized loss of 300 BP or greater.",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: false,
      tm_technical: true,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 0.24036833807089789,
      gpr_monthly_mix: 1.106817889134322,
      mar_mix: 0.3483637703684611,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: {
        leaderboard: [
          {
            position: 18,
            nick: "TA7272605",
            score: 67.0878,
            icon: false,
            change: 2,
          },
          { position: 19, nick: "TA0351784", score: 67.0162, icon: true, change: -1 },
          {
            position: 20,
            nick: "TA5386633",
            score: 64.104,
            icon: false,
            change: 15,
          },
        ],
        tm_discretionary: [
          {
            position: 9,
            nick: "TA0905509",
            score: 70.3327,
            icon: false,
            change: 0,
          },
          { position: 10, nick: "TA0351784", score: 67.0162, icon: true, change: 0 },
          {
            position: 11,
            nick: "TA7563343",
            score: 63.7334,
            icon: false,
            change: 0,
          },
        ],
        tm_technical: [
          {
            position: 12,
            nick: "TA7272605",
            score: 67.0878,
            icon: false,
            change: 2,
          },
          { position: 13, nick: "TA0351784", score: 67.0162, icon: true, change: -1 },
          {
            position: 14,
            nick: "TA5386633",
            score: 64.104,
            icon: false,
            change: 11,
          },
        ],
        mt_equities: [
          {
            position: 14,
            nick: "TA7272605",
            score: 67.0878,
            icon: false,
            change: 1,
          },
          { position: 15, nick: "TA0351784", score: 67.0162, icon: true, change: -1 },
          {
            position: 16,
            nick: "TA5386633",
            score: 64.104,
            icon: false,
            change: 10,
          },
        ],
      },
      performance_chart: [
        "1.103250026702881",
        "1.307870030403137",
        "1.314339995384216",
        "1.348350048065186",
        "1.113940000534058",
        "1.20713996887207",
        "1.248600006103516",
        "1.320129990577698",
        "1.418490290641785",
        "1.488611102104187",
        "1.546720504760742",
        "1.603746981590587",
        "1.532402527322221",
        "1.612020806278381",
        "1.628499623812263",
        "1.749008828426619",
        "1.815257890050988",
        "1.808595956650984",
        "1.833862466784252",
        "1.779734443099049",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "144212.970787644765",
      nlv_usd_mix: "40050.740048756755",
      inception_date_ver: "2014-10-28",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "40050.740048756755",
      nominal_size_acc_ver: "1275621.411805033",
      nominal_size_usd_ver: "144212.970787644765",
      fs_score_ver: 67.0485,
      gpr_ver: 0.20237288191527325,
      sharpe_ver: 0.8558985581350801,
      sortino_ver: 0.9429826675250164,
      performance_ann_ver: 0.07753626125303104,
      performance_ver: 0.779885201651147,
      performance_ytd_ver: 0.016888605782584998,
      maxdd_ver: 0.22257268937875385,
      vol_ver: 0.09059048004704556,
      calmar_ver: 0.6795234891330164,
      var_ver: -0.007868649992399999,
      es_ver: -0.012894208562128712,
      mean_ver: 0.00030303773837967113,
      stdev_ver: 0.005618662385191927,
      skewness_ver: 0.8086066943743515,
      kurtosis_ver: 10.515176233573813,
      mar_ver: 0.3483637703684611,
      sterling_ver: 0.24036833807089789,
      gpr_monthly_ver: 1.106817889134322,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: true,
      aum: 100000.0,
      avg_positions: 6.0,
      appr_trades: 60.0,
      avg_holding_period: 20.0,
      signup_reason: null,
      strategy_equities: "long/short,global_macro",
      region_equities: "europe,us,asia",
      sector_equities: null,
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "naspl",
      last_nominal_size: "980000.0",
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 2817,
      user_full_name: "Alex  Laan",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "NFA",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "alexlaan@online.no",
        phone_1: "004722832140",
        phone_2: "",
        address_1: "Torpveien 35B",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "NFA",
        personal_info_filled: true,
        years_experience: "16",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 5834,
          calc_method: "naspl",
          start_date: "2017-05-03",
          created_at: "2017-05-03T09:35:40.000Z",
          nominal_size: "971000.0",
          status: "active",
        },
        {
          id: 8123,
          calc_method: "naspl",
          start_date: "2018-01-17",
          created_at: "2018-01-17T13:30:48.000Z",
          nominal_size: "980000.0",
          status: "active",
        },
      ],
    },
    {
      key: 7,
      id: 19023,
      user_id: 2530,
      tplatform_id: 40,
      nick: "TA5022685",
      fs_score_mix: 62.1117,
      inception_date_mix: "2021-03-15",
      last_update_mix: "2022-07-15",
      extid: "210G7048",
      currency_id: 6,
      nominal_size_acc_mix: "107684.89",
      performance_mix: 0.32551465372869326,
      performance_ytd_mix: 0.32551465372869326,
      performance_ann_mix: 0.2346322355942183,
      maxdd_mix: 0.09088715334639774,
      vol_mix: 0.13819680107849835,
      sharpe_mix: 1.6978123499468185,
      sortino_mix: 2.1117535174086206,
      gpr_mix: 0.43233420945096235,
      calmar_mix: 2.5815775602517297,
      var_mix: -0.0140492853388,
      es_mix: -0.017311477606333333,
      mean_mix: 0.0008414922431571429,
      stdev_mix: 0.008541368261277337,
      skewness_mix: 1.6337983079838114,
      kurtosis_mix: 11.296323859838571,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2021-03-05T05:11:48.000Z",
      updated_at: "2022-07-18T10:58:56.000Z",
      strategy:
        "Rules based technical trading. For a glimpse of philosophy and process please visit https://ctcapm.wordpress.com/",
      edge_sources: "methodology + execution",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: false,
      tm_technical: true,
      mt_forex: false,
      mt_futures: true,
      mt_equities: false,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 1.2291672408589882,
      gpr_monthly_mix: 3.264129326525488,
      mar_mix: 2.5815775602517297,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: {
        leaderboard: [
          {
            position: 27,
            nick: "TA6232281",
            score: 60.7862,
            icon: false,
            change: -1,
          },
          { position: 28, nick: "TA5022685", score: 60.6834, icon: true, change: 1 },
          {
            position: 29,
            nick: "TA1740506",
            score: 60.3662,
            icon: false,
            change: -1,
          },
        ],
        tm_discretionary: [
          {
            position: 15,
            nick: "TA6232281",
            score: 60.7862,
            icon: false,
            change: 0,
          },
          { position: 16, nick: "TA5022685", score: 60.6834, icon: true, change: 1 },
          {
            position: 17,
            nick: "TA3216369",
            score: 59.8904,
            icon: false,
            change: 3,
          },
        ],
        tm_technical: [
          {
            position: 19,
            nick: "TA6232281",
            score: 60.7862,
            icon: false,
            change: -1,
          },
          { position: 20, nick: "TA5022685", score: 60.6834, icon: true, change: 1 },
          {
            position: 21,
            nick: "TA1740506",
            score: 60.3662,
            icon: false,
            change: -1,
          },
        ],
        mt_futures: [
          {
            position: 14,
            nick: "TA6232281",
            score: 60.7862,
            icon: false,
            change: 0,
          },
          { position: 15, nick: "TA5022685", score: 60.6834, icon: true, change: 2 },
          {
            position: 16,
            nick: "TA1740506",
            score: 60.3662,
            icon: false,
            change: 0,
          },
        ],
      },
      performance_chart: [
        "0.999289389988141",
        "0.925214112348021",
        "0.945158336909155",
        "0.940492535332937",
        "0.94324253330192",
        "0.943008658536195",
        "0.943008658536195",
        "0.96176196817186",
        "0.979843687953948",
        "0.966747193441265",
        "1.01794816498166",
        "0.994999757508796",
        "1.041950362912091",
        "1.027927601235155",
        "1.200673042338966",
        "1.180082831056503",
        "1.179914810501127",
        "1.258163521789621",
        "1.301715680662965",
        "1.325514653731178",
      ],
      protocol_id: 8,
      status: "active",
      nominal_size_usd_mix: "107684.89",
      nlv_usd_mix: "107684.89",
      inception_date_ver: "2021-03-15",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "107684.89",
      nominal_size_acc_ver: "107684.89",
      nominal_size_usd_ver: "107684.89",
      fs_score_ver: 62.1117,
      gpr_ver: 0.43233420945096235,
      sharpe_ver: 1.6978123499468185,
      sortino_ver: 2.1117535174086206,
      performance_ann_ver: 0.2346322355942183,
      performance_ver: 0.32551465372869326,
      performance_ytd_ver: 0.32551465372869326,
      maxdd_ver: 0.09088715334639774,
      vol_ver: 0.13819680107849835,
      calmar_ver: 2.5815775602517297,
      var_ver: -0.0140492853388,
      es_ver: -0.017311477606333333,
      mean_ver: 0.0008414922431571429,
      stdev_ver: 0.008541368261277337,
      skewness_ver: 1.6337983079838114,
      kurtosis_ver: 11.296323859838571,
      mar_ver: 2.5815775602517297,
      sterling_ver: 1.2291672408589882,
      gpr_monthly_ver: 3.264129326525488,
      subscribe_steps: true,
      xls_backtest: false,
      show_tradestatistics: false,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: false,
      aum: 80000.0,
      avg_positions: 4.0,
      appr_trades: 200.0,
      avg_holding_period: 4.0,
      signup_reason: null,
      strategy_equities: null,
      region_equities: null,
      sector_equities: null,
      strategy_futures: "chart_analysis",
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: 20.0,
      max_margin_futures: 50.0,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 487,
      user_full_name: "Constantine Theodossiou",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "Financial Conduct Authority (UK)",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "constantine.theodossiou@gmail.com",
        phone_1: "+447827156978",
        phone_2: null,
        address_1: "Flat 121 Andrewes House",
        address_2: "Barbican",
        seeking_for_investor: true,
        user_regulatorybody: "Financial Conduct Authority (UK)",
        personal_info_filled: true,
        years_experience: "13",
        education_name: "Master’s Degree",
      },
      calcmethods: [
        {
          id: 21346,
          calc_method: "nlv",
          start_date: "2021-03-05",
          created_at: "2021-03-05T05:11:49.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 8,
      id: 101,
      user_id: 241,
      tplatform_id: 1,
      nick: "TA8629961",
      fs_score_mix: 59.7475,
      inception_date_mix: "2012-12-31",
      last_update_mix: "2022-07-15",
      extid: "U7342336",
      currency_id: 15,
      nominal_size_acc_mix: "1310264.852434406",
      performance_mix: 1.4702995386029651,
      performance_ytd_mix: -0.04133850624886615,
      performance_ann_mix: 0.0993760500556371,
      maxdd_mix: 0.3099926825267507,
      vol_mix: 0.13399025107495222,
      sharpe_mix: 0.741666272421921,
      sortino_mix: 0.8881879808019904,
      gpr_mix: 0.19386130890095754,
      calmar_mix: 0.8066413118629739,
      var_mix: -0.009954034432399998,
      es_mix: -0.017359845122624,
      mean_mix: 0.00039845891674818694,
      stdev_mix: 0.008309309838101017,
      skewness_mix: 2.6531130528738918,
      kurtosis_mix: 38.74438086175472,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 567639.0,
      max_trade_length: 24796800.0,
      min_trade_length: 86400.0,
      avg_win: 0.00229202,
      max_win: 0.0357172,
      min_win: 0.0,
      avg_loss: 0.00149271,
      max_loss: 0.0326921,
      min_loss: 0.0,
      created_at: "2015-09-30T18:04:15.000Z",
      updated_at: "2022-07-18T09:36:53.000Z",
      strategy:
        "Discretionary classical chartist looking for chart pattern breakouts based on weekly, daily and 60min timeframes in futures markets through a global perspective. Rules based approach, buying breakouts in uptrends and shorting downtrends, risk is anything between 20-150 BP per trade depending on market and portfolio volatility and recent performance. Position sizes may be reduced or the goal would be to if in a draw down equal to 300 BP or a single month intra trading yields realized loss of 300 BP or greater. Portfolio is highly liquid, thus no lock up periods. ",
      edge_sources:
        "Discipline, stamina. In futures I look for signals where groups confirm each other. This reduces risk and increases the odds for success. Downside is that the leaders might be out of reach by then. In a few rare circumstances a 60min chart pattern presents itself near the breakout point of a bigger chart pattern in a daily or weekly time frame, on those chart setups a bigger bet size is justified and quite often account for a considerable part of the portfolios performance. Risk conscious, attempting to avoid calendar year losses.",
      tm_discretionary: true,
      tm_systematic: false,
      tm_fundamental: false,
      tm_technical: true,
      mt_forex: false,
      mt_futures: true,
      mt_equities: false,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 0.2423849358559055,
      gpr_monthly_mix: 1.1690878401069122,
      mar_mix: 0.3205754705098933,
      num_trades: 1581,
      num_positive: 769,
      num_negative: 812,
      leaderboards: {
        leaderboard: [
          {
            position: 34,
            nick: "TA0510767",
            score: 58.7235,
            icon: false,
            change: 3,
          },
          { position: 35, nick: "TA8629961", score: 58.6781, icon: true, change: -8 },
          {
            position: 36,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -5,
          },
        ],
        tm_discretionary: [
          {
            position: 20,
            nick: "TA0510767",
            score: 58.7235,
            icon: false,
            change: 3,
          },
          { position: 21, nick: "TA8629961", score: 58.6781, icon: true, change: -5 },
          {
            position: 22,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -3,
          },
        ],
        tm_technical: [
          {
            position: 25,
            nick: "TA0510767",
            score: 58.7235,
            icon: false,
            change: 2,
          },
          { position: 26, nick: "TA8629961", score: 58.6781, icon: true, change: -7 },
          {
            position: 27,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -4,
          },
        ],
        mt_futures: [
          {
            position: 17,
            nick: "TA3216369",
            score: 59.8904,
            icon: false,
            change: 2,
          },
          { position: 18, nick: "TA8629961", score: 58.6781, icon: true, change: -3 },
          {
            position: 19,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -1,
          },
        ],
      },
      performance_chart: [
        "1.586050033569336",
        "1.175359964370728",
        "1.276559948921204",
        "1.418580055236816",
        "1.751590013504028",
        "1.955530047416687",
        "1.989459991455078",
        "1.857620000839233",
        "1.910159945487976",
        "1.864339947700501",
        "1.806726932525635",
        "1.853444337844849",
        "2.035751342773438",
        "2.255827981054052",
        "2.3269419853049",
        "2.495313697871146",
        "2.557583045738109",
        "2.518034210718594",
        "2.493837270595932",
        "2.470326305947301",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "148129.519573383454",
      nlv_usd_mix: "11772.982706972098",
      inception_date_ver: "2012-12-31",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "11772.982706972098",
      nominal_size_acc_ver: "1310264.852434406",
      nominal_size_usd_ver: "148129.519573383454",
      fs_score_ver: 59.7475,
      gpr_ver: 0.19386130890095754,
      sharpe_ver: 0.741666272421921,
      sortino_ver: 0.8881879808019904,
      performance_ann_ver: 0.0993760500556371,
      performance_ver: 1.4702995386029651,
      performance_ytd_ver: -0.04133850624886615,
      maxdd_ver: 0.3099926825267507,
      vol_ver: 0.13399025107495222,
      calmar_ver: 0.8066413118629739,
      var_ver: -0.009954034432399998,
      es_ver: -0.017359845122624,
      mean_ver: 0.00039845891674818694,
      stdev_ver: 0.008309309838101017,
      skewness_ver: 2.6531130528738918,
      kurtosis_ver: 38.74438086175472,
      mar_ver: 0.3205754705098933,
      sterling_ver: 0.2423849358559055,
      gpr_monthly_ver: 1.1690878401069122,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: true,
      aum: 100000.0,
      avg_positions: 3.0,
      appr_trades: 60.0,
      avg_holding_period: 20.0,
      signup_reason: null,
      strategy_equities: null,
      region_equities: null,
      sector_equities: null,
      strategy_futures: "chart_analysis,macro",
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: 10.0,
      max_margin_futures: 20.0,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "naspl",
      last_nominal_size: "1000000.0",
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 3483,
      user_full_name: "Alex  Laan",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "NFA",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "alexlaan@online.no",
        phone_1: "004722832140",
        phone_2: "",
        address_1: "Torpveien 35B",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "NFA",
        personal_info_filled: true,
        years_experience: "16",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 101,
          calc_method: "nlv",
          start_date: "2015-09-30",
          created_at: "2015-09-30T18:04:15.000Z",
          nominal_size: null,
          status: "active",
        },
        {
          id: 4786,
          calc_method: "naspl",
          start_date: "2017-01-05",
          created_at: "2017-01-03T20:55:26.000Z",
          nominal_size: "1474320.0",
          status: "active",
        },
        {
          id: 5028,
          calc_method: "naspl",
          start_date: "2017-01-27",
          created_at: "2017-01-27T21:42:16.000Z",
          nominal_size: "1000000.0",
          status: "active",
        },
      ],
    },
    {
      key: 9,
      id: 106,
      user_id: 254,
      tplatform_id: 1,
      nick: "TA1959616",
      fs_score_mix: 58.1011,
      inception_date_mix: "2012-01-02",
      last_update_mix: "2022-07-15",
      extid: "U616796",
      currency_id: 6,
      nominal_size_acc_mix: "129944.77566778",
      performance_mix: 3.5275852835002857,
      performance_ytd_mix: -0.03949849221369961,
      performance_ann_mix: 0.154016138717888,
      maxdd_mix: 0.39572863837322936,
      vol_mix: 0.2144681293968315,
      sharpe_mix: 0.7181306572265156,
      sortino_mix: 0.7009715698818295,
      gpr_mix: 0.1467105041326906,
      calmar_mix: 0.25953397283911483,
      var_mix: -0.02091964677859999,
      es_mix: -0.0316396413550942,
      mean_mix: 0.0006399163894609774,
      stdev_mix: 0.013298422849724064,
      skewness_mix: -0.7448334129939232,
      kurtosis_mix: 7.894783196467612,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 12606700.0,
      max_trade_length: 37584000.0,
      min_trade_length: 5356800.0,
      avg_win: 0.00280407,
      max_win: 0.00984472,
      min_win: 7.95431e-6,
      avg_loss: 0.00278028,
      max_loss: 0.0112557,
      min_loss: 1.1475e-5,
      created_at: "2015-09-30T18:04:15.000Z",
      updated_at: "2022-07-18T09:36:59.000Z",
      strategy:
        "50-position equity-only quantitatively-driven strategy in real-world use for 18 years. Loose-fitting five-factor model underpinned by peer-reviewed industry and academic research. Portfolio rebalanced quarterly.",
      edge_sources: "Synthesis of proprietary quant factors and real-world practical portfolio management expertise.",
      tm_discretionary: false,
      tm_systematic: true,
      tm_fundamental: true,
      tm_technical: false,
      mt_forex: false,
      mt_futures: false,
      mt_equities: true,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 0.3106863852435548,
      gpr_monthly_mix: 0.9406901753877779,
      mar_mix: 0.3891963426023984,
      num_trades: 112,
      num_positive: 51,
      num_negative: 61,
      leaderboards: {
        leaderboard: [
          {
            position: 36,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -5,
          },
          { position: 37, nick: "TA1959616", score: 57.8202, icon: true, change: 3 },
          {
            position: 38,
            nick: "TA0449719",
            score: 57.7441,
            icon: false,
            change: 13,
          },
        ],
        tm_systematic: [
          {
            position: 23,
            nick: "TA2185315",
            score: 58.9617,
            icon: false,
            change: -7,
          },
          { position: 24, nick: "TA1959616", score: 57.8202, icon: true, change: 1 },
          {
            position: 25,
            nick: "TA0449719",
            score: 57.7441,
            icon: false,
            change: 8,
          },
        ],
        tm_fundamental: [
          {
            position: 18,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -4,
          },
          { position: 19, nick: "TA1959616", score: 57.8202, icon: true, change: 1 },
          {
            position: 20,
            nick: "TA0449719",
            score: 57.7441,
            icon: false,
            change: 5,
          },
        ],
        mt_equities: [
          {
            position: 27,
            nick: "TA7905443",
            score: 58.583,
            icon: false,
            change: -5,
          },
          { position: 28, nick: "TA1959616", score: 57.8202, icon: true, change: 2 },
          {
            position: 29,
            nick: "TA0449719",
            score: 57.7441,
            icon: false,
            change: 9,
          },
        ],
      },
      performance_chart: [
        "1.096931115109831",
        "1.251439295620702",
        "1.47023001543631",
        "1.729026782184638",
        "1.793109367867638",
        "2.051256291359037",
        "1.812575800540169",
        "1.897373712781777",
        "2.160608660136482",
        "2.46619372399539",
        "3.138355320663614",
        "2.988476187131452",
        "3.210809933846572",
        "3.156740220640143",
        "3.53368206110451",
        "3.517084785968424",
        "4.614930267545118",
        "5.976092163713462",
        "5.968061948655045",
        "4.527585283480741",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "129944.77566778",
      nlv_usd_mix: "129944.77566778",
      inception_date_ver: "2012-01-02",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "129944.77566778",
      nominal_size_acc_ver: "129944.77566778",
      nominal_size_usd_ver: "129944.77566778",
      fs_score_ver: 58.1011,
      gpr_ver: 0.1467105041326906,
      sharpe_ver: 0.7181306572265156,
      sortino_ver: 0.7009715698818295,
      performance_ann_ver: 0.154016138717888,
      performance_ver: 3.5275852835002857,
      performance_ytd_ver: -0.03949849221369961,
      maxdd_ver: 0.39572863837322936,
      vol_ver: 0.2144681293968315,
      calmar_ver: 0.25953397283911483,
      var_ver: -0.02091964677859999,
      es_ver: -0.0316396413550942,
      mean_ver: 0.0006399163894609774,
      stdev_ver: 0.013298422849724064,
      skewness_ver: -0.7448334129939232,
      kurtosis_ver: 7.894783196467612,
      mar_ver: 0.3891963426023984,
      sterling_ver: 0.3106863852435548,
      gpr_monthly_ver: 0.9406901753877779,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: "235",
      become_regulated: false,
      aum: 5600000.0,
      avg_positions: 50.0,
      appr_trades: 60.0,
      avg_holding_period: 270.0,
      signup_reason: null,
      strategy_equities: "long",
      region_equities: "us,europe,emerging",
      sector_equities: "true",
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: "technology",
      sector_futures_items: null,
      regulated: true,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: "FINRA",
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 3847,
      user_full_name: "Barry Randall",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "SEC , Minnesota Dept. of Revenue",
      navs_type: "verified",
      have_trades: false,
      user: {
        email: "barry_randall@prodigy.net",
        phone_1: "6129166789",
        phone_2: "6516996654",
        address_1: "2260 Sargent Avenue",
        address_2: "",
        seeking_for_investor: true,
        user_regulatorybody: "SEC , Minnesota Dept. of Revenue",
        personal_info_filled: true,
        years_experience: "45",
        education_name: "Master of Business Administration (M.B.A.)",
      },
      calcmethods: [
        {
          id: 106,
          calc_method: "nlv",
          start_date: "2015-09-30",
          created_at: "2015-09-30T18:04:15.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
    {
      key: 10,
      id: 7797,
      user_id: 9742,
      tplatform_id: 1,
      nick: "TA9805437",
      fs_score_mix: 56.3889,
      inception_date_mix: "2013-01-02",
      last_update_mix: "2022-07-15",
      extid: "U1060189",
      currency_id: 6,
      nominal_size_acc_mix: "366925.235",
      performance_mix: 0.7789847471523244,
      performance_ytd_mix: 0.04516471345448503,
      performance_ann_mix: 0.06224392672435175,
      maxdd_mix: 0.13793552769170403,
      vol_mix: 0.10332882480430339,
      sharpe_mix: 0.6023868639001442,
      sortino_mix: 0.6396599234084267,
      gpr_mix: 0.17804439162737906,
      calmar_mix: 0.8866498015876019,
      var_mix: -0.009273699854999993,
      es_mix: -0.015476002198024,
      mean_mix: 0.00025267249911890366,
      stdev_mix: 0.006407314756752159,
      skewness_mix: 0.6681107360078219,
      kurtosis_mix: 19.157280841882226,
      profitability: 0.0,
      volume: 0.0,
      comms: 0.0,
      best_trade: 0.0,
      worst_trade: 0.0,
      avg_trade_length: 0.0,
      max_trade_length: 0.0,
      min_trade_length: 0.0,
      avg_win: 0.0,
      max_win: 0.0,
      min_win: 0.0,
      avg_loss: 0.0,
      max_loss: 0.0,
      min_loss: 0.0,
      created_at: "2018-02-16T19:53:50.000Z",
      updated_at: "2022-07-18T10:14:44.000Z",
      strategy:
        "The Triple Advantage Strategy (MTAS) is one of our less volatile models, taking a position no more than once per week and never holding a position over the weekend.  When invested, MTAS takes equal positions in E-Mini S\u0026P 500 and E-Mini NASDAQ 100 equity index futures, always beta 1.0 (positive or negative). Our objectives are increased Alpha and reduced Beta: superior capital growth uncorrelated with equity, bond, and CTA indexes; significant absolute returns in all market scenarios; and added-value diversification to portfolios with other assets.",
      edge_sources:
        "MTAS looks for high probability percentage moves within a 2-4 day timeframe.\r\n\r\nNo weekend exposure: if signal calls for a trade, position opened Monday evening and closed no later than Friday evening.\r\n\r\nProjected trade can be aborted should certain conditions arise on or before Monday.\r\n\r\nNo trade deployed if insufficient upside is expected.\r\n\r\nPattern recognition involving price, breadth, volume, and extent of market moves preceding call and during trade.\r\n\r\nTrades only SPX and NDX, but data analysis across multiple markets.\r\n\r\nBeyond quantitative inputs, a complex blend of daily and historic market data.\r\n\r\nStop-gains during the week, can the shorten trade and provide an early exit.\r\n\r\nStops can initiate early exit to stem loss or lock-in gains.\r\n\r\nTrades only SPX and NDX derivatives: fully liquid intra-day exits can be deployed if necessary.\r\n\r\nHistorical pro-forma results back to 1987 show signal behavior in diverse market environments.",
      tm_discretionary: false,
      tm_systematic: true,
      tm_fundamental: false,
      tm_technical: true,
      mt_forex: false,
      mt_futures: true,
      mt_equities: false,
      mt_options: false,
      mt4server_id: null,
      sterling_mix: 0.2615999692362124,
      gpr_monthly_mix: 0.7479788819864606,
      mar_mix: 0.4512537688148877,
      num_trades: 0,
      num_positive: 0,
      num_negative: 0,
      leaderboards: {
        leaderboard: [
          {
            position: 40,
            nick: "TA1695901",
            score: 57.2578,
            icon: false,
            change: 2,
          },
          { position: 41, nick: "TA9805437", score: 56.3827, icon: true, change: 4 },
          {
            position: 42,
            nick: "TA1207944",
            score: 56.3085,
            icon: false,
            change: 7,
          },
        ],
        tm_systematic: [
          {
            position: 26,
            nick: "TA1695901",
            score: 57.2578,
            icon: false,
            change: 0,
          },
          { position: 27, nick: "TA9805437", score: 56.3827, icon: true, change: 2 },
          {
            position: 28,
            nick: "TA5261881",
            score: 56.1605,
            icon: false,
            change: -4,
          },
        ],
        tm_technical: [
          {
            position: 28,
            nick: "TA0449719",
            score: 57.7441,
            icon: false,
            change: 9,
          },
          { position: 29, nick: "TA9805437", score: 56.3827, icon: true, change: 3 },
          {
            position: 30,
            nick: "TA1207944",
            score: 56.3085,
            icon: false,
            change: 5,
          },
        ],
        mt_futures: [
          {
            position: 21,
            nick: "TA4163569",
            score: 57.7393,
            icon: false,
            change: -1,
          },
          { position: 22, nick: "TA9805437", score: 56.3827, icon: true, change: -1 },
          {
            position: 23,
            nick: "TA1207944",
            score: 56.3085,
            icon: false,
            change: 0,
          },
        ],
      },
      performance_chart: [
        "0.903276026248932",
        "0.974503993988037",
        "0.978121995925903",
        "1.023200035095215",
        "0.990336000919342",
        "0.997988998889923",
        "1.051290035247803",
        "1.06647002696991",
        "1.055029988288879",
        "1.101119995117188",
        "1.140788793563843",
        "1.161682963371277",
        "1.23419451713562",
        "1.341758799063483",
        "1.264841520097544",
        "1.573149793037784",
        "1.612382238794056",
        "1.660493230191222",
        "1.777738169294107",
        "1.778779039779963",
      ],
      protocol_id: 1,
      status: "active",
      nominal_size_usd_mix: "366925.235",
      nlv_usd_mix: "366925.235",
      inception_date_ver: "2013-01-02",
      last_update_ver: "2022-07-15",
      nlv_usd_ver: "366925.235",
      nominal_size_acc_ver: "366925.235",
      nominal_size_usd_ver: "366925.235",
      fs_score_ver: 56.3889,
      gpr_ver: 0.17804439162737906,
      sharpe_ver: 0.6023868639001442,
      sortino_ver: 0.6396599234084267,
      performance_ann_ver: 0.06224392672435175,
      performance_ver: 0.7789847471523244,
      performance_ytd_ver: 0.04516471345448503,
      maxdd_ver: 0.13793552769170403,
      vol_ver: 0.10332882480430339,
      calmar_ver: 0.8866498015876019,
      var_ver: -0.009273699854999993,
      es_ver: -0.015476002198024,
      mean_ver: 0.00025267249911890366,
      stdev_ver: 0.006407314756752159,
      skewness_ver: 0.6681107360078219,
      kurtosis_ver: 19.157280841882226,
      mar_ver: 0.4512537688148877,
      sterling_ver: 0.2615999692362124,
      gpr_monthly_ver: 0.7479788819864606,
      subscribe_steps: false,
      xls_backtest: false,
      show_tradestatistics: true,
      datatype: "daily",
      navstype: "equity_navs",
      country_regulatory_body: null,
      become_regulated: false,
      aum: null,
      avg_positions: null,
      appr_trades: null,
      avg_holding_period: null,
      signup_reason: null,
      strategy_equities: null,
      region_equities: null,
      sector_equities: null,
      strategy_futures: null,
      classes_futures: null,
      sector_futures: null,
      futures_commodities: null,
      avg_margin_futures: null,
      max_margin_futures: null,
      fx_markets: null,
      fx_strategy_style: null,
      options_markets: null,
      markets: null,
      additional_strategy_futures: null,
      additional_sector_futures_items: null,
      additional_strategy_equities: null,
      additional_sector_equities_items: null,
      additional_non_otc_markets: null,
      features_specific_asset_classes: null,
      sector_equities_items: null,
      sector_futures_items: null,
      regulated: false,
      non_otc_markets_items: null,
      options_volatility: null,
      futures_specific_asset: false,
      regulatory_body: null,
      non_otc_markets: false,
      last_calc_method: "nlv",
      last_nominal_size: null,
      last_showable_date_ver: "2022-07-15",
      last_showable_date_mix: "2022-07-15",
      time_tracked: 3481,
      user_full_name: "Steven Prusky",
      have_unverified: false,
      user_regulated: true,
      user_regulatorybody: "SEC Registered Investment Advisor",
      navs_type: "verified",
      have_trades: true,
      user: {
        email: "staff@windsorsecurities.com",
        phone_1: "610-642-3100",
        phone_2: null,
        address_1: "25 E. Athens Avenue",
        address_2: null,
        seeking_for_investor: true,
        user_regulatorybody: "SEC Registered Investment Advisor",
        personal_info_filled: true,
        years_experience: "35",
        education_name: "Bachelor’s Degree",
      },
      calcmethods: [
        {
          id: 8606,
          calc_method: "nlv",
          start_date: "2018-02-16",
          created_at: "2018-02-16T19:53:50.000Z",
          nominal_size: null,
          status: "active",
        },
      ],
    },
  ],
};
