import React, { FC, useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { apiClient } from "../../services/base";
import styles from "./myAccount.module.css";

export interface platform {
  full_name: string;
  id: number;
}

interface mapUsersAccount {
  actions: string;
  activationDate: string;
  brokerId: string;
  fsName: string;
  key: number;
  lastUpdateDate: string;
  preview: string;
  status: { active: boolean; title: string };
  platform: platform;
  ccy?: string;
}

interface deleteTradingAccountPropsType {
  accountsLoading: boolean;
  handleAccountsLoading: (value: boolean) => void;
  record: mapUsersAccount;
}

export const DeleteTradingAccount: FC<deleteTradingAccountPropsType> = ({
  record,
  accountsLoading,
  handleAccountsLoading,
}) => {
  const { t } = useTranslation("myAccount");

  const [accountToDelete, setAccountToDelete] = useState<mapUsersAccount | null>(null);
  const [modalState, setModalState] = useState<boolean>(false);

  const openModal = (record: mapUsersAccount) => () => {
    setAccountToDelete(record);
    setModalState(true);
  };

  const closeModal = () => {
    setAccountToDelete(null);
    setModalState(false);
  };

  const deleteTradingAccount = () => {
    if (!accountsLoading) {
      handleAccountsLoading(true);
      closeModal();
      apiClient
        .tradingAccountsAccountIdDelete(accountToDelete?.key)
        .then(() => {
          apiClient.userTradingAccountsGet().catch(() => {});
        })
        .catch(() => {})
        .finally(() => {
          handleAccountsLoading(false);
        });
    }
  };

  return (
    <>
      <Tooltip title={t("deleteAccount")}>
        <Button
          className={styles.actionIcon}
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={openModal(record)}
          data-testid="deleteTradingAccount"
          disabled={process.env.REACT_APP_READ_ONLY === "enabled"}
        />
      </Tooltip>
      <Modal
        visible={modalState}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={
          <>
            <Button
              type="primary"
              onClick={deleteTradingAccount}
              disabled={accountsLoading}
              data-testid="confirmDeleteTradingAccount"
            >
              {t("yes")}
            </Button>
            <Button onClick={closeModal} data-testid="closeModal">
              {t("no")}
            </Button>
          </>
        }
      >
        <p data-testid="deleteUserAccountMsg">
          {t("deleteUserAccountMsg")} {accountToDelete?.fsName}?
        </p>
      </Modal>
    </>
  );
};
