import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames/bind";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Empty, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ItemInformUser } from "../../components/itemInformUser";
import { Loading } from "../../components/loading";
import { useAppSelector } from "../../hooks";
import { TitleEdit } from "../../components/titleEdite";
import { apiClient } from "../../services/base";
import styles from "./myAccount.module.css";

const cx = classNames.bind(styles);

export interface educationOptions {
  label: string;
  value: string;
}

export interface country {
  name: string;
  alpha_3: string;
}

export interface form {
  city?: string;
  country?: country;
  education?: string;
  firstName?: string;
  lastName?: string;
  tradingYears?: string;
}

const educationOptions: educationOptions[] = [
  { label: "High School", value: "highschool" },
  { label: "Associate's Degree", value: "associates" },
  { label: "Bachelor's Degree", value: "bachelors" },
  { label: "Master's Degree", value: "masters" },
  { label: "Master's of Business Administration (M.B.A.)", value: "mba" },
  { label: "Doctor of Medicine (M.D.)", value: "med_doctor" },
  { label: "Doctor of Philosophy (Ph.D.)", value: "phd" },
  { label: "Other", value: "other" },
];

export const PersonalInfo: FC = () => {
  const { t } = useTranslation("myAccount");
  const accessToken = useAppSelector((state) => state.main.accessToken);
  const countries = useAppSelector((state) => state.main.countries);
  const userProfile = useAppSelector((state) => state.main.userProfile);
  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const [editUserProfile, setEditUserProfile] = useState<boolean>(false);
  const [dataUpdating, setDataUpdating] = useState<boolean>(false);
  const [form, setForm] = useState<any>(userProfile);
  const mapUserProfile = (Object.keys(userProfile) as (keyof typeof userProfile)[]).map((userInfo, index) => {
    if (userProfile[userInfo]) {
      return {
        title: userInfo,
        name: userInfo,
        value: userInfo === "country" ? userProfile[userInfo]?.name : userProfile[userInfo],
        id: index,
      };
    } else {
      return {
        title: userInfo,
        name: userInfo,
        value: "-",
        id: index,
      };
    }
  });

  useLayoutEffect(() => {
    setForm(userProfile);
  }, [userProfile]);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .userInfoGet({ signal: controller.signal })
      .catch(() => {})
      .finally(() => {
        setProfileLoading(false);
      });
    return () => controller.abort();
  }, []);

  const toggleProfileEdit = () => {
    setEditUserProfile((prevValue: boolean) => !prevValue);
  };

  const submitUsersData = () => {
    setDataUpdating(true);
    const formatedEducation = educationOptions?.find((option: any) => option?.label === userProfile?.education)?.value;
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user_info`,
        {
          country: form?.country || userProfile?.country,
          city: form?.city || userProfile?.city,
          trading_years: Number(form?.tradingYears) || Number(userProfile?.tradingYears),
          education: form?.education || formatedEducation,
          first_name: form?.firstName || userProfile?.firstName,
          last_name: form?.lastName || userProfile?.lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .catch(() => {})
      .finally(() => {
        setDataUpdating(false);
        setEditUserProfile(false);
      });
  };
  console.log(form);
  return (
    <>
      <TitleEdit
        onClick={toggleProfileEdit}
        title={t("personalInformation")}
        linkUrl="/edit/my-account"
        disabled={
          profileLoading ||
          !mapUserProfile.find((userData) => userData.value && userData.value !== "-") ||
          process.env.REACT_APP_READ_ONLY === "enabled"
        }
      />
      {editUserProfile && (
        <>
          <Button
            type="primary"
            role="button"
            htmlType="submit"
            form="personalInfo"
            data-testid="savePersonalInfo"
            disabled={profileLoading || dataUpdating}
          >
            {dataUpdating ? <LoadingOutlined /> : t("save")}
          </Button>
          &nbsp; &nbsp;
          <Button onClick={toggleProfileEdit} disabled={dataUpdating}>
            {t("cancel")}
          </Button>
        </>
      )}
      <Form
        onFinish={submitUsersData}
        name="personalInfo"
        id="personalInfo"
        className={cx("informBox", {
          informBoxLoading: profileLoading,
          emptyData: !mapUserProfile.find((userData) => userData.value && userData.value !== "-") && !profileLoading,
        })}
        fields={[
          {
            name: "firstName",
            value: editUserProfile ? form.firstName || "" : form.firstName || "-",
          },
          {
            name: "lastName",
            value: editUserProfile ? form.lastName || "" : form.lastName || "-",
          },
          {
            name: "education",
            value: editUserProfile ? form.education || "" : form.education || "-",
          },
          {
            name: "tradingYears",
            value: editUserProfile ? form.tradingYears || "" : form.tradingYears || "-",
          },
          {
            name: "city",
            value: editUserProfile ? form.city || "" : form.city || "-",
          },
          {
            name: "country",
            value: editUserProfile ? form.country || "" : form.country || "-",
          },
        ]}
      >
        {mapUserProfile.find((userData) => userData.value && userData.value !== "-") && !profileLoading ? (
          mapUserProfile?.map((userProfile) => {
            return (
              <Form.Item
                name={userProfile?.title}
                rules={[{ required: userProfile?.title === "country", message: "Country required" }]}
                key={userProfile?.id}
              >
                <ItemInformUser
                  isActive={editUserProfile}
                  title={userProfile?.title}
                  name={userProfile?.name}
                  setForm={setForm}
                  value={userProfile?.value}
                  countries={countries}
                  educationOptions={educationOptions}
                  disabled={dataUpdating}
                  country={form.country}
                />
              </Form.Item>
            );
          })
        ) : mapUserProfile.find((userData) => userData?.value && userData?.value !== "-") || profileLoading ? (
          <Loading />
        ) : (
          <Empty />
        )}
      </Form>
    </>
  );
};
