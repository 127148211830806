import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import axios from "axios";
import { RanksTable } from "./ranksTable";
import { MapedLeaderBoard } from "./mapedLeaderBoards";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { apiClient } from "../../services/base";
import { Loading } from "../../components/loading";
import { main } from "../../constants/actionTypes";
import styles from "./leaderboard.module.css";

export const Leaderboard: FC = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.main.accessToken);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .fsIndicesGet()
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    axios
      .get(`${process.env.REACT_APP_API_URL}/leaderboard/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        dispatch({
          type: main.SET_LEADERBOARD,
          payload: res.data,
        });
      })
      .catch((error) => console.log(error));
    return () => controller.abort();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MapedLeaderBoard />
          <Row className={styles.leaderboardTableRow}>
            <Col xs={24}>
              <RanksTable />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
