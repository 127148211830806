import React, { useState, useRef, useEffect, FC } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Steps, Button, Form, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames/bind";
import { Header } from "../../components/header";
import { FormItem } from "./formItem";
import { UnverifiedAccountResult } from "./unverifiedAccountResult";
import { BrokerAccountResult } from "./brokerAccountResult";
import { useAppSelector } from "../../hooks";
import { apiClient } from "../../services/base";
import styles from "./addTradingAccount.module.css";

const { Step } = Steps;

type inputStatusType = "" | "warning" | "error";

const cx = classNames.bind(styles);

interface tradingMethodologyCheckboxesTypes {
  systematic?: boolean;
  discretionary?: boolean;
  fundamental?: boolean;
  technical?: boolean;
}

interface assetsTradedCheckboxesTypes {
  forex?: boolean;
  futures?: boolean;
  equities?: boolean;
  options?: boolean;
  otc?: boolean;
}

export const AddTradingAccount: FC = () => {
  const { t } = useTranslation("addTradingAccount");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [connection, setConnection] = useState<string>("");
  const [selectBrokerName, setSelectBrokerName] = useState<string>("");
  const [accNumber, setAccNumber] = useState<string>("");
  const [brokerName, setBrokerName] = useState<string>("");
  const [inputStatus, setInputStatus] = useState<inputStatusType>("");
  const [tradingMethodologyCheckboxes, setTradingMethodologyCheckboxes] = useState<tradingMethodologyCheckboxesTypes>(
    {}
  );
  const [assetsTradedCheckboxes, setAssetsTradedCheckboxes] = useState<assetsTradedCheckboxesTypes>({});

  const accessToken = useAppSelector((state) => state.main.accessToken);
  const tradingPlatforms = useAppSelector((state) => state.main.tradingPlatforms);

  const options = {
    connectionOptions: [
      { label: t("connectBrokerDailyAutomatedData"), value: "Connect Broker" },
      {
        label: t("useUnverifiedDataManuallyUploadedData"),
        value: "Use Unverified Data",
      },
    ],
  };

  const tradingPlatformsOptions = tradingPlatforms.map((tradingPlatform) => {
    return {
      label: tradingPlatform.full_name,
      value: tradingPlatform.name,
    };
  });

  const formRef = useRef<any>();

  const unverifiedData = connection === "Use Unverified Data";

  useEffect(() => {
    document.title = "Trading account wizard";
  }, []);

  useEffect(() => {
    apiClient.tradingPlatformsGet().catch(() => {});
  }, []);

  const handleConnectionChange = (value: string) => {
    setConnection(value);
  };

  const handleSelectBroker = (value: string) => {
    setSelectBrokerName(value);
  };

  const handleBrokerAndAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (connection === "Use Unverified Data") {
      setBrokerName(event.target.value);
    } else {
      setAccNumber(event.target.value);
    }
    if (selectBrokerName === "IB") {
      if (/^([IAUiau])[0-9]{5,8}[Ff]?$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    } else if (selectBrokerName === "Tradestation") {
      if (/^[0-9a-zA-Z]{4,8}$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    } else if (selectBrokerName === "Meta Trader 4") {
      if (/^[0-9]{4,13}$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    } else if (selectBrokerName === "GMI") {
      if (/^[0-9a-zA-Z]{5,8}$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    } else if (selectBrokerName === "XLS") {
      if (/^[A-Za-z0-9]{0,60}$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    } else {
      if (/^[A-Za-z0-9 _]{0,32}$/.exec(event.target.value) === null) {
        setInputStatus("error");
      } else {
        setInputStatus("");
      }
    }
  };

  const resetAllData = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setConnection("");
    setSelectBrokerName("");
    setAccNumber("");
    setBrokerName("");
    formRef.current?.resetFields();
  };

  const switchDataToUnverified = (e: React.ChangeEvent<HTMLFormElement>) => {
    resetAllData(e);
    formRef.current?.setFieldsValue({
      ChooseConnectionMethod: "Use Unverified Data",
    });
    setConnection("Use Unverified Data");
  };

  const getTradingPlatformId = (name: string): number => {
    const el = tradingPlatforms.find((item) => item.name === name);
    return el?.id || 24;
  };

  const createTradingAccount = () => {
    if (!isLoading) {
      setIsLoading(true);
      const el = tradingPlatforms.find((item) => item.name === selectBrokerName);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user_trading_accounts`,
          {
            platform: {
              id: getTradingPlatformId(selectBrokerName),
              full_name: el?.full_name || brokerName,
            },
            extid: unverifiedData ? brokerName : accNumber,
            fs_nick: unverifiedData ? brokerName : accNumber,
            status: {
              status: "newbie",
            },
            ...tradingMethodologyCheckboxes,
            ...assetsTradedCheckboxes,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(() => {
          setCurrentStep(1);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleTradingMethodsChange = (name: string) => (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setTradingMethodologyCheckboxes((prevSelectedCheckboxes) => {
        return { ...prevSelectedCheckboxes, [name]: true };
      });
    } else {
      setTradingMethodologyCheckboxes((prevSelectedCheckboxes: any) => {
        delete prevSelectedCheckboxes[name];
        return { ...prevSelectedCheckboxes };
      });
    }
  };

  const handleAssetsTradedChange = (name: string) => (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setAssetsTradedCheckboxes((prevSelectedCheckboxes) => {
        return { ...prevSelectedCheckboxes, [name]: true };
      });
    } else {
      setAssetsTradedCheckboxes((prevSelectedCheckboxes: any) => {
        delete prevSelectedCheckboxes[name];
        return { ...prevSelectedCheckboxes };
      });
    }
  };

  return (
    <>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>{t("setUpYourTradingAccount")}</div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.stepsWrapper}>
          <Steps current={currentStep} className={styles.steps}>
            <Step />
            <Step />
          </Steps>
        </div>
        <div className={styles.selectWrapper}>
          {currentStep === 0 && (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={createTradingAccount}
              ref={formRef}
            >
              {!connection && <p className={styles.selectText}>{t("fundSeederOffers")}.</p>}
              <FormItem
                formLabel={t("chooseConnectionMethod")}
                name="ChooseConnectionMethod"
                connection={connection}
                setValue={handleConnectionChange}
                needToRenderTooltip={!!((connection && selectBrokerName) || unverifiedData)}
                options={options.connectionOptions}
                changed={!!(connection && selectBrokerName)}
                renderAlternativeTooltip={unverifiedData}
                resetData={resetAllData}
              />
              {connection && (
                <>
                  {unverifiedData ? (
                    <p className={styles.selectText}>{t("ourUnverifiedData")}.</p>
                  ) : (
                    <p className={styles.selectText}>{t("brokerageFirm")}.</p>
                  )}
                  {!unverifiedData && (
                    <FormItem
                      formLabel={t("selectBrokerageFirm")}
                      name="SelectBrokerageFirm"
                      connection={connection}
                      setValue={handleSelectBroker}
                      needToRenderTooltip={true}
                      options={tradingPlatformsOptions}
                      switchDataToUnverified={switchDataToUnverified}
                    />
                  )}

                  {(selectBrokerName || unverifiedData) && (
                    <>
                      <FormItem
                        formLabel={unverifiedData ? t("brokerName") : t("accountNumber")}
                        name={unverifiedData ? "BrokerName" : "AccountNumber"}
                        connection={connection}
                        setInputValue={handleBrokerAndAccount}
                        needToRenderTooltip={true}
                        input={true}
                        accNumber={accNumber}
                        inputStatus={inputStatus}
                      />
                      <Form.Item
                        label={t("tradingMethodology")}
                        className={cx("formItem", "formItemWrapper")}
                        wrapperCol={{ span: 16 }}
                      >
                        <Checkbox onChange={handleTradingMethodsChange("systematic")}>{t("systematic")}</Checkbox>
                        <Checkbox onChange={handleTradingMethodsChange("discretionary")}>{t("discretionary")}</Checkbox>
                        <Checkbox onChange={handleTradingMethodsChange("fundamental")}>{t("fundamental")}</Checkbox>
                        <Checkbox onChange={handleTradingMethodsChange("technical")}>{t("technical")}</Checkbox>
                      </Form.Item>
                      <Form.Item
                        label={t("assetsTraded")}
                        className={cx("formItem", "formItemWrapper")}
                        wrapperCol={{ span: 16 }}
                      >
                        <Checkbox onChange={handleAssetsTradedChange("forex")}>{t("forex")}</Checkbox>
                        <Checkbox onChange={handleAssetsTradedChange("futures")}>{t("futures")}</Checkbox>
                        <Checkbox onChange={handleAssetsTradedChange("equities")}>{t("equities")}</Checkbox>
                        <Checkbox onChange={handleAssetsTradedChange("options")}>{t("options")}</Checkbox>
                        <Checkbox onChange={handleAssetsTradedChange("otc")}>{t("otc")}</Checkbox>
                      </Form.Item>
                      <Form.Item className={styles.formItem} wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                          htmlType="submit"
                          className={styles.confirmButton}
                          disabled={
                            unverifiedData
                              ? !connection || !brokerName || inputStatus === "error"
                              : !accNumber || !selectBrokerName || !connection || inputStatus === "error"
                          }
                          style={{ width: "360px" }}
                        >
                          {isLoading ? <LoadingOutlined /> : t("setUpAccount")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Form>
          )}
          {currentStep === 1 && connection === "Use Unverified Data" && (
            <UnverifiedAccountResult connection={connection} brokerName={brokerName} />
          )}
          {currentStep === 1 && connection === "Connect Broker" && (
            <BrokerAccountResult connection={connection} selectBrokerName={selectBrokerName} accNumber={accNumber} />
          )}
        </div>
      </div>
    </>
  );
};
