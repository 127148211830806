import React, { FC } from "react";
import { LeaderboardCard } from "./leaderboardCard";
import { useAppSelector } from "../../hooks";

export const Leaderboard: FC = () => {
  const fsIndices = useAppSelector((state) => state.main.fsIndices);
  return (
    <>
      {fsIndices.map((leader) => {
        if (leader?.index_name !== "fundseeder") {
          return <LeaderboardCard leader={leader} key={leader?.id} />;
        }
      })}
    </>
  );
};
