import React, { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Row, Tooltip, Empty } from "antd";
import classNames from "classnames/bind";
import { Loading } from "../../components/loading";
import { apiClient } from "../../services/base";
import styles from "./tradingAccount.module.css";

const cx = classNames.bind(styles);

interface metric {
  date: string;
  display_name: string;
  metric_value: number;
  name: string;
}

export const AccountStrategy: FC = () => {
  const { t } = useTranslation("tradingAccount");
  const { id } = useParams();

  const [tableData, setTableData] = useState<metric[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    apiClient
      .accountMetricsAccountIdGet(Number(id), { signal: controller.signal })
      .then((res) => {
        setTableData(res?.data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
    return () => controller.abort();
  }, []);

  const sortedTableData = tableData?.sort((a, b) => {
    if (a?.display_name < b?.display_name) return -1;
    if (a?.display_name > b?.display_name) return 1;
    return 0;
  });

  const mapTableData = sortedTableData?.map((tableDataItem) => {
    const titleWithoutSymbols = tableDataItem?.display_name?.replace(/\W/g, "");
    const camelCaseTitle = titleWithoutSymbols?.charAt(0).toLowerCase() + titleWithoutSymbols?.slice(1);
    const percentageData = (displayName: string, value: number, name: string) => {
      if (value === 0) {
        return 0;
      }
      if (name === "TOTAL_PERFORMANCE") {
        return `${((Number(value) - 1) * 100).toFixed(2)} %`;
      }
      if (
        name === "AVG_RETURN" ||
        name === "STDEV" ||
        name === "WORST_MONTH_RETURN" ||
        name === "DOWNSIDE_DEVIATION" ||
        name === "N_PERIOD_ANNUALISED_VOL"
      ) {
        return Number(value) * 100;
      }
      if (displayName === "Expected Shortfall") {
        return `${Number(value * 100).toFixed(2)} %`;
      }
      if (
        displayName === "Annualized Volatility" ||
        displayName === "Annualized Return" ||
        displayName === "Average Monthly Return" ||
        displayName === "Value at Risk (VaR)" ||
        displayName === "Maximum Drawdown" ||
        displayName === "Annual performance"
      ) {
        return `${(Number(value) * 100).toFixed(2)} %`;
      } else return value?.toFixed(2) || 0;
    };
    if (tableDataItem?.display_name) {
      return (
        <Tooltip title={t(`${camelCaseTitle}Tooltip`)} trigger="click" key={tableDataItem?.name}>
          <div className={styles.accountStrategyField}>
            <div className={styles.tableItemsWrapper}>
              <span className={styles.accountStrategyName}>{t(`${camelCaseTitle}`)}</span>
              <span className={cx("accountStrategyValue", { strategyColoredItem: tableDataItem?.metric_value < 0 })}>
                {percentageData(tableDataItem?.display_name, tableDataItem?.metric_value, tableDataItem?.name)}
              </span>
            </div>
          </div>
        </Tooltip>
      );
    }
  });

  return (
    <Row className={cx("strategyRow", { emptyTable: !mapTableData?.length })}>
      {isLoading ? <Loading /> : <>{mapTableData?.length ? mapTableData : <Empty />}</>}
    </Row>
  );
};
