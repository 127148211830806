import React from "react";
import cn from "classnames";
import styles from "./title.module.css";

export const Title = ({
  className = "",
  classNameSubtitle = null,
  children = "",
  subTitle = "",
  afterTitle = "",
  primary = false,
}) => {
  if (!children) {
    return null;
  }

  return (
    <h1
      className={cn(
        styles.title,
        className,
        classNameSubtitle,
        primary && styles.titleBlack
      )}
    >
      {children}
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      {afterTitle}
    </h1>
  );
};
