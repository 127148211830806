import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./support.module.css";

export const HelpCenter = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <div className={styles.mainHeader}>{t("helpCenter")}</div>
      <p className={styles.supportText}>
        {t("aboutTheProduct")}. <br /> {t("findTheAnswer")}? <br />
        {t("please")}{" "}
        <a href="#contactForm" className={styles.supportTextLink}>
          {t("contactOurFriendlyTeam")}.
        </a>
      </p>
    </>
  );
};
