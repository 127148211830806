import { AnyAction } from "redux";
import { main, tradingAccount } from "../../constants/actionTypes";

export interface monthData {
  monthly_return: number;
  date: string;
}

export interface daily_returns {
  account_id: number;
  currency_id: number;
  date: string;
  fs_return: number;
  performance: number;
  return_type: string;
  twr: number;
  updated_at: string;
}
export interface underwater {
  metric_value: number;
  date: string;
}

interface initialState {
  dailyFsReturn: number[];
  dailyReturn: daily_returns[];
  date: string[];
  filteredDate: string[];
  filteredFsReturn: number[];
  filteredPerformance: number[];
  filteredReturnValue: number[];
  monthData: monthData[];
  performance: number[];
  returnValue: number[];
  underwater: underwater[];
}

const initialState: initialState = {
  dailyFsReturn: [],
  dailyReturn: [],
  date: [],
  filteredDate: [],
  filteredFsReturn: [],
  filteredPerformance: [],
  filteredReturnValue: [],
  monthData: [],
  performance: [],
  returnValue: [],
  underwater: [],
};

const tradingAccountReducer = (state = initialState, action: AnyAction): initialState => {
  const { type, payload } = action;

  const dataTypeCheck = (value: keyof initialState) => {
    return Array.isArray(payload[value]) ? payload[value] : [];
  };

  switch (type) {
    case tradingAccount.SET_GRAPH_DATA:
      return {
        ...state,
        dailyFsReturn: dataTypeCheck("dailyFsReturn"),
        dailyReturn: dataTypeCheck("dailyReturn"),
        date: dataTypeCheck("date"),
        filteredDate: dataTypeCheck("date"),
        filteredFsReturn: dataTypeCheck("dailyFsReturn"),
        filteredPerformance: dataTypeCheck("performance"),
        filteredReturnValue: dataTypeCheck("returnValue"),
        monthData: dataTypeCheck("monthData"),
        performance: dataTypeCheck("performance"),
        returnValue: dataTypeCheck("returnValue"),
      };
    case tradingAccount.SET_FILTERED_GRAPH_DATA:
      return {
        ...state,
        filteredDate: dataTypeCheck("filteredDate"),
        filteredFsReturn: dataTypeCheck("filteredFsReturn"),
        filteredPerformance: dataTypeCheck("filteredPerformance"),
        filteredReturnValue: dataTypeCheck("filteredReturnValue"),
      };
    case tradingAccount.RESET_GRAPH_ZOOM:
      return {
        ...state,
        filteredDate: state.date,
        filteredFsReturn: state.dailyFsReturn,
        filteredPerformance: state.performance,
        filteredReturnValue: state.returnValue,
      };
    case tradingAccount.SET_UNDERWATER:
      return {
        ...state,
        underwater: Array.isArray(payload) ? payload : [],
      };
    default:
      return state;
  }
};

export default tradingAccountReducer;
