export const getCookie = (name: any) => {
  const matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: any, toJson = true) => {
  document.cookie = `${name}=${toJson ? JSON.stringify(value) : value}`;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=0";
};
