import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { Form, Input, Select, Space } from "antd";
import { renderTooltip } from "./formTooltip";
import styles from "./addTradingAccount.module.css";

interface FormItem {
  formLabel: string;
  name: string;
  connection: string;
  setValue?: any;
  setInputValue?: any;
  needToRenderTooltip: boolean;
  options?: any[];
  changed?: boolean;
  input?: boolean;
  accNumber?: string;
  renderAlternativeTooltip?: boolean;
  resetData?: (e: React.ChangeEvent<HTMLFormElement>) => void;
  switchDataToUnverified?: (e: React.ChangeEvent<HTMLFormElement>) => void;
  lastItem?: boolean;
  inputStatus?: "" | "error" | "warning";
}

const cx = classNames.bind(styles);

export const FormItem: FC<FormItem> = ({
  formLabel,
  name,
  connection,
  setValue,
  setInputValue,
  needToRenderTooltip,
  options,
  changed,
  input,
  accNumber,
  renderAlternativeTooltip,
  resetData,
  switchDataToUnverified,
  lastItem,
}) => {
  const { t } = useTranslation("addTradingAccount");
  return (
    <Form.Item label={formLabel} className={cx("formItemWrapper", { lastItem: lastItem })}>
      <Space>
        <Form.Item name={name} style={{ marginBottom: 0 }} className={styles.formItem}>
          {input ? (
            <Input
              className={styles.input}
              size="middle"
              style={{ width: "360px" }}
              value={accNumber}
              onChange={setInputValue}
            />
          ) : !input && changed ? (
            <div className={styles.changedConnectionInput}>{connection}</div>
          ) : (
            <Select
              placeholder={t("select")}
              className={styles.select}
              style={{ width: "360px" }}
              onChange={setValue}
              options={options}
              defaultActiveFirstOption={true}
            ></Select>
          )}
        </Form.Item>
        {needToRenderTooltip &&
          renderTooltip(formLabel, renderAlternativeTooltip, resetData, switchDataToUnverified, t)}
      </Space>
    </Form.Item>
  );
};
