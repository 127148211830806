import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { FormItem } from "./formItem";
import { Loading } from "../../components/loading";
import { apiClient } from "../../services/base";
import styles from "./editTradingAccount.module.css";

export const EditTradingAccount = () => {
  const { t } = useTranslation("editTradingAccount");
  const [accountsLoading, setAccountsLoading] = useState<boolean>(true);

  useEffect(() => {
    apiClient.userTradingAccountsGet().finally(() => {
      setAccountsLoading(false);
    });
  }, []);

  return (
    <div className={styles.formWrapper}>
      {accountsLoading ? (
        <Loading />
      ) : (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={() => {}}
        >
          <FormItem formLabel={t("firstItem")} type="textarea" />
          <FormItem formLabel={t("secondItem")} type="textarea" />
          <FormItem formLabel={t("thirdItem")} type="checkboxGroup" />
          <FormItem formLabel={t("fourthItem")} type="checkbox" />
          <FormItem formLabel={t("fifthItem")} type="input" />
        </Form>
      )}
    </div>
  );
};
