import React, { useEffect } from "react";
import { Row, Col } from "antd";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/header";
import { FooterLinks } from "../home/footer/footerLinks";
import styles from "./cms.module.css";

const cx = classNames.bind(styles);

export const PrivacyPolicy = () => {
  const { t } = useTranslation("cms");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Privacy policy";
  }, []);

  const scrollFunction = (el: HTMLElement) => {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }, 300);
  };

  return (
    <div className={styles.pageBg}>
      <Header />
      <div className={styles.wrapper}>
        <Row>
          <Col xs={24}>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("thisPolicyOutlines")}
              <a href="/security">{t("securityPolicyLink")}</a>
            </p>
            <h3 className={styles.cmsHeader}>{t("whatInformationWeCollect")}</h3>
            <p className={styles.cmsContent}>{t("nonPasswordProtected")}</p>
            <p className={styles.cmsContent}>{t("becomeARegistered")}</p>
            <p className={styles.cmsContent}>{t("additionally")}</p>
            <p className={styles.cmsContent}>{t("changeThePersonalInformation")}</p>
            <p className={styles.cmsContent}>{t("permitsYouToVoluntarily")}</p>
            <p className={styles.cmsContent}>{t("gatherInformation")}</p>
            <p className={styles.cmsContent}>{t("fullDescription")}</p>
            <p className={styles.cmsContent}>{t("useAnonymized")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("specificUsersData")}</p>
            <h3 className={styles.cmsHeader}>{t("howWeUseTheInformationWeCollect")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("intendToUseYourPersonally")}</p>
            <h3 className={styles.cmsHeader}>{t("yourRightsWithRespectToYourInformation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("generalDataProtection")}</p>
            <ul className={styles.lastParagraph}>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightOfAccess")}</b>
                </span>{" "}
                - {t("includesYourRight")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToCorrection")}</b>
                </span>{" "}
                - {t("rightToRequest")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToErasure")}</b>
                </span>{" "}
                - {t("certainLimitations")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToComplain")}</b>
                </span>{" "}
                - {t("complaintRegarding")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToRestrictProcessing")}</b>
                </span>{" "}
                - {t("identifiableInformation")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToObject")}</b>
                </span>{" "}
                - {t("certainSituations")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToPortability")}</b>
                </span>{" "}
                - {t("transmitItToAnotherParty")}
              </li>
              <li className={styles.listItem}>
                <span>
                  <b>{t("rightToNotBeSubject")}</b>
                </span>{" "}
                - {t("similarlySignificant")}
              </li>
            </ul>
            <p className={styles.cmsContent}>
              {t("rightsCanBeExercised")}
              <HashLink to="/privacy-policy#contacts" scroll={scrollFunction}>
                {t("below")}
              </HashLink>
              {t("rightsCanBeExercisedEnd")}
            </p>
            <p className={styles.cmsContent}>{t("discriminateAgainstInUser")}</p>
            <p className={styles.cmsContent}>
              {t("questionsAboutExercising")}
              <HashLink to="/privacy-policy#contacts" scroll={scrollFunction}>
                {t("below")}
              </HashLink>
            </p>
            <p className={styles.cmsContent}>{t("specialCategories")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("designateAnAgent")}
              <HashLink to="/privacy-policy#contacts" scroll={scrollFunction}>
                {t("below")}
              </HashLink>
            </p>
            <h3 className={styles.cmsHeader}>{t("retentionOfYourInformation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("businessPurposes")}</p>
            <h3 className={styles.cmsHeader}>{t("processorsWeUse")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>
              {t("servicesWeProvide")}
              <a href="/processor_documentation" rel="noopener noreferrer" target="_blank">
                {t("processorDocumentation")}
              </a>
              {t("servicesWeProvideEnd")}
            </p>
            <h3 className={styles.cmsHeader}>{t("technicalAndOtherInformation")}</h3>
            <p className={styles.cmsContent}>
              {t("collectInformation")}
              <a href="https://www.allaboutcookies.org">{t("allaboutcookies")}</a>
            </p>
            <p className={styles.cmsContent}>{t("browsingOurWebsite")}</p>
            <p className={styles.cmsContent}>{t("smallTransparent")}</p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("visitAWebsite")}</p>
            <h3 className={styles.cmsHeader}>{t("customerService")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("responseToYourInquiries")}</p>
            <h3 className={styles.cmsHeader}>{t("informationSharingAndDisclosure")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("publiclyPublish")}</p>
            <h3 className={styles.cmsHeader}>{t("rentOrShare")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("personallyIdentifiableInformation")}</p>
            <h3 className={styles.cmsHeader}>{t("provideYourInformation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("successorEntity")}</p>
            <h3 className={styles.cmsHeader}>{t("referrals")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("referFriends")}</p>
            <h3 className={styles.cmsHeader}>{t("accuracyOfInformation")}</h3>
            <p className={styles.cmsContent}>
              {t("endeavorToKeepAccurate")}
              <HashLink to="/privacy-policy#contacts" scroll={scrollFunction}>
                {t("below")}
              </HashLink>
            </p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("alternatively")}</p>
            <h3 className={styles.cmsHeader}>{t("changesToThisPrivacyPolicy")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("assetsTransferred")}</p>
            <h3 className={styles.cmsHeader}>{t("optOut")}</h3>
            <p className={styles.cmsContent}>
              {t("contacting")}
              <HashLink to="/privacy-policy#contacts" scroll={scrollFunction}>
                {t("below")}
              </HashLink>
            </p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("receivingCommunications")}</p>
            <h3 className={styles.cmsHeader}>{t("resultsOfNotProvidingInformation")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("certainServicesOffered")}</p>
            <h3 className={styles.cmsHeader}>{t("children")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("compliance")}</p>
            <h3 className={styles.cmsHeader}>{t("currentAsOfDate")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("contactUsDirectly")}</p>
            <h3 className={styles.cmsHeader}>{t("locationOfWebsite")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("websiteIsOperated")}</p>
            <h3 className={styles.cmsHeader}>{t("acceptanceOfTheseTerms")}</h3>
            <p className={cx("cmsContent", "lastParagraph")}>{t("privacyPolicy")}</p>
            <h3 className={styles.cmsHeader} id="contacts">
              {t("contact")}
            </h3>
            <p className={styles.cmsContent}>{t("followingMethods")}<a href="/user-agreement">{t("userAgreementLink")}</a>{t("orOur")}<a
              href="/security">{t("security")}</a>{t("followingMethodsEnd")}</p>
            <p className={styles.cmsContent}>{t("byEmail")}<a href="mailto:info@fundseeder.com" target="_blank" rel="noopener noreferrer">
              {t("mailTo")}
            </a></p>
            <p className={styles.cmsContent}>{t("byPhone")}<a href="tel:+15619534456">+1 561 953 4456</a></p>
            <p className={cx("cmsContent", "lastParagraph")}>{t("toringdonWay")}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.fixedLinks}>
        <ul className={styles.fixedLinksList}>
          <li className={cx("fixedLinksListItem", "currentPage")}>{t("privacyPolicyLink")}</li>
          <Link to="/security"><li className={styles.fixedLinksListItem}>{t("security")}</li></Link>
          <Link to="/user-agreement"><li className={styles.fixedLinksListItem}>{t("userAgreementLink")}</li></Link>
        </ul>
      </div>
      <FooterLinks />
    </div>
  );
};
