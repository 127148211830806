import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Modal, Input, Form } from "antd";
import { TitleEdit } from "../../components/titleEdite";
import { useAppSelector } from "../../hooks";
import { SubscriptionForm } from "./subscriptionForm";
import { AccountsTable } from "./accountsTable";
import { PersonalInfo } from "./personalInfo";
import { apiClient } from "../../services/base";
import styles from "./myAccount.module.css";

export interface educationOptions {
  label: string;
  value: string;
}

export const UserInformation = () => {
  const { t } = useTranslation("myAccount");
  const [accountDeleting, setAccountDeleting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const accessToken = useAppSelector((state) => state.main.accessToken);
  const userId = useAppSelector((state) => state.main.userId);

  useEffect(() => {
    const controller = new AbortController();
    apiClient.countriesGet({ signal: controller.signal }).catch(() => {});
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    apiClient.currenciesGet({ signal: controller.signal }).catch(() => {});
    return () => controller.abort();
  }, []);

  const toggleDeleteAccountModal = () => {
    setInputValue("");
    setShowModal((prevValue) => !prevValue);
  };

  const handleDeleteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const deleteAccount = (userId: string, accessToken: string, accountDeleting: boolean) => {
    if (!accountDeleting) {
      setAccountDeleting(true);
      apiClient
        .userDelete()
        .catch(() => {})
        .finally(() => {
          setInputValue("");
          setAccountDeleting(false);
          setShowModal(false);
        });
    }
  };

  return (
    <div className={styles.userInformation}>
      <div className={styles.container}>
        <div className={styles.controlButtons}>
          <Link to="/add-trading-account">
            <Button type="primary" disabled={process.env.REACT_APP_READ_ONLY === "enabled"}>
              {t("addTradingAccount")}
            </Button>
          </Link>
        </div>
        <AccountsTable />
        <PersonalInfo />
        <SubscriptionForm />
        <TitleEdit
          title={t("changePassword")}
          linkUrl="/credentials"
          disabled={process.env.REACT_APP_READ_ONLY === "enabled"}
        />
        <div>
          <br />
          <br />
          <Button
            type="primary"
            onClick={toggleDeleteAccountModal}
            danger
            data-testid="deleteAccount"
            disabled={process.env.REACT_APP_READ_ONLY === "enabled"}
          >
            {t("deleteUserAccount")}
          </Button>
        </div>
      </div>
      <Modal
        centered
        visible={showModal}
        onCancel={toggleDeleteAccountModal}
        width="30%"
        footer={null}
        className={styles.deleteAccountModal}
        destroyOnClose={true}
      >
        <div className={styles.modalContentWrapper}>
          <h2 className={styles.textCenter}>{t("deleteUserAccountMsg")}?</h2>
          <h3 className={styles.modalHeader}>{t("typeDELETE")}</h3>
          <div className={styles.modalControls}>
            <Form
              className={styles.deleteAccountForm}
              onFinish={() => deleteAccount(userId, accessToken, accountDeleting)}
            >
              <Form.Item className={styles.deleteAccountFormInput}>
                <Input
                  onChange={handleDeleteInputChange}
                  value={inputValue}
                  disabled={accountDeleting}
                  data-testid="accountDeleteInput"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.modalSubmitButton}
                  danger
                  disabled={inputValue !== "DELETE"}
                  data-testid="confirmAccountDelete"
                >
                  {accountDeleting ? <LoadingOutlined /> : t("submit")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
