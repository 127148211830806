import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { format } from "date-fns";
import { monthData } from "./reducer";
import { useAppSelector } from "../../hooks";
import { ModalChart } from "./modalChart";
import { PerformanceTableContent } from "./performanceTableContent";
import styles from "./tradingAccount.module.css";

interface year {
  year: number;
}

export const PerformanceTable: FC = () => {
  const { t } = useTranslation("tradingAccount");
  const monthData = useAppSelector((state) => state.tradingAccount.monthData);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalDate, setModalDate] = useState<string | Date | null>("");
  const [fullYearSelected, setFullYearSelected] = useState<boolean>(false);

  // Array of unique years
  const years: year[] = [];
  monthData?.forEach((oneMonthData: monthData) => {
    if (
      years?.findIndex((yearObject: year) => yearObject?.year === new Date(oneMonthData?.date).getFullYear()) === -1
    ) {
      years.push({ year: new Date(oneMonthData?.date).getFullYear() });
    }
  });

  const handleModalOpen = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setFullYearSelected(!!event.currentTarget.children[0]?.getAttribute("data-year"));
    if (event.currentTarget.children[0]) {
      setModalDate(event.currentTarget.children[0].getAttribute("data-id"));
    }
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setFullYearSelected(false);
    setModalDate("");
    setIsModalVisible(false);
  };

  return (
    <>
      <PerformanceTableContent handleModalOpen={handleModalOpen} />
      <Modal
        title={`${t("cumulativeReturnFor")} ${
          modalDate ? format(new Date(modalDate), `${fullYearSelected ? "yyyy" : "MMM yyyy"}`) : ""
        }`}
        visible={isModalVisible}
        onCancel={closeModal}
        width="unset"
        className={styles.performanceModal}
        footer={[
          <Button type="primary" onClick={closeModal} key={1}>
            Ok
          </Button>,
        ]}
      >
        <ModalChart selectedDate={modalDate} fullYearSelected={fullYearSelected} />
      </Modal>
    </>
  );
};
