import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./titleEdite.module.css";

type Props = {
  classNames?: {
    title?: string;
    edit?: string;
  };
  disabled?: boolean;
  linkUrl: string;
  onClick?: any;
  title: string;
};

export const TitleEdit: React.FC<Props> = ({ title, classNames, onClick, disabled, linkUrl }) => {
  return (
    <div className={cn(styles.box)}>
      <h4 className={cn(styles.title, classNames && classNames.title)}>
        {title}
        <>
          {disabled ? (
            <>
              <span> </span>
              <span className={styles.disabled} data-testid="disabled">
                Edit
              </span>
            </>
          ) : (
            <>
              {title === "Change password:" ? (
                <Link
                  className={cn(styles.edit, classNames && classNames.edit)}
                  to={linkUrl}
                  onClick={onClick}
                  data-testid={title}
                >
                  Edit
                </Link>
              ) : (
                <span className={cn(styles.edit, classNames && classNames.edit)} onClick={onClick} data-testid={title}>
                  Edit
                </span>
              )}
            </>
          )}
        </>
      </h4>
    </div>
  );
};
