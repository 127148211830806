import React, { FC } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { Header } from "../../components/header";
import { useAppSelector } from "../../hooks";
import styles from "./leaderboard.module.css";

const cx = classNames.bind(styles);

export const MapedLeaderBoard: FC = () => {
  const { t } = useTranslation("leaderboard");

  const fsIndices = useAppSelector((state) => state.main.fsIndices);
  const selectedLeaderboard = useAppSelector((state) => state.main.selectedLeaderboard);

  const mapLeaderBoardsList = fsIndices.map((fsValue) => {
    return (
      <div className={styles.leaderboard} key={fsValue?.index_name}>
        <span className={styles.leaderboardChartName}>{t(`${fsValue?.index_display_name.toLowerCase()}`)}</span>
        <ul className={styles.leaderboardUsersList}>
          {fsValue?.leaders.map((leader) => {
            return (
              <li className={styles.leaderboardUsers} key={leader?.account_id}>
                {`${leader?.rank + 1} ${leader?.fs_nick}`}
              </li>
            );
          })}
        </ul>
      </div>
    );
  });

  return (
    <>
      <Header />
      <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfoContent}>
          {t("top50FSAccounts")}{" "}
          <span className={cx("returnSource", { transformName: selectedLeaderboard !== "leaderboard" })}>
            {selectedLeaderboard === "leaderboard"
              ? t("allSourcesOfReturns")
              : t(`${selectedLeaderboard.toLowerCase()}`)}
          </span>
        </div>
      </div>
      <Row className={styles.leaderboardCardsRow}>
        <Col xs={24} className={styles.leaderboardWrapper}>
          {mapLeaderBoardsList}
        </Col>
      </Row>
    </>
  );
};
